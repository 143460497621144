import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {ModalComponent} from "../../widgets/modal/ModalComponent";

export interface AboutComponentProps {

}

export interface AboutComponentState {

}

export class AboutComponent extends React.Component<AboutComponentProps, AboutComponentState> {


    constructor(props) {
        super(props);



    }


    render() {
        return <div>
            <div><b>About:</b> DragoNote adds a new dimension to the world of note taking. Ideas, thoughts, todo lists and files you can put on the gigantic map – the map of your brain.</div>
            <br/>
            <div>DragoNote Version: {AppController.VERSION}</div>
            <div>by <a href="https://yagular.com/"
            target="_blank">
                Yagular, Frank Chomiuk
            </a> </div>
            <br/>

            If you have any questions or want to report a bug, please contact me on
            <br/>
            <a href="contact@dragonote.com">contact@dragonote.com</a>
            <br/>
            <br/>
            Read more about the app on<br/>
            <a href="https://info.dragonote.com/"
                target="_blank"
            >
                info.dragonote.com
            </a>
            <br/>
            <br/>

            <div >
                <a href="https://info.dragonote.com/terms-of-use/"

                   target="_blank"
                >
                    terms of use
                </a>
                <a href="https://info.dragonote.com/privacy-policy/"
                   style={{"marginLeft": "10px"}}

                   target="_blank"
                >
                    privacy policy
                </a>
            </div>

        </div>
    }
}
