import {Vector2} from "./Vector2";
import {MapArea} from "./MapArea";


export class MapObject {
    constructor(public position: Vector2,
                public size: Vector2,
                public level: number) {

    }

    public toString(): string {
        return "M{"+this.position+","+this.size+","+this.level+"}";
    }

    public scale(scale): MapObject {
        return new MapObject(this.position.multiply(scale), this.size.multiply(scale), this.level);
    }

    getArea(): MapArea {
        return new MapArea(this.position, this.size);
    }

}