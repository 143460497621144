import React, {useState} from 'react';
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {AppController} from "../../../controllers/AppController";
import {NotificationsComponent} from "../notifications/NotificationsComponent";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";


export interface ConfirmAccountProps {

    app: AppController,
    onClose: () => void

}

export interface ConfirmAccountState {
    loading: boolean,
    resent: boolean
    // selectedIcon: number
}

export class ConfirmAccountComponent extends React.Component<ConfirmAccountProps, ConfirmAccountState> {

    private _code = React.createRef<HTMLInputElement>();



    constructor(props, state) {
        super(props, state);
        this.state = {
            loading: false,
            resent: false
            // selectedIcon: props.selectedIcon
        }


    }


    private submit(e) {
        e.preventDefault();
        const code = this._code.current.value.trim();
        if (10 !== code.length) {
            this.props.app.notify("Invalid code","danger");
            return;
        }
        console.log("send code",code);
        (async () => {
            const resp = await this.props.app.userService.confirmCode(code);
            if ("ok" == resp.status) {
                this.setState({"loading": false});
                this.props.app.notify("Your email address is confirmed!","success");
                this.props.app.userService.getLoggedLoaded().status = 0;
                this.props.onClose();
                // this.props.app.onViewerStateChangeCall();
            } else {
                if (undefined !== resp.error) {
                    this.props.app.notify(resp.error,"danger");
                } else {
                    this.props.app.notify("An error occured, please contact support","danger");
                }
            }
        })();
        // (async () => {
        //
        //     this.props.app.onViewerStateChangeCall();
        //     this.props.onClose();
        // })();
    }

    private resend() {
        this.setState({"loading": true});
        (async () => {
            const resp = await this.props.app.userService.resendCode();
            if ("ok" == resp.status) {
                this.setState({"resent": true,"loading": false});
                this.props.app.notify("The code was sent again","success");
            } else {
                if (undefined !== resp.error) {
                    this.props.app.notify(resp.error,"danger");
                } else {
                    this.props.app.notify("An error occured, please contact support","danger");
                }
            }
        })();
    }

    render() {

        if (this.state.loading) {
            return <LoadingComponent/>
        }

        return <div className="confirm-account">
                <form autoComplete="off" onSubmit={e => this.submit(e)}>
                    <div className="input-group mb-3">
                        <input  type="text"
                                ref={this._code} className="form-control " placeholder="Enter the code"
                               required={true}
                               aria-describedby="button-addon2"/>


                        <button className="btn btn-outline-success" type="submit" id="button-addon2"
                                data-mdb-ripple-color="dark">
                            Confirm
                        </button>

                    </div>


                    <div className="text-muted">The code was sent to your email address</div>
                    {!this.state.resent ?
                        <div><a onClick={() => this.resend()} className={"text-primary"}> resend the code</a></div>
                        : null}



                </form>
        </div>;
    }
}
