import React, {useState} from 'react';


import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import md5 from 'crypto-js/md5';

import './note.css';
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import {MapNodeToolsButton, MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";
import {CommentsComponent} from "../../ui/comments/CommentsComponent";
import {DateComponent} from "../../widgets/DateComponent";
import {InputUtil} from "../../../utils/InputUtil";
import {MapNodeNoteTxt} from "../../../node/MapNodeType";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";

export interface NoteTxtColorComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,
    onColorSelect: (index) => void

}

export interface NoteTxtColorComponentState {
    opened: boolean,
    selectedIndex: number
}


export class NoteTxtColorComponent extends React.Component<NoteTxtColorComponentProps, NoteTxtColorComponentState> {



    constructor(props, state) {
        super(props, state);
        const options = this.props.mapNode.getOptionsObject();
        let colorIndex = options.get(0,true);
        if (isNaN(colorIndex)) {
            colorIndex = 0;
        }
        this.state = {
            opened: false,
            selectedIndex: colorIndex
        }
    }

    private isSelected(index) {
        return null != this.state && index === this.state.selectedIndex;
    }

    public getSelectedIndex() {
        if (null != this.state) {
            return this.state.selectedIndex;
        } else {
            return 0;
        }
    }

    private select(index) {
        this.setState({
            selectedIndex: index,
            opened: false
        });
        this.props.onColorSelect(index);
    }

    render() {
        return <span className={"note-txt-color app-ui app-node-ignore-focus"}>
            <span
                style={{"backgroundColor": MapNodeNoteTxt.COLORS[this.getSelectedIndex()]}}
                onClick={e => this.setState({opened: null == this.state || !this.state.opened})}
                className={"note-txt-color-button map-node-tools-button"}>

            </span>
            {null != this.state && true === this.state.opened ? <div
            className={"note-txt-color-picker"}>
                {MapNodeNoteTxt.COLORS.map((color,index) => <OutsideClickComponent
                    ignoreClass={"note-txt-color-picker"}
                    onOutsideClick={() => this.setState({opened: false})}>
                    <span
                        className={"note-txt-color-button "+(this.isSelected(index) ? "note-txt-color-button-selected" : "")}
                        style={{"backgroundColor": color}}
                        onClick={e => this.select(index)}
                    >

                    </span>
                </OutsideClickComponent>  )}
            </div> : null}
        </span>
    }
}
