export class DelayedOperation {

    private static _requests = {};
    private static _delayed = {};

    public static deletePending(key: string) {
        DelayedOperation._requests[key] = undefined;
        DelayedOperation._delayed[key] = undefined;
    }

    public static run(key: string,operation: ()=>void,delay: number = 2000) {
        return new Promise((resolve, reject) => {
            if (undefined === DelayedOperation._requests[key]) {
                DelayedOperation._requests[key] = 0;
            } else {
                DelayedOperation._requests[key]++;
            }
            const req = DelayedOperation._requests[key];
            setTimeout(()=>{
                if (req === DelayedOperation._requests[key]) {
                    const delayed = DelayedOperation._delayed[key];

                    const resp = operation();
                    if (undefined !== delayed) {
                        DelayedOperation._delayed[key] = [];
                        delayed.forEach(op => op(resp));
                    }

                    resolve(resp);

                } else {
                    if (undefined === DelayedOperation._delayed[key]) {
                        DelayedOperation._delayed[key] = [];
                    }
                    DelayedOperation._delayed[key].push(resp=>resolve(resp));
                }
            },delay);
        });

    }

}