import React from 'react';
import {Vector2} from "../map/Vector2";
import {MapObject} from "../map/MapObject";

import {AppController} from "../controllers/AppController";
import {DomUtil} from "../utils/DomUtil";
import {getIconUnicode, ICONS} from "../lib/Icons";
import {MapNodeType, MapNodeTypeEnum} from "../node/MapNodeType";
import {MapNode} from "../node/MapNode";
import {MiniMapStaticComponent} from "./ui/minimap/MiniMapStaticComponent";
import {DelayedOperation} from "../lib/DelayedOperation";


export interface MiniMapComponentProps {

    app: AppController

}

export interface MiniMapComponentState {
    // drawLastPosition?: boolean
}


export class MiniMapComponent extends React.Component<MiniMapComponentProps,MiniMapComponentState> {

    private _ref = React.createRef<HTMLDivElement>();


    private _size = new Vector2(200, 200);
    // private _size_mobile = new Vector2(100, 100);



    constructor(props: MiniMapComponentProps) {
        super(props);


    }

    componentDidMount() {
        // if ("true" == window.localStorage.getItem("minimap_draw_last_position")) {
        //     this.setState({drawLastPosition: true});
        // }
    }

    getSize(): Vector2 {
        // if (this.props.app.io.isMobile()) {
        //     return this._size_mobile;
        // } else {
            return this._size;
        // }
    }

    private _mouseDown = false;
    private _mouseDownTraveled = 0;
    private _mouseDownTarget: any = null;

    private handleMouseDown(event) {
        this._mouseDown = true;
        this._mouseDownTarget = event.target;
        this.handleMapMove(event);

    }

    private handleMouseUp(event) {

        this._mouseDown = false;

        this._mouseDownTraveled = 0;

        this.props.app.mapMoving = false;
    }

    private handleMouseMove(event) {
        if (this._mouseDown) {
            this.props.app.mapMoving = true;
            this.handleMapMove(event);

        }
    }

    private handleMapMove(event) {
        const position = new Vector2(event.nativeEvent.offsetX, event.nativeEvent.offsetY)
            .sum(this.getSize().multiply(-0.5))
        ;

        const scale = this.props.app.mapSize.x / this.getSize().x;

        const mapPos = position.multiply(scale * this.props.app.getZoom());


        this.props.app.setViewerPosition(mapPos);
    }



    onCanvasRender(serialized,canvas) {
        // console.log(serialized,JSON.stringify(serialized).length);
        // canvas.toBlob(blob => console.log(blob),"image/jpeg",0.3);
        if (this.props.app.currentMap.setMiniMapUnserialized(serialized)) {
            this.props.app.mapService.requestUpdateMap(this.props.app.currentMap);
        }

    }

    render() {
        return <div
            ref={this._ref}
            style={{width: this.getSize().x,height: this.getSize().y}}
            onMouseDown={e => this.handleMouseDown(e)}
            onMouseUp={e => this.handleMouseUp(e)}
            onMouseMove={e => this.handleMouseMove(e)}
        >
                <MiniMapStaticComponent app={this.props.app} size={this.getSize()} highlight={null}
                                        drawingActivePosition={true}
                                        drawLastPosition={true}
                    onCanvasRender={(serialized,canvas) => this.onCanvasRender(serialized,canvas)}
                />
            </div>
        // return <div className="mini-map shadow-2-strong">
        //     <canvas width={this.getSize().x}
        //             height={this.getSize().y}
        //             onMouseDown={e => this.handleMouseDown(e)}
        //             onMouseUp={e => this.handleMouseUp(e)}
        //             onMouseMove={e => this.handleMouseMove(e)}
        //             className="mini-map-canvas" ref={this._canvasRef}/>
        // </div>
    }
}
