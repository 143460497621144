import {MapNode} from "./MapNode";
import {MapObject} from "../map/MapObject";
import {Vector2} from "../map/Vector2";

import {Model} from "../model/Model";
import {Base64} from "js-base64";

import sha256 from "crypto-js/sha256";
import {MapNodeType} from "./MapNodeType";
import {PendingOperations} from "../lib/PendingOperation";
import {DelayedOperation} from "../lib/DelayedOperation";
import {ModelUtil} from "../model/ModelUtil";
import {DaemonUtils} from "../utils/DaemonUtils";
import {MapUserAccess} from "./MapUserAccess";
import {MapScope} from "./MapScope";
import {MapInvitation} from "./MapInvitation";

export class MapService {

    private _maps: MapScope[] = null;
    private _mapAccesses: MapUserAccess[] = null;
    private _otherMapAccesses: MapUserAccess[] = null;
    private _invitations: MapInvitation[] = null;

    private _userNames = {};
    private _userColors = {};
    private _userColorsInverted = {};

    constructor(public pendingOperations: PendingOperations) {

    }

    public getUserName(userId: number): string {
        if (undefined === this._userNames[userId]) {
            this._userNames[userId] = null;

            const test1 = this._mapAccesses.find(a => ModelUtil.entityIdToNumeric(a.user) === userId);
            if (null != test1) {
                this._userNames[userId] = test1.getTitle();
            }
            const test2 = this._otherMapAccesses.find(a => ModelUtil.entityIdToNumeric(a.user) === userId);
            if (null != test2) {
                this._userNames[userId] = test2.getTitle();
            }

        }
        return this._userNames[userId];
    }

    public getUserColor(userId,inverted=false): string {
        const db = !inverted ? this._userColors : this._userColorsInverted;
        if (undefined === db[userId]) {
            const string =  ""+userId+"_"+this.getUserName(userId);
            var hash = 0;
            if (string.length === 0) return ""+hash;
            for (var i = 0; i < string.length; i++) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
                hash = hash & hash;
            }
            var color = '#';
            let sum = 0;
            for (var i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 127;
                if (inverted) {
                    value = 255 - value;
                }
                sum += value;
                color += ('00' + value.toString(16)).substr(-2);
            }
            // if (!inverted) {
            //     if (sum > 255 * 3 / 2) {
            //         return "white";
            //     } else {
            //         return "black";
            //     }
            // }
            db[userId] = color;
        }
        return db[userId];
    }

    public getMapAccessForMapNode(mapNode: MapNode) {
        let result = this._mapAccesses.find(el => el.getMapId() == mapNode.mapId);
        if (null == result) {
            result = this._otherMapAccesses.find(el => el.getMapId() == mapNode.mapId);
        }
        return result;
    }

    getMapsLoaded(): MapScope[] {
        return this._maps;
    }

    getOtherMapAccessesLoaded(map: MapScope = null): MapUserAccess[] {
        if (null != map) {
            return this._otherMapAccesses.filter(el => el.map == "/maps/"+map.id);
        }
        return this._otherMapAccesses;
    }

    getInvitationsLoaded(): MapInvitation[] {
        return this._invitations;
    }


    async fetchMyMaps() {
        if (null === this._maps) {
            this._maps = [];
            const result = await (await Model.rest("my_maps")).json();
            this._maps = result.maps.map(obj => Object.assign(new MapScope(),obj));
            for(const map of this._maps) {
                map.usageLimit = result.usageLimit[map.id];
            }
            this._mapAccesses = result.mapAccesses.map(obj => Object.assign(new MapUserAccess(),obj));
            this._otherMapAccesses = result.otherMapAccesses.map(obj => Object.assign(new MapUserAccess(),obj));
            this._invitations = result.invitations.map(obj => Object.assign(new MapInvitation(),obj));
        }
        return this._maps;
    }

    async saveMapPermissions(mapAccess: MapUserAccess) {
        const result = await (await Model.rest("save_map_permissions","POST",{
            "mapAccessId": mapAccess.id,
            "readOnly": mapAccess.readOnly,
        })).json();
        if (result.error) {
            console.error(result.error);
            throw new Error(result.error);
        }
        Object.assign(mapAccess,result.mapAccess);
    }

    async shareMap(map: MapScope, email: string, readOnly: boolean) {
        const result = await (await Model.rest("share_map","POST",{
            "mapId": map.id,
            "readOnly": readOnly,
            "email": email
        })).json();
        if (result.error) {
            console.error(result.error);
            throw new Error(result.error);
        }
        const mapAccess = Object.assign(new MapUserAccess(),result.mapAccess);
        this._otherMapAccesses.push(mapAccess);
        return mapAccess;
    }

    async unShareMap(mapUserAccess: MapUserAccess) {
        const result = await (await Model.rest("unshare_map","POST",{
            "id": mapUserAccess.id
        })).json();
        if (result.error) {
            throw new Error(result.error);
        }
        this._otherMapAccesses.splice(this._otherMapAccesses.indexOf(mapUserAccess),1);
        return true;
    }

    async addMap(title: string) {
        const map = new MapScope();
        map.title = title;

        const result = await (await Model.rest("add_map","POST",{
            "title": title,
            "public": false,
            "publicReadOnly": false
        })).json();
        if (result.error) {
            throw new Error(result.error);
        }
        Object.assign(map,result.map);

        this._maps.push(map);
        return map;
    }

    async updateMap(map: MapScope) {

        const result = await (await Model.rest("update_map","POST",{
            id: map.id,
            title: map.title,
            miniMap: map.miniMap
        })).json();

        return map;

//         const query = `mutation($id: ID!,$title: String!,$miniMap: String!) {
//   updateMap(input: {id: $id,
//   title: $title,
//   miniMap: $miniMap,
//   }) {
//     map {
//       id
//     }
//   }
// }`;
//         console.log("update map");
//         return Model.mutate(query, {
//             id: `/maps/${map.id}`,
//             title: map.title,
//             miniMap: map.miniMap
//         })
    }

    async deleteMap(map: MapScope) {

        return await (await Model.rest("delete_map","POST",{
            id: map.id
        })).json();
//
//         const query = `mutation($id: ID!) {
//   deleteMap(input: {id: $id,
//   }) {
//     map {
//       id
//     }
//   }
// }`;
//
//         return Model.mutate(query, {
//             id: "/maps/"+map.id
//         })
    }

    requestUpdateMap(map: MapScope) {
        DelayedOperation.run("update-map-"+map.id,() => {
           this.updateMap(map);
        });
    }


    async acceptInvitation(invitation: MapInvitation) {
        const result = await (await Model.rest("accept_invitation","POST",{
            "id": invitation.accessId
        })).json();
        if (result.error) {
            throw new Error(result.error);
        }
        const map = Object.assign(new MapScope(),result.map);
        this._maps.push(map);
        this._mapAccesses.push(Object.assign(new MapUserAccess(),result.mapAccess));
        this._invitations.splice(this._invitations.indexOf(invitation),1)
        return map;
    }

    async rejectInvitation(invitation: MapInvitation) {
        const result = await (await Model.rest("reject_invitation","POST",{
            "id": invitation.accessId
        })).json();
        if (result.error) {
            throw new Error(result.error);
        }
        this._invitations.splice(this._invitations.indexOf(invitation),1)
        return true;
    }


}