import React, {useState} from 'react';

import './flag.css';


import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import {SelectIconComponent} from "../../widgets/select-icon/SelectIconComponent";
import {ICONS} from "../../../lib/Icons";
import {MapNodeFlag} from "../../../node/MapNodeType";
import {MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {SelectEmojiComponent} from "../../widgets/select-emoji/SelectEmojiComponent";


export interface FlagEditComponentProps {

    app: AppController,
    mapNode: MapNode,
    callback: Function

}
export interface FlagEditComponentState {
    selectedColor: string,
    iconIndex: number,
    type: number

}


export class FlagEditComponent extends React.Component<FlagEditComponentProps,FlagEditComponentState> {


    constructor(props,state) {
        super(props,state);
    }


    componentDidMount() {
        const selectedColor = this.props.mapNode.getOptionsObject().get(1,false);
        const selectedIcon = this.props.mapNode.getOptionsObject().get(0,true);
        const selectedType = this.props.mapNode.getOptionsObject().get(2,true);

        this.setState({
            selectedColor: (undefined !== selectedColor?selectedColor:MapNodeFlag.DEFAULT_COLOR),
            iconIndex: (null !== selectedIcon && !isNaN(selectedIcon))?selectedIcon:MapNodeFlag.DEFAULT_ICON,
            type: (null !== selectedType && !isNaN(selectedType))?selectedType:0
        },() => {
            this._initialType = this.state.type;
            this._initialIcon = this.state.iconIndex;
        });


    }

    componentWillUnmount() {

    }



    getIcon() {
        return ICONS[this.state.iconIndex];
    }

    private setOption(option,value) {
        console.log("set value",option,value);
        const options = this.props.mapNode.getOptionsObject();
        options.set(option,value);
        this.props.mapNode.setOptionsObject(options);
        this.props.app.mapNodeService.requestUpdateMapNode(this.props.mapNode);
        if (undefined != this.props.callback) {
            this.props.callback();
        }
    }

    onSelectIcon(icon: number) {
        this.setOption(0,icon);
        this.setState({iconIndex: icon});
    }

    onColorChange(e) {
        this.setState({selectedColor: e.target.value});
        this.setOption(1,this.state.selectedColor);
    }

    private _initialType = null;
    private _initialIcon = null;

    private setType(type) {
        let icon = this.state.iconIndex;
        if (type !== this.state.type) {
            if (type === this._initialType) {
                this.setOption(0,this._initialIcon);
                icon = this._initialIcon;
            } else {
                this.setOption(0,0);
                icon = 0;
            }
        }
        this.setOption(2,type);
        this.setState({type: type,iconIndex: icon});
    }


    render() {
        if (null == this.state) {
            return <div/>;
        }
        return <div className="map-node-flag-options-opened  app-ui">
            <div className="btn-group btn-group-sm text-center map-node-flag-options-choose-type" role="group" >
                <button type="button"
                        onClick={e => this.setType(0)}
                        className={"btn btn-outline-"+(0 == this.state.type ? "primary" : "dark")} >Icon</button>
                <button type="button"
                        onClick={e => this.setType(1)}
                        className={"btn btn-outline-"+(1 == this.state.type ? "primary":"dark")} >Emoji</button>

            </div>
            {0 == this.state.type ?
                <div><SelectIconComponent color={this.state.selectedColor} onSelectIcon={icon => this.onSelectIcon(icon)} selectedIcon={this.state.iconIndex} />
                <input onChange={e => this.onColorChange(e)} type="color" className="map-node-flag-options-color" />
                </div>
                : <SelectEmojiComponent selectedIcon={this.state.iconIndex} onSelectIcon={icon => this.onSelectIcon(icon)} />}
            </div>}
}
