import React from 'react';
import {AppController} from "../../../controllers/AppController";

import "./map-choose.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {MiniMapStaticComponent} from "../minimap/MiniMapStaticComponent";
import {Vector2} from "../../../map/Vector2";
import {DropdownButtonComponent} from "../../widgets/dropdown-button/DropdownButtonComponent";
import {MapScope} from "../../../node/MapScope";

export interface MapChooseComponentProps {
    app: AppController
}

export interface MapChooseComponentState {
    menuOpened: boolean
}

export class MapChooseComponent extends React.Component<MapChooseComponentProps, MapChooseComponentState> {


    constructor(props) {
        super(props);
        this.state = {menuOpened: false};
    }

    toggleMapMenu(): void {
        this.setState({menuOpened: !this.state.menuOpened});
    }

    chooseMap(map) {
        document.location.href=map.getMapLink(new Vector2(0,0),0);
    }

    addMap() {
        const name = prompt("Enter name of map");
        if (null != name && name.length > 0) {
            (async() => {
                const map = await this.props.app.mapService.addMap(name);
                this.chooseMap(map);
                // this.forceUpdate();
            })();

        }
    }


    changeMapName(map: MapScope) {
        const newMapName = prompt("Enter new name of the map");
        if (null != newMapName && newMapName.trim().length > 0) {
            map.title = newMapName;
            this.props.app.mapService.updateMap(map).then(() => {
                console.log("updated");
            });
            this.forceUpdate();
        }
    }

    deleteMap(map: MapScope) {
        if (map === this.props.app.currentMap) {
            alert("Cannot delete current map");
            return;
        }
        if (window.confirm(`Are you sure you want to delete map ${map.title} and ALL its contents?`)) {
            this.props.app.mapService.deleteMap(map).then(resp => {
                if (undefined != resp.error) {
                    alert(resp.error);
                } else {
                    window.location.reload();
                }
            },err => {
                alert(err.message);
            });
        }
    }

    cloneMap(map: MapScope) {
        alert("Not implemented yet");
    }

    render() {
        let modal = null;
        if (this.state.menuOpened) {
            modal = <div className="map-choose-open-component">
                <ModalComponent onClose={() => this.toggleMapMenu()} title="Choose a Map or add a new one">

                    <div className="map-choose-items-container row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4">


                            {this.props.app.mapService.getMapsLoaded().map(map => <div key={"map-choose-"+map.id} className="col"><div


                                className={"card   "+(map === this.props.app.currentMap?"shadow-5-strong":"")}>
                                <div className="card-header map-choose-button"
                                     onClick={e => this.chooseMap(map)}
                                >
                                <MiniMapStaticComponent app={this.props.app}
                                                        size={new Vector2(200,200)}
                                                        highlight={null}
                                                        fromObjects={map.getMiniMapUnserialized()}
                                                        />
                                </div>
                                <div className="card-body row">
                                    <div className="col-9">
                                        <h5 className="card-title ">
                                            {map.title}
                                        </h5>
                                    </div>
                                    <div className="col-3 text-right">
                                        {/*<i className="map-menu-button fas fa-ellipsis-v" />*/}
                                        <DropdownButtonComponent buttons={[
                                            {"title": "Change the name","onClick": () => this.changeMapName(map)},
                                            {"title": "Clone the map","onClick": () => this.cloneMap(map)},
                                            {"title": "Delete","onClick": () => this.deleteMap(map),"className": "text-danger"},
                                        ]} />
                                    </div>

                                </div>

                            </div></div>)}

                        <div className="col map-add-button">
                            {/*<div className=" card map-add-button">*/}
                                {/*<div className="map-add-button-container">*/}
                                <button onClick={e => this.addMap()} className="btn btn-floating btn-light btn-lg ripple-surface-dark">
                                    <i className="fa fa-plus" />
                                </button>
                                {/*</div>*/}
                                {/*<div className="card-body">*/}
                                {/*<h5 className="card-title text-center">*/}
                                {/*    add*/}
                                {/*</h5>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>



                    </div>

                </ModalComponent>
            </div>;
        }
        return <div className="map-choose-component bg-light shadow-5 text-dark row">
            <div className="col-10">
                <b>{this.props.app.currentMap.title.substring(0,15)}</b>
            </div>
            <div className="col-2"
                 data-tooltip="Change map"
                 data-tooltip-position="right"
            >
                <i onClick={e => this.toggleMapMenu()}

                   className="fas fa-globe map-choose-button" />
            </div>
            {modal}
        </div>
    }
}
