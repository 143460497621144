import React from 'react';
import {AppController} from "../../../controllers/AppController";

import "./tooltips.css";
import {DomUtil} from "../../../utils/DomUtil";
import {Vector2} from "../../../map/Vector2";

export interface TooltipsComponentProps {
    app: AppController
}

export interface TooltipsComponentState {
    tooltip: string,
    position: Vector2
}

export class TooltipsComponent extends React.Component<TooltipsComponentProps, TooltipsComponentState> {

    private _handle;
    private _clickHandle;
    private _lastTarget = null;

    constructor(props) {
        super(props);
        this.state = {tooltip: "",position: new Vector2(0,0)};

    }

    setTooltip(tooltip,position: Vector2 = new Vector2(0,0)) {

        if (this.state.tooltip !== tooltip) {
            this.setState({"tooltip": tooltip,position: position});
        }
    }

    getTooltipPosition(el): Vector2 {
        let position = el.getAttribute("data-tooltip-position");
        if (undefined == position) {
            position = "top";
        }

        const tooltipSize = new Vector2(el.getAttribute("data-tooltip").length * 12 + 20,30);
        const tooltipMargin = 15;

        const rect = el.getBoundingClientRect();

        switch(position) {
            case "top":
                return new Vector2(rect.x + rect.width / 2 - tooltipSize.x / 2,rect.y - tooltipSize.y - tooltipMargin);
            case "bottom":
                return new Vector2(rect.x + rect.width / 2 - tooltipSize.x / 2,rect.bottom + tooltipMargin);
            case "right":
                return new Vector2(rect.right + tooltipMargin,rect.y + (rect.height - tooltipSize.y) / 2);
            default:
                return new Vector2(0,0);
        }

    }

    componentDidMount() {
        this._handle = e => {
            const el = DomUtil.getOfAttributeOrParent(e.target,"data-tooltip",2);
            if (null != el) {
                if (this._lastTarget != el) {
                    this._lastTarget = el;
                    this.setTooltip(
                        el.getAttribute("data-tooltip"),
                        this.getTooltipPosition(el)
                    );
                }

            } else {
                this.setTooltip("");
                this._lastTarget = null;
            }
        };
        this.props.app.io.onMouseMove.push(this._handle);

        this._clickHandle = () => {
            if (this.state.tooltip.length > 0 && null != this._lastTarget) {
                if (this._lastTarget.getAttribute("data-tooltip-click-keep") == undefined) {
                    this.setState({"tooltip": ""});
                } else {
                    setTimeout(() => {
                        if (null != this._lastTarget) {
                            const tooltip = this._lastTarget.getAttribute("data-tooltip");
                            if (tooltip != this.state.tooltip) {
                                this.setTooltip(tooltip,this.getTooltipPosition(this._lastTarget))
                            }
                        }
                    },100);

                }

          }
        };
        this.props.app.io.onMouseUp.push(this._clickHandle);
    }

    componentWillUnmount() {
        this.props.app.io.onMouseMove.splice(this.props.app.io.onMouseMove.indexOf(this._handle),1);
        this.props.app.io.onMouseUp.splice(this.props.app.io.onMouseUp.indexOf(this._clickHandle),1);

    }



    render() {

        if (0 == this.state.tooltip.length) {
            return null;
        }
        let tooltip = this.state.tooltip;
        let keyboardShortcut = null;
        const keyboardTest = /\((.*)\)$/.exec(tooltip);
        if (null != keyboardTest && undefined !== keyboardTest[1]) {
            keyboardShortcut = keyboardTest[1];
            tooltip = tooltip.replace(/\((.*)\)$/,"");
        }

        return <div className={"tooltips-component"} style={{"top": this.state.position.y,"left": this.state.position.x}}>
            {tooltip}
            {null != keyboardShortcut ? <kbd className="keyboard-shortcut">{keyboardShortcut}</kbd> : null}
        </div>
    }
}
