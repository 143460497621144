import {MapNode} from "../node/MapNode";
import {AppController} from "../controllers/AppController";
import {MapNodeService} from "../node/MapNodeService";

export class FocusHistory {

    public focusHistory: MapNode[] = [];

    constructor(public mapId: number,public mapNodeService: MapNodeService) {
    }

    private getSessionKey() {
        return "focusHistory."+this.mapId;
    }

    load() {
        const data = sessionStorage.getItem(this.getSessionKey());
        if (null != data) {
            const ids = JSON.parse(data);
            //console.log(ids);
            if (null != ids) {
                this.focusHistory = ids.map(id => this.mapNodeService.getById(id)).filter(el => undefined != el);
            }
        }
    }

    save() {
        sessionStorage.setItem(this.getSessionKey(),JSON.stringify(this.focusHistory.filter(el => null != el).map(el => el.id)));
    }

    onFocus(focus: MapNode) {
        if (-1 !== this.focusHistory.indexOf(focus)) {
            this.focusHistory.splice(this.focusHistory.indexOf(focus),1);
        }
        this.focusHistory.push(focus);
        if (this.focusHistory.length > 9) {
            this.focusHistory.splice(0,1);
        }
        this.save();
    }

    remove(mapNode: MapNode) {
        this.focusHistory.splice(this.focusHistory.indexOf(mapNode),1);
        this.save();
    }
}