import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./todo-icon.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {MapInvitation} from "../../../node/MapInvitation";
import {MapNodeType, MapNodeTypeEnum} from "../../../node/MapNodeType";
import {DaemonUtils} from "../../../utils/DaemonUtils";
import {MapNode} from "../../../node/MapNode";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";

export interface TodoIconComponentProps {
    app: AppController
}

export interface TodoIconComponentState {
    importance: number,
    dropdownOpen: boolean
}

export class TodoIconComponent extends React.Component<TodoIconComponentProps, TodoIconComponentState> {


    private _onImportanceChange = null;

    constructor(props) {
        super(props);
        this.state = {importance: 0, dropdownOpen: false};

    }

    recalculateImportance() {
        let importance = 0;
        this.props.app.getNodesVisible().forEach(mapNode => {
            if (MapNodeTypeEnum.TODO === mapNode.type) {
                const nodeImportance = mapNode.getOptionsObject().get(0);
                if (nodeImportance > importance) {
                    importance = nodeImportance;
                }
            }
        });
        this.setState({
            "importance": importance
        });
    }

    componentDidMount() {
        this._onImportanceChange = () => this.recalculateImportance();
        this.props.app.onTodoImportanceChange.push(this._onImportanceChange);
        DaemonUtils.waitFor(() => this.props.app.mapNodeService.isLoaded()).then(() => {
            this.recalculateImportance();
        });
    }

    componentWillUnmount() {
        this.props.app.onTodoImportanceChange.splice(this.props.app.onTodoImportanceChange.indexOf(this._onImportanceChange), 1);
    }

    // goTodo() {
    //     const todos = this.props.app.mapNodeService.getNodesLoaded().filter(
    //         node => MapNodeTypeEnum.TODO == node.type && node.getOptionsObject().get(0) == this.state.importance
    //     );
    //     let goto = todos[0];
    //     if (null != this.props.app.focus) {
    //         const index = todos.indexOf(this.props.app.focus);
    //         if (-1 !== index) {
    //             goto = todos[(index + 1) % todos.length];
    //         }
    //     }
    //     const mapPos = goto.mapObject.position;
    //     this.props.app.setLevel(goto.mapObject.level);
    //     this.props.app.setViewerPosition(mapPos);
    //     this.props.app.setFocus(goto, true);
    // }

    getTodos() {
        return this.props.app.getNodesVisible().filter(
            node => MapNodeTypeEnum.TODO == node.type && node.getOptionsObject().get(0) > 0 && node.getOptionsObject().get(0) !== 3// == this.state.importance
        ).sort((a: MapNode,b: MapNode) => {
            if (a.getOptionsObject().get(0) > b.getOptionsObject().get(0)) {
                return -1;
            } else if (a.getOptionsObject().get(0) < b.getOptionsObject().get(0)) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    onTodoClick(todo: MapNode) {
        const mapPos = todo.mapObject.position;
        this.props.app.setLevel(todo.mapObject.level);
        this.props.app.setViewerPosition(mapPos);
        this.props.app.setFocus(todo, true);
        this.setState({dropdownOpen: false});
    }

    // getImportanceColorClass() {
    //     switch(this.state.importance) {
    //         case 1:
    //             return "btn-warning";
    //         case 2:
    //             return "btn-danger";
    //         case 3:
    //             return "btn-secondary";
    //     }
    // }
    //
    // getImportanceIconClass() {
    //     switch(this.state.importance) {
    //         case 1:
    //             return "btn-warning";
    //         case 2:
    //             return "btn-danger";
    //         case 3:
    //             return "btn-secondary";
    //     }
    // }

    render() {

        if (0 == this.state.importance) {
            return null;
        }


        let tooltipTitle = "";
        if (!this.state.dropdownOpen) {
            tooltipTitle = (2 == this.state.importance ? "Deadly important taks" : "Important tasks");
        }

        return <span className="todo-icon-component">
            <div className="btn-group"
                 data-tooltip={tooltipTitle}
                 data-tooltip-position="bottom"

            >
                <button

                    onClick={e => this.setState({dropdownOpen: !this.state.dropdownOpen})}

                className={"todo-icon-dropdown-button btn btn-floating dropdown-toggle ripple-surface " + (2 == this.state.importance?"btn-danger":"btn-warning") }
                >
                <i
                   className={(2 == this.state.importance) ? "fas fa-skull-crossbones " : "fas fa-exclamation-circle "}
                />
            </button>
                {this.state.dropdownOpen?
                    <OutsideClickComponent ignoreClass="todo-icon-dropdown-button" onOutsideClick={() => this.setState({dropdownOpen: false})}>
                        <ul className="dropdown-menu open-toolbar todo-main-menu-dropdown"
                            data-popper-placement="bottom-start"
                        >
                            {this.getTodos().map(mapNode => <li key={mapNode.id}>
                                <a className="dropdown-item" href="#" onClick={e => this.onTodoClick(mapNode)}>
                                    <i className={(2 == mapNode.getOptionsObject().get(0)) ?
                                        "fas fa-skull-crossbones text-danger" : "fas fa-exclamation-circle text-warning"}
                                    />
                                    {null == mapNode.title || 0 == mapNode.title.trim().length ? <span className="text-muted">No title</span>:mapNode.title}
                                </a>
                            </li> )}
                        </ul>
                    </OutsideClickComponent>
                    :null}
            </div>


        </span>
    }
}
