 import React from 'react';
import {Vector2} from "../map/Vector2";
import {MapObject} from "../map/MapObject";

import {MapNodeComponent} from "./MapNodeComponent";
import {MapGridComponent} from "./MapGridComponent";
import {DebugComponent} from "./DebugComponent";
import {DomUtil} from "../utils/DomUtil";

import {MainToolsComponent} from "./MainToolsComponent";
import {AppController} from "../controllers/AppController";
import {MapAction, MapActionComponent, MapActionType} from "./MapActionComponent";
 import {SelectedToolsComponent} from "./tools/SelectedToolsComponent";
 import {MapNode} from "../node/MapNode";
 import md5 from 'crypto-js/md5';

 import {DisplayArea} from "../lib/DisplayArea";
 import {start} from "repl";
 import {LoadingComponent} from "./widgets/loading/LoadingComponent";
 import {NotificationsComponent} from "./ui/notifications/NotificationsComponent";
 import {CommentsModalComponent} from "./ui/comments/CommentsModalComponent";
 import {TooltipsComponent} from "./ui/tooltips/TooltipsComponent";
 import {ModalComponent} from "./widgets/modal/ModalComponent";
 import {BottomToolsComponent} from "./tools/BottomToolsComponent";
 import {FlagModalComponent} from "./nodes/flag/FlagModalComponent";
 import {DaemonUtils} from "../utils/DaemonUtils";
/**
 *
 * @todo

 * - paste image upload aws
 * - viewerState in uri
 *  - note -> mapNode
 * - previewPanel
 * - remove node
 * - level up / down selected
 *
 * - esc cancel add
 * - dbl click zoom in position
 * - select area shift
 * - add new same size as focus
 *
 */

export interface AppComponentProps {

}

 export interface AppComponentState {
    viewerState: MapObject,
     mapAction: MapAction,
     mapMoving: boolean,
     focus: MapNode,
     loading: boolean
 }

export class AppComponent extends React.Component<AppComponentProps,AppComponentState> {

    static instance: AppComponent;

    private app: AppController;


    constructor(props) {
        super(props);



        this.state = {
            viewerState: null,
            mapAction: null,
            mapMoving: null,
            focus: null,
            loading: true,
        }


    }

    private _listeners = {};

    private windowListener(name,listener,options=undefined) {

        this._listeners[name] = listener;
        window.addEventListener(name,listener,options);
    }


    componentDidMount() {
        this.app = new AppController();
        this.app.initLoad().then(() => {
            this.setState({loading: false});
        });
        this.app.onViewerStateChange.push(() => {
            this.setState({viewerState: this.app.viewerState});
        });


        // this.viewerState = new MapObject(new Vector2(0, 0),
        //     new Vector2(0, 0),0);
        this.windowListener("resize", ev => {
            this.updateWindowSize();

        })
        this.updateWindowSize();
        // this.noteService = new NoteService();

        this.windowListener("contextmenu", e => {
            const target: any = e.target;
            if (undefined !== target
                && undefined !== target.classList
                && target.classList.contains("map-grid")) {
                // //console.log("map grid",e);
                e.preventDefault();
            }
        });
        this.windowListener("keydown",e => {
            this.app.io.handleKeyDown(e);
            this.initArrowKeys();
        });
        this.windowListener("keyup",e => {
            this.app.io.handleKeyUp(e);
            switch(e.key) {
                case "Escape":
                    if (ModalComponent.opened.length > 0) {
                        ModalComponent.opened[ModalComponent.opened.length-1].onClose();
                    } else {
                        if (null != e.target && undefined !== e.target["blur"]) {
                            e.target["blur"]();
                        }
                        if (null != this.app.mapAction) {
                            this.app.mapAction = null;
                            this.setState({mapAction: null});
                        } else if (this.app.getSelected().length > 0) {
                            this.app.deselectAll();
                        }
                    }
                    break;
            }

        });
        let lastWheel = null;
        let lastNotify = null;

        let lastMapScroll = null;

        let lastWheelMove = null;
        let onWheelStop = null;

        this.windowListener("wheel",e => {


            // console.log("wheel",e);
            if (e.ctrlKey || e.altKey || e.metaKey) {
                e.preventDefault();
                e.stopPropagation();
                if (!this.app.disableScrollZoom
                    // && !DomUtil.getOfClassOrParent(e.target,"app-ui")
                ) {
                    if (0 != e.deltaY) {
                        const move = e.deltaY > 0?-1:1;
                        if (null == lastWheel || new Date().getTime() > lastWheel+300) {
                            lastWheel = new Date().getTime();
                            this.app.moveLevel(move);
                        }
                    }

                }
            } else {
                if (this.app.io.mouseDown && 1 === this.app.io.mouseDownButton || new Date().getTime() - this.app.io.lastActivity.getTime() < 100) {
                    return;
                }
                // if (!DomUtil.getOfClassOrParent(e.target,"app-ui")) {
                // if (null != lastMapScroll) {
                //     console.log("lastMapScroll", new Date().getTime() - lastMapScroll);
                // }
                const lastScrollPass = (null != lastMapScroll && new Date().getTime() - lastMapScroll < 300 && new Date().getTime() - lastMapScroll > 0);
                if (!this.app.isFocusFullScreenEdit() &&
                    (null == e.target
                    // || document.activeElement !== e.target
                    || lastScrollPass
                    || !DomUtil.isElementScrollable(e.target,"app-node")
                    || !DomUtil.getOfClassOrParent(e.target,["focus","app-interface"]))

                    &&  0 === ModalComponent.opened.length
                    // && (document.activeElement !== e.target || lastScrollPass)
                    && !(this.app.io.mouseDown && 1 == this.app.io.mouseDownButton)
                ) {

                // if ((document.activeElement !== e.target || (null != lastMapScroll && new Date().getTime() - lastMapScroll < 100))
                //     && 0 === ModalComponent.opened.length
                //     && (null == e.target || (
                //         !DomUtil.getOfClassOrParent(e.target,["focus","app-search-results"])))
                // ) {
                    // this.app.mapMoving = true;
                    const move = new Vector2(-e.wheelDeltaX, -e.wheelDeltaY)
                        .divide(4)
                        .maximum(70)
                        .round()
                    ;
                    ;
                    this.app.moveViewerPosition(move);
                    e.preventDefault();
                    this.app.mapMoving = true;
                    lastWheelMove = new Date().getTime();
                    if (null == onWheelStop) {
                        onWheelStop = true;

                        DaemonUtils.waitFor(() => new Date().getTime() > lastWheelMove+500).then(() => {
                           onWheelStop = null;
                           this.app.mapMoving = false;
                            this.app.io.onWheelStopMovingMapMove.forEach(call => call());
                        });
                    }
                }

                // if (!DomUtil.getOfClassOrParent(e.target,"app-ui") && (null == lastNotify || new Date().getTime() - lastNotify > 5000)) {
                //     lastNotify = new Date().getTime();
                //     this.app.notify("Use ctrl+scroll to zoom out / zoom in","info");
                // }
            }

        },{passive: false});

        // this.app.io.onMouseMove.push(e => {
        //     if (null != lastMapScroll) {
        //         lastMapScroll = null;
        //         console.error("NULL ON MOUSE MOVE SCROLL !!!");
        //     }
        // });

        // document.addEventListener('wheel', function(e) {
        //     e.ctrlKey && e.preventDefault();
        // }, {
        //     passive: false,
        // });


        window.addEventListener("blur",e => {
            //console.log("WINDOW BLUR");
            this.app.io.cancelAllKeysDown();
        });
        window.addEventListener("focus",e => {
           // console.log("WINDOW FOCUS");
           this.updateWindowSize();
        });

        // window["appcomponent"] = this;

    }



    private _arrowKeysHandle = null;

    private initArrowKeys() {
        if (null != this._arrowKeysHandle) {
            return;
        }
        if (!this.app.io.keysDown["ArrowUp"] && !this.app.io.keysDown["ArrowDown"] && !this.app.io.keysDown["ArrowLeft"] && !this.app.io.keysDown["ArrowRight"]) {
            return;
        }
        if (null != document.activeElement && (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")
        || ModalComponent.opened.length > 0) {
            return;
        }
        this._arrowKeysHandle = setInterval(() => {
            const keys = this.app.io.keysDown;
            const speed = 10;
            const vector = new Vector2(0,0);
            if (keys["ArrowUp"]) {
                vector.y -= speed;
            }
            if (keys["ArrowDown"]) {
                vector.y += speed;
            }
            if (keys["ArrowLeft"]) {
                vector.x -= speed;
            }
            if (keys["ArrowRight"]) {
                vector.x += speed;
            }
            if (vector.x != 0 || vector.y != 0) {
                this.app.mapMoving = true;
                this.app.moveViewerPosition(vector);
            } else {
                this.app.mapMoving = false;
                clearInterval(this._arrowKeysHandle);
                this._arrowKeysHandle = null;
                this.app.onViewerStateChangeCall();
            }
        },10);
    }

    componentWillUnmount() {
        for(const k in this._listeners) {
            window.removeEventListener(k,this._listeners[k]);
        }
    }

    updateWindowSize(): void {
        this.app.viewerState.size = new Vector2(window.outerWidth, window.innerHeight);
        //console.log("resize",this.app.viewerState.size);
        this.setState({viewerState: this.app.viewerState});
        this.app.log["viewerState"] = "" + this.app.viewerState;

        //console.log("set body zoom ",window.innerWidth / window.outerWidth);
        document["body"].style["zoom"] = "" + window.innerWidth / window.outerWidth;


    }


    private handleMouseDown(event) {
        this.app.io.handleMouseDown(event);
        if (event.button === 1) {
            this.app.mapMoving = true;
        }
    }


    private handleMouseUp(event) {

        // this.app.io.mouseDown = false;
        this.app.mapMoving = false;
        this.setState({"mapMoving": this.app.mapMoving});

        if (4 > this.app.io.mouseDownTraveled) {
            const nodeEl: any = DomUtil.getOfClassOrParent(event.target, "app-node");

            if (null != nodeEl) {

                if (DomUtil.getOfClassOrParent(event.target, "app-node-ignore-focus")) {
                    return;
                }

                const shift = this.app.io.isKeyDown("Shift");
                const mapNode = this.app.mapNodeService.getById(nodeEl.getAttribute("data-id"));
                this.app.setFocus(mapNode,
                    !shift
                    );
                if (!shift
                    && !DomUtil.getOfClassOrParent(event.target, "app-ui-no-center-map")
                    && mapNode.getTypeObject().isFittingScreenOnFocus()
                ) {
                    if (!this.app.isFittingScreen(mapNode.mapObject)
                        && !this.app.isDesktopUncomfortable(mapNode.mapObject)
                    ) {
                        const moveVector = this.app.getNotFittingScreenVector(mapNode.mapObject);
                        if (null != moveVector) {
                            if (this.app.io.isMobile()) {
                                this.app.moveViewerPosition(moveVector);
                            }
                        } else {

                            this.app.goto(mapNode.mapObject,1);
                        }
                    }
                }
                this.setState({focus: this.app.focus});
            } else {
                if (!DomUtil.getOfClassOrParent(this.app.io.mouseDownTarget, "app-ui")) {
                    switch (this.app.io.mouseDownButton) {
                        case 0:
                            if (!this.app.io.isKeyDown("Shift")) {
                                this.app.deselectAll();
                            }
                            break;
                        case 2:
                            // const canvasPos = new Vector2(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
                            // const selectedArea = this.app.getSelectedDisplayArea();
                            // const diff = canvasPos.minus(selectedArea.center());
                            // this.app.getSelected().forEach(node => {
                            //     if (this.app.userService.hasWriteRights(node)) {
                            //         const zoom = this.app.getDisplayZoom(node.mapObject.level);
                            //         node.mapObject.position = node.mapObject.position.sum(diff.divide(zoom));
                            //         this.app.mapNodeService.requestUpdateMapNode(node);
                            //     }
                            //
                            // });
                            // this.app.onViewerStateChangeCall();

                            // const position = this.app.canvasPositionToMapPosition(canvasPos);
                            // this.app.setSelectedNotesPosition(position);
                            break;
                    }
                }


            }
        }
        this.app.io.handleMouseUp(event);
        // this.app.io.mouseDownTraveled = 0;
    }


    private onMouseDownMove(move:Vector2,position: Vector2,event) {
        if (1 == this.app.io.mouseDownButton) {
            this.app.mapMoving = true;
            this.app.moveViewerPosition(move);
            event.preventDefault();
            return;
        }
        if ((null == this.app.io.mouseDownTarget
            || !DomUtil.getOfClassOrParent(this.app.io.mouseDownTarget, "app-ui"))) {



            switch (this.app.io.mouseDownButton) {
                case 0:

                    if (!this.app.io.isKeyDown("Shift")) {
                        this.app.mapMoving = true;
                        this.app.moveViewerPosition(move);
                    } else {
                        if (null == this.app.mapAction) {
                            const startPoint = position;
                            this.app.setMapAction(new MapAction(MapActionType.SelectArea, {}, endPoint => {
                                const displayArea = DisplayArea.fromPositions(startPoint,endPoint);
                                // //console.log(displayArea,startPoint,endPoint);
                                const toSelect = this._nodesDisplayable.filter(mapNode => this.app.isInDisplayArea(mapNode.mapObject,displayArea));
                                toSelect.forEach(obj => this.app.setSelected(obj));
                            }));
                        }

                    }

                    break;

                case 2:
                    if (null != this.app.focus) {
                        this.app.mapMoving = true;
                    }

                    break;
            }
        }
    }

    private handleMouseMove(event) {

        this.app.io.handleMouseMove(event);

        if (this.app.io.mouseDown) {
            const move = new Vector2(-event.movementX, -event.movementY);
            this.onMouseDownMove(move,new Vector2(event.nativeEvent.offsetX,event.nativeEvent.offsetY),event);


        } else {
            if (this.app.mapMoving) {
                this.app.mapMoving = false;
                this.setState({"mapMoving": this.app.mapMoving});
            }

        }
    }


    private _touchPos: Vector2 = null;

    handleTouchStart(e) {
        this.app.io.handleTouchStart(e);
        this._touchPos = new Vector2(e.touches[0].clientX, e.touches[0].clientY);
    }

    handleTouchMove(e) {
        this.app.io.handleTouchMove(e);
        const pos = new Vector2(e.touches[0].clientX, e.touches[0].clientY)
        const move = this._touchPos.minus(pos);
        this.onMouseDownMove(move,pos,e);
        this._touchPos = pos;
    }

    handleTouchEnd(e) {
        this.app.io.handleTouchEnd(e);
    }

    // private _nodesAll: MapNode[] = null;
    private _nodesDisplayable: MapNode[] = null;
    private _nodesDisplayableFor: string = null;

    getNodesDisplayable(): MapNode[] {
        if (null === this._nodesDisplayable) {
            this._nodesDisplayable = [];

            this.app.mapNodeService.getNodes().then(notes => {
                // this._nodesAll = notes;
                this.forceUpdate();
            });
            // this.app.mapNodeService.onLoadMapNodes.push(() => {
            //    this.forceUpdate();
            // });
        }
        const hash = md5(this.app.globalClass+ this.app.viewerState.toString() + this.app.getNodesVisible().map(el => el.mapObject.toString()).join(',')).toString();
        if (hash !== this._nodesDisplayableFor) {
            this._nodesDisplayableFor = hash;
            this._nodesDisplayable = this.app.getNodesVisible().filter(el => {

                return this.app.isDisplayable(el.mapObject) && -1 === this.app.globalClass.indexOf("no-type-"+el.getTypeObject().getInfo().getName())
            });
        }
        return this._nodesDisplayable;
    }



    render() {

        if (this.state.loading) {
            return <LoadingComponent fullPage={true} />;
        }

        const mapNodes = [];
        this.getNodesDisplayable().forEach(mapNode => mapNodes.push(<MapNodeComponent
            key={mapNode.id}
            app={this.app}
            mapNode={mapNode}

        />));
        let className = "";
        if (this.app.mapMoving) {
            className = "app-map-moving";
        }

        className += " "+this.app.globalClass;
        let mapAction = null;
        if (null != this.app.mapAction) {
            mapAction = <MapActionComponent app={this.app} />;
        }
        if (this.app.io.isTouch()) {
            className += " io-touch";
        }
        if (this.app.io.isMobile()) {
            className += " io-mobile";
        }
        if (this.app.io.isIOS()) {
            className += " io-ios";
        }


        return <div id="app"
                    className={className}
                    onMouseDown={e => this.handleMouseDown(e)}
                    onMouseUp={e => this.handleMouseUp(e)}
                    onMouseMove={e => this.handleMouseMove(e)}

                    onTouchStart={e => this.handleTouchStart(e)}
                    onTouchMove={e => this.handleTouchMove(e)}
                    onTouchEnd={e => this.handleTouchEnd(e)}

                    // onKeyDown={e => this.app.io.handleKeyDown(e)}

                    // onKeyUp={e => this.app.io.handleKeyUp(e)}
        >
            <MapGridComponent app={this.app}/>
            {mapAction}

            <div id="map-canvas">
            </div>
            <div id="notes-container">
                {mapNodes}
            </div>
            <MainToolsComponent app={this.app}/>
            <SelectedToolsComponent app={this.app} />
            <NotificationsComponent app={this.app} />
            <TooltipsComponent app={this.app} />
            <DebugComponent app={this.app} />
            <CommentsModalComponent app={this.app} />
            <FlagModalComponent app={this.app} />

            {!this.app.io.isMobile() && window.innerWidth / window.outerWidth > 1.03 || window.innerWidth / window.outerWidth < 0.97 ? <div
                className="app-component-zoom-alert"
            >
                Your browser has zoom applied, please use Ctrl/Command + 0 to set the zoom to 100%
            </div> : null}
        </div>;
    }
}
