import {Vector2} from "../map/Vector2";

export class DisplayArea {
    constructor(public position: Vector2, public size: Vector2) {

    }

    public static fromPositions(position1: Vector2,position2: Vector2): DisplayArea {
        const start = position1.lesserValues(position2);
        const end = position2.greaterValues(position1);
        return new DisplayArea(start,end.minus(start));
    }

    getStart(): Vector2 {
        return this.position;
    }

    getEnd(): Vector2 {
        return this.position.sum(this.size);
    }

    public getCorners(): Vector2[] {
        return [this.getStart(),
            this.getStart().sum(this.size.multiplyVector(new Vector2(1, 0))),
            this.getEnd(),
            this.getStart().sum(this.size.multiplyVector(new Vector2(0, 1))),
        ]
    }

    public getCornerIndex(vector: Vector2): any {
        const corners = this.getCorners();
        for(const i in corners) {
            if (vector.equals(corners[i])) {
                return i;
            }
        }
        return -1;
    }

    public getCollisionCheckPoints() {
        const result = this.getCorners();
        result.push(this.center());
        return result;
    }

    public isInside(point: Vector2): boolean {
        return point.isGreaterEqualThan(this.getStart()) && point.isLesserEqualThan(this.getEnd());
    }

    public isInsideAnotherArea(area: DisplayArea): boolean {
        for(const corner of this.getCollisionCheckPoints()) {
            if (!area.isInside(corner)) {
                return false;
            }
        }
        return true;
    }

    public getCornersNotInside(area: DisplayArea): Vector2[] {
        let result = [];
        for(const corner of this.getCollisionCheckPoints()) {
            if (!area.isInside(corner)) {
                result.push(corner);
            }
        }
        return result;
    }

    public isColliding(other: DisplayArea): boolean {
        for(const corner of this.getCollisionCheckPoints()) {
            if (other.isInside(corner)) {
                return true;
            }
        }
        for(const corner of other.getCollisionCheckPoints()) {
            if (this.isInside(corner)) {
                return true;
            }
        }
        return false;
    }

    public sum(other: DisplayArea): DisplayArea {
        const start = this.getStart().lesserValues(other.getStart());
        const end = this.getEnd().greaterValues(other.getEnd());
        const size = end.minus(start);
        return new DisplayArea(start,size);
    }

    public static sumAll(areas: DisplayArea[]): DisplayArea {
        if (0 === areas.length) {
            return new DisplayArea(new Vector2(0,0),new Vector2(0,0));
        }
        let result = areas[0];
        for(let i = 1; i < areas.length; i++) {
            result = result.sum(areas[i]);
        }
        return result;
    }

    toString() {
        return `A{${this.position},${this.size}}`;
    }

    public extendBorders(size: Vector2) {

        return new DisplayArea(this.position.sum(size.multiply(-1)),
            this.size.sum(size.multiply(2)));
    }

    public center(): Vector2 {
        return this.getStart().sum(this.size.multiply(0.5));
    }
}