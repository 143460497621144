export class InputUtil {

    public static onCtrlCSelectAll(e: any) {

        if ((e.code == "KeyC" || e.code == "KeyX") && (e.ctrlKey || e.metaKey)
            && e.target.selectionStart === e.target.selectionEnd) {
            // const lines = e.trget.value.split("\n");
            let newStart = e.target.selectionStart;
            let newEnd = e.target.selectionEnd;
            while(newStart > 0) {
                newStart--;
                if ("\n" === e.target.value[newStart]) {
                    newStart++;
                    break;
                }
            }
            while(newEnd < e.target.value.length) {
                if ("\n" === e.target.value[newEnd]) {
                    break;
                }
                newEnd++;
            }
            e.target.selectionStart = newStart;
            e.target.selectionEnd = newEnd;

        }
    }

}
