export class ModelUtil {

    public static entityIdToNumeric(id: any): number {
        if (!isNaN(id)) {
            return id;
        }
        if (/^\d+$/.test(""+id)) {
            return parseInt(id);
        }
        const test = /id=(\d+)$/.exec(id);
        if (test) {
            return parseInt(test[1]);
        }
        return parseInt(id.replace(/^\/(.*)\//,''))
    }
}