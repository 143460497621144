import React from 'react';
import {Vector2} from "../../map/Vector2";
import {MapObject} from "../../map/MapObject";

import {MiniMapComponent} from "../MiniMapComponent";
import {AppController} from "../../controllers/AppController";
import {MapAction, MapActionType} from "../MapActionComponent";
import {MapObjectList} from "../../map/MapObjectList";
import {DisplayObject} from "../../display/DisplayObject";
import {DisplayArea} from "../../lib/DisplayArea";




export interface SelectedToolsComponentProps {
    app: AppController

}

export class SelectedToolsComponent extends React.Component<SelectedToolsComponentProps> {



    constructor(props: SelectedToolsComponentProps) {
        super(props);

    }


    registerDragMove(e) {
        if (1 == e.button) {
            return;
        }
        const dragMoveEvent = (e,move) => {
            this.props.app.mapNodesMoving = true;
            this.props.app.getSelected().forEach(note =>  {
                const zoom = this.props.app.getDisplayZoom(note.mapObject.level);
                note.mapObject.position = note.mapObject.position.sum(move.divide(zoom));
                this.props.app.mapNodeService.requestUpdateMapNode(note);
            });
            this.props.app.onViewerStateChangeCall();
        }
        const dragEndEvent = event => {
            this.props.app.mapNodesMoving = false;
            this.props.app.io.onDragEnd.splice(this.props.app.io.onDragEnd.indexOf(dragEndEvent,1));
            this.props.app.io.onDrag.splice(this.props.app.io.onDrag.indexOf(dragMoveEvent,1));
        }

        this.props.app.io.onDrag.push(dragMoveEvent);
        this.props.app.io.onDragEnd.push(dragEndEvent);
    }

    registerDragResize(e,button: number) {

        const dragEvent: any = (event,move) => {

            this.props.app.mapNodesMoving = true;

            this.props.app.getSelected().forEach(node =>  {
                const zoom = this.props.app.getDisplayZoom(node.mapObject.level);
                let sizeMod = move.divide(zoom);
                let posMod = sizeMod.multiply(0.5);

                switch(button) {
                    case 8:

                        break;
                    case 1:

                        sizeMod = sizeMod.multiply(-1);
                        break;
                    case 3:
                        sizeMod = sizeMod.multiplyVector(new Vector2(1,-1));
                        break;
                    case 6:
                        sizeMod = sizeMod.multiplyVector(new Vector2(-1,1));
                        break;
                }

                sizeMod = sizeMod.multiplyVector(node.getTypeObject().isResizable());
                posMod = posMod.multiplyVector(node.getTypeObject().isResizable());

                node.mapObject.size = node.mapObject.size.sum(sizeMod);
                const minSize = node.getTypeObject().getMinSize();

                if (node.mapObject.size.x < minSize.x) {
                    node.mapObject.size.x = minSize.x;
                    posMod.x = 0;
                }
                if (node.mapObject.size.y < minSize.y) {
                    node.mapObject.size.y = minSize.y;
                    posMod.y = 0;
                }
                node.mapObject.position = node.mapObject.position.sum(posMod);
                this.props.app.mapNodeService.requestUpdateMapNode(node);

            });
            this.props.app.onViewerStateChangeCall();
        };

        const dragEndEvent = event => {
            this.props.app.mapNodesMoving = false;
            this.props.app.io.onDrag.splice(this.props.app.io.onDrag.indexOf(dragEvent,1));
            this.props.app.io.onDragEnd.splice(this.props.app.io.onDragEnd.indexOf(dragEndEvent,1));
        }

        this.props.app.io.onDrag.push(dragEvent);
        this.props.app.io.onDragEnd.push(dragEndEvent);
    }



    render() {
        if (0 == this.props.app.getSelected().length) {
           return <div/>;
        }
        const area = this.props.app.getSelectedDisplayArea();

        const readOnly = this.props.app.getSelected().find(el => !this.props.app.userService.hasWriteRights(el));

        // const position = area.extendBorders(new Vector2(10,10));
        const position = area;

        const resizeButtons = [];
        if (!readOnly) {
            if (1 === this.props.app.getSelected().length) {
                for(let i=1;i<9;i++) {
                    const className = "selected-tools-resize-handle selected-tools-resize-handle-"+i;
                    resizeButtons.push(<span key={i} className={className}
                                             onMouseDown={e => this.registerDragResize(e,i)}
                                             onTouchStart={e => this.registerDragResize(e,i)}

                    />)
                }
            }
        }



        return <div className="selected-tools app-ui">
            <div className="selected-tools-handle"
                style={{
                    top: position.position.y,
                    left: position.position.x,
                }}
            >
                <div className="selected-tools-handle-area"
                     style={{
                         width: position.size.x,
                         height: position.size.y
                     }}
                >

                    {resizeButtons}
                </div>
                {!readOnly?<i
                    onMouseDown={e => this.registerDragMove(e)}
                    onTouchStart={e => this.registerDragMove(e)}

                    style={{
                        marginTop: position.size.y / 2 - 26
                    }}
                    className="selected-tools-move-handle fas fa-arrows-alt shadow-5-strong"/>:null}




            </div>
        </div>
    }
}
