import React from 'react';
import {AppController} from "../../../controllers/AppController";

import "./user-icon.css";

import {MapUserAccess} from "../../../node/MapUserAccess";
import md5 from "crypto-js/md5";

export interface UserIconComponentProps {
    app: AppController,
    userId: number,
    onButtonClick?: () => void
}

export interface UserIconComponentState {
    menuOpened: boolean,
    avatarImg: boolean
}

export class UserIconComponent extends React.Component<UserIconComponentProps, UserIconComponentState> {

    private static _avatarNoImg = {};

    private getAvatarUrl()
    {
        const email = this.getUserName();
        if (null == email || !/@/.test(email)) {
            return null;
        }

        let size = 40;
        let allowed_rating = "g";
        let force_default = 'n';

        return ("https://secure.gravatar.com/avatar/" + md5(email.toLowerCase().trim()) + "?size=" + size + "&default=404&rating=" + allowed_rating + (force_default === 'y' ? "&forcedefault=" + force_default : ''));
    }

    constructor(props) {
        super(props);
        this.state = {menuOpened: false,avatarImg: undefined === UserIconComponent._avatarNoImg[this.getUserName()]};

    }

    getUserName() {
        return this.props.app.mapService.getUserName(this.props.userId);
    }

    getInitials() {
        const userName = this.getUserName();
        if (null != userName) {
            return userName[0];
        } else {
            return "--";
        }
    }

    getColor() {
        return this.props.app.mapService.getUserColor(this.props.userId,false);
    }

    getTextColor() {
        return "white";
        // return this.props.app.mapService.getUserColor(this.props.userId,false);
    }

    onClick() {
        if (null == this.props.onButtonClick) {
            this.props.app.notify(this.getUserName(),"primary");
        }
    }

    private _onAvatarError() {
        this.setState({avatarImg: false});
        UserIconComponent._avatarNoImg[this.getAvatarUrl()] = true;
    }

    render() {
        return <span className="user-icon-component" title={this.getUserName()}>
            <button type="button" className="btn btn-dark btn-floating  ripple-surface"
                    title={this.getUserName()}
                    style={{"background": this.getColor(),"color": this.getTextColor()}}
                    onClick={e => this.onClick()}
                    aria-expanded="false">
                {this.state.avatarImg ? <img onError={() => this._onAvatarError()} src={this.getAvatarUrl()} /> : this.getInitials()}
            </button>
        </span>
    }
}
