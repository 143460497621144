import {MapNode} from "./MapNode";
import {MapObject} from "../map/MapObject";
import {Vector2} from "../map/Vector2";

import {Model} from "../model/Model";
import {Base64} from "js-base64";

import md5 from "crypto-js/md5";
import {MapNodeData} from "./MapNodeData";
import {DelayedOperation} from "../lib/DelayedOperation";
import {PendingOperations} from "../lib/PendingOperation";
import {ModelUtil} from "../model/ModelUtil";
import {MapNodeService} from "./MapNodeService";

/*
@todo data update hash
@todo separate update query
 */

export class MapNodeDataService {

    constructor(public pendingOperations: PendingOperations,private mapNodeService: MapNodeService) {

    }

    private parseDatas(datas,mapNode:MapNode) {
        datas.forEach(el => {
            el.mapNodeId = mapNode.id;
            const serialized = el.data;
            if (null != serialized) {
                try {
                    const parsed = Base64.decode(serialized);
                    if (null != parsed && parsed.length > 0) {
                        try {
                            if ("NO_ACCESS" != parsed) {
                                el.data = JSON.parse(parsed);
                            }
                        } catch (e) {
                            console.error(e, parsed);
                        }
                    }
                } catch (e) {
                    el.data = null;
                }
            }
        });
    }

    async fetchNodeData(mapNode: MapNode) {
        if (mapNode.password) {
            const passwordHash = mapNode._passwordUnlockHash;
            if (null == passwordHash) {
                throw new Error("no password provided");
            }
            const resp = await Model.rest("password_unlock/" + ModelUtil.entityIdToNumeric(mapNode.id), "POST", {
                "password": passwordHash
            });
            const respJson = await resp.json();
            if (null == respJson || "ok" !== respJson.status) {
                if (undefined != respJson.error) {

                    throw new Error(respJson.error);
                } else {

                    throw new Error("error unlocking data");
                }
            }
            const datas = respJson.mapNodeDatas.map(el => Object.assign(new MapNodeData(), el));
            console.log("datas1",datas);
            this.parseDatas(datas,mapNode);
            console.log("datas2",datas);
            mapNode.mapNodeDatas = datas;


            this.pendingOperations.set(mapNode, "passwordUnlock", false);
            return mapNode;

        } else {

            const resp =  await (await Model.rest("get_map_node_data", "POST", {
                "mapNodeId": ModelUtil.entityIdToNumeric(mapNode.id)
            })).json();

            const datas = resp.mapNodeDatas.map(el => Object.assign(new MapNodeData(), el));
            this.parseDatas(datas,mapNode);
            mapNode.mapNodeDatas = datas;
            if (undefined != resp.commentsCount) {
                mapNode.commentsCount = resp.commentsCount;
            }
            return mapNode;



//             const data = await Model.query(`
//       {
//   mapNode(id: "/map_nodes/${mapNode.id}") {
//     mapNodeDatas {
//         id,createdAt,updatedAt,data
//     }
//   }
// }`);
//             const datas = data.data.mapNode.mapNodeDatas.map(el => Object.assign(new MapNodeData(), el));
//             this.parseDatas(datas,mapNode);
//             mapNode.mapNodeDatas = datas;
//             return mapNode;
        }


    }


    private serializeData(data: string): string {
        return Base64.encode(JSON.stringify(data));
    }

    async insertMapNodeData(mapNode: MapNode, data: any = null): Promise<MapNodeData> {
        this.pendingOperations.set(mapNode, "insertMapNodeData", true);
        mapNode.onLocalUpdate();
        const result =  await (await Model.rest("insert_map_node_data", "POST", {
            "mapNodeId": ModelUtil.entityIdToNumeric(mapNode.id)
        })).json();


//         const query = `mutation {
//   createMapNodeData(input: {mapNode: "/map_nodes/${mapNode.id}",
//   data: "",
//   }) {
//     mapNodeData {
//       id,createdAt
//     }
//   }
// }`;
//
//         const result = await Model.mutate(query, {});
        const mapNodeData = new MapNodeData(result.mapNodeData.id, data);
        // const mapNodeData = new MapNodeData(result.data.createMapNodeData.mapNodeData.id, data);
        // mapNodeData.createdAt = result.data.createMapNodeData.mapNodeData.createdAt;
        mapNodeData.createdAt = result.mapNodeData.createdAt;
        mapNodeData.mapNodeId = mapNode.id;
        if (null != data) {
            await this.updateMapNodeData(mapNodeData, data); // needed for enc
        }
        this.pendingOperations.set(mapNode, "insertMapNodeData", false);
        return mapNodeData;
    }

    async updateMapNodeData(mapNodeData: MapNodeData, data: any) {




        mapNodeData.data = data;

        const mapNode = this.mapNodeService.getById(mapNodeData.mapNodeId);
        if (null != mapNode) {
            mapNode.onLocalUpdate();
            if (mapNode.password) {
                if (mapNode._passwordUnlocked && null != mapNode._passwordUnlockHash) {
                    const resp = await Model.rest("password_update_data/" + ModelUtil.entityIdToNumeric(mapNodeData.id), "POST", {
                        "password": mapNode._passwordUnlockHash,
                        "data": this.serializeData(data)
                    });
                    console.log("pass update resp",resp);
                } else {
                    console.error("map node data updaging but not unlocked");
                }
            } else {

                const result =  await (await Model.rest("update_map_node_data", "POST", {
                    "id": ModelUtil.entityIdToNumeric(mapNodeData.id),
                    "data": this.serializeData(data)
                })).json();
                if (null != result && null != result.mapNodeData) {
                    mapNodeData.updatedAt = result.mapNodeData.updatedAt;
                }


//                 const query = `mutation {
//   updateMapNodeData(input: {id: "${mapNodeData.id}",
//   data:"${this.serializeData(data)}",
//   }) {
//     mapNodeData {
//       id,updatedAt
//     }
//   }
// }`;
//
//                 const result = await Model.mutate(query, {});
//                 mapNodeData.updatedAt = result.data.updateMapNodeData.mapNodeData.updatedAt;
                return result;
            }
        } else {
            console.error("null map node",mapNodeData);
        }
        return null;
    }

    async deleteMapNodeData(mapNodeData: MapNodeData, mapNode: MapNode) {
//
//         const query = `mutation($id: ID!) {
//   deleteMapNodeData(input: {id: $id,
//   }) {
//     mapNodeData {
//       id
//     }
//   }
// }`;
        const index = mapNode.mapNodeDatas.indexOf(mapNodeData);
        // if (undefined !== this._updateMapNodeDataRequests[mapNodeData.id]) {
        //     this._updateMapNodeDataRequests[mapNodeData.id] = undefined;
        // }
        DelayedOperation.deletePending("updateMapNodeData" + mapNodeData.id);
        this.pendingOperations.remove(mapNodeData);
        mapNode.mapNodeDatas.splice(index, 1);
        mapNode.onLocalUpdate();
        return await (await Model.rest("delete_map_node_data", "POST", {
            "id": ModelUtil.entityIdToNumeric(mapNodeData.id),
        })).json();
        // return Model.mutate(query, {
        //     id: mapNodeData.id
        // });

    }


    // private _updateMapNodeDataRequests = {};


    requestUpdateMapNodeData(mapNodeData: MapNodeData, getData: () => any) {
        console.log("requestUpdateMapNodeData", mapNodeData.id);
        const mapNode = this.mapNodeService.getById(mapNodeData.mapNodeId);
        if (null != mapNode) {
            mapNode.onLocalUpdate();
        }
        this.pendingOperations.set(mapNodeData, "update", true);
        return DelayedOperation.run("updateMapNodeData" + mapNodeData.id, () => this.updateMapNodeData(mapNodeData, getData())).then(_ => {
            this.pendingOperations.set(mapNodeData, "update", false);
        });

        // if (undefined === this._updateMapNodeDataRequests[mapNodeData.id]) {
        //     this._updateMapNodeDataRequests[mapNodeData.id] = 0;
        // } else {
        //     this._updateMapNodeDataRequests[mapNodeData.id]++;
        // }
        // const req = this._updateMapNodeDataRequests[mapNodeData.id];
        // setTimeout(() => {
        //     if (this._updateMapNodeDataRequests[mapNodeData.id] === req) {
        //
        //         this.updateMapNodeData(mapNodeData, getData()).then(data => {
        //             console.log("data updated");
        //         });
        //     }
        // }, 2000);
    }

    async provideButton(mapNodeData: MapNodeData) {
        const id = ModelUtil.entityIdToNumeric(mapNodeData.id);
        const resp = await Model.rest("provide_plane_button/" + id);
        const respJson = await resp.json();
        if ("ok" === respJson['status']) {
            return respJson["data"];
        } else {
            return null;
        }

    }

    async getFiles(mapNodeData: MapNodeData) {
        const id = ModelUtil.entityIdToNumeric(mapNodeData.id);
        const resp = await Model.rest("get_plane_files/" + id);
        const respJson = await resp.json();
        if ("ok" === respJson['status']) {
            return respJson["files"];
        } else {
            return null;
        }

    }

    async onFilesChange(mapNodeData: MapNodeData,buttonId) {
        // console.log("ON FILES CHANGE REQUEST");
        const mapNode = this.mapNodeService.getById(mapNodeData.mapNodeId);
        if (null != mapNode) {
            mapNode.onLocalUpdate();
        }

        const id = ModelUtil.entityIdToNumeric(mapNodeData.id);
        const resp = await Model.rest("on_files_change","POST",{"key": mapNodeData.data,"buttonId": buttonId,"id": id});
        const respJson = await resp.json();
        if ("ok" === respJson['status']) {
            return respJson;
        } else {
            return null;
        }

    }

}
