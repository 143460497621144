export class Settings {

    private data = null;

    constructor(public name: string) {
    }

    public get(key,defaultValue = null): any {
        if (null === this.data) {
            this.data = {};
            if (null != localStorage.getItem("settings."+this.name)) {
                this.data = JSON.parse(localStorage.getItem("settings."+this.name));
            }
        }
        if (undefined === this.data[key] && null != defaultValue) {
            this.data[key] = defaultValue;
        }
        return this.data[key];
    }

    public set(key,value): void {
        if (null === this.data) {
            this.get(key);
        }
        this.data[key] = value;
        localStorage.setItem("settings."+this.name,JSON.stringify(this.data));
    }

    public getBool(key,defaultValue): boolean {
        if (true === defaultValue) {
            defaultValue = "1";
        }
        const val = this.get(key,defaultValue);
        return "1" === val || true === val;
    }

    public setBool(key,value): void {
        this.set(key,true == value ? "1" : "0");
    }
}
