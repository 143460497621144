import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./app-search.css";
import {MapNode} from "../../../node/MapNode";
import {MiniMapStaticComponent} from "../minimap/MiniMapStaticComponent";
import {Vector2} from "../../../map/Vector2";
import {MapNodeIconComponent} from "../MapNodeIconComponent";
import {DomUtil} from "../../../utils/DomUtil";
import {MapNodeFlag, MapNodeType, MapNodeTypeEnum} from "../../../node/MapNodeType";
import {ICONS} from "../../../lib/Icons";
import {DelayedOperation} from "../../../lib/DelayedOperation";
import {Emoji} from "../../../lib/Emoji";

export interface AppSearchComponentProps {
    app: AppController
}

export interface AppSearchComponentState {
    _search: string,
    search: string,
    opened: boolean,
    focused: boolean,
    hover: number
}

export class AppSearchComponent extends React.Component<AppSearchComponentProps, AppSearchComponentState> {


    private _inputRef = React.createRef<HTMLInputElement>();
    miniMapSize = new Vector2(100,100);
    private _mouseUpEvent;
    private _onKeyUp;


    constructor(props) {
        super(props);

        this.state = {_search: "", search: "",opened: false,focused: false,hover: -1};

    }


    componentDidMount() {
        this._mouseUpEvent = event => {
            if (!DomUtil.getOfClassOrParent(event.target,"app-search")) {
                this.setState({opened:false});
            }
        };
        this.props.app.io.onMouseUp.push(this._mouseUpEvent);
        this._onKeyUp = e => this.handleKeyUp(e);
        this.props.app.io.onKeyUp.push(this._onKeyUp);


    }

    componentWillUnmount() {
        this.props.app.io.onMouseUp.splice(this.props.app.io.onMouseUp.indexOf(this._mouseUpEvent),1);
        this.props.app.io.onKeyUp.splice(this.props.app.io.onKeyUp.indexOf(this._onKeyUp),1);

    }

    setSearch(search: string) {

        this.setState({_search: search,opened: search.length > 0,hover: 0});
        DelayedOperation.run("app.search.update",() => {
            this.setState({search: this.state._search});
        },500);

    }

    private _lastShift = null;

    private navigateHover() {
        // setTimeout(() => {
            if (this.state.hover >= 0) {
                const el = document.querySelector(".app-search-results-item-"+this.state.hover);
                if (null != el) {
                    el.scrollIntoView({block: "nearest", behavior: "smooth"});
                }
            }
        // },50);

    }

    private handleKeyUp(e) {
        if (null != this.props.app.mapAction) {
            return;
        }
        if ("Escape" === e.key && this.state.focused) {
            this._inputRef.current.blur();
            this.setState({focused: false,opened: false});
        }

        if (e.target === this._inputRef.current && (this.state.opened || this.state.focused)) {
            if (this.state.hover >= 0 && "Enter" == e.key) {
                const object = this.getObjects()[this.state.hover];
                if (undefined !== object) {
                    this.onSearchClick(object);
                    this.setState({focused: false,opened: false});
                    this._inputRef.current.blur();
                    return;
                }
            }
            if ("ArrowDown" === e.key) {
                this.setState({hover: Math.min(this.state.hover + 1,this.getObjects().length - 1)},() => this.navigateHover());
                return;
            } else if ("ArrowUp" === e.key) {
                this.setState({hover: Math.max(this.state.hover - 1,0)},() => this.navigateHover());
                return;
            }

        } else {
            switch(e.key) {
                case "Shift":
                    if (null != this._lastShift && new Date().getTime() - this._lastShift < 300) {
                        this._inputRef.current.focus();
                        this.setState({opened: true,focused: true,hover: 0});
                        return;
                    }
                    this._lastShift = new Date().getTime();
                    break;
                default:
                    this._lastShift = null;
            }
        }


        if (e.target.tagName.toLowerCase() == "input" || e.target.tagName.toLowerCase() == "textarea") {
            return;
        }
        if (e.ctrlKey || e.metaKey || e.altKey) {
            return;
        }






    }



    getObjects(): MapNode[] {
        // const context = this.state.search+":"+this.props.app.viewerState.toString();

        let all = this.props.app.getNodesVisible().sort((a: MapNode,b: MapNode) => {
            if (null != a.touchedTime && null != b.touchedTime) {
                return b.touchedTime - a.touchedTime;
            }
            if (null != a.touchedTime) {
                return -1;
            }
            if (null != b.touchedTime) {
                return 1;
            }
            const sa = a.getTypeObject().getSearchImportance(a);
            const sb = b.getTypeObject().getSearchImportance(b);
            if (sa > sb) {
                return -1;
            }
            if (sa < sb) {
                return 1;
            }
            return b.id - a.id;
        });
        if (this.state.search.length > 0) {
            all = all.filter(mapNode => mapNode.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1);
        }
        if (all.length > 15) {
            all = all.slice(0, 15);
        }

        return all;
        // if (this.state.search.length == 0) {
        //     return this.props.app.getNodesVisible().sort((a: MapNode,b: MapNode) => {
        //         if (null != a.touchedTime && null != b.touchedTime) {
        //             return b.touchedTime - a.touchedTime;
        //         }
        //         if (null != a.touchedTime) {
        //             return -1;
        //         }
        //         if (null != b.touchedTime) {
        //             return 1;
        //         }
        //         return b.id - a.id;
        //     });
        // }
        // const all = this.props.app.getNodesVisible().filter(mapNode => mapNode.title.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
        //     .sort((a,b) => {
        //         const sa = a.getTypeObject().getSearchImportance(a);
        //         const sb = b.getTypeObject().getSearchImportance(b);
        //         if (sa > sb) {
        //             return -1;
        //         }
        //         if (sa < sb) {
        //             return 1;
        //         }
        //         const distA = this.props.app.getObjectViewerDistance(this.props.app.viewerState.position,a);
        //         const distB = this.props.app.getObjectViewerDistance(this.props.app.viewerState.position,b);
        //
        //         if (distA > distB) {
        //             return 1;
        //         }
        //         if (distB > distA) {
        //             return -1;
        //         }
        //
        //         return 0;
        //     })
        // ;
        //
        // return all;
    }

    onSearchClick(mapNode: MapNode) {
        this.props.app.setFocus(mapNode,true);
        this.props.app.goto(mapNode.mapObject);
        this.setState({opened: false,search: "",_search: "",hover: 0});
    }

    getClosestFlagComponent(mapNode: MapNode) {
        if (MapNodeTypeEnum.FLAG !== mapNode.type) {
            const closestFlag = this.props.app.searchClosestObject(
                this.props.app.mapObjectToDisplayObject(mapNode.mapObject).position,mapNode => mapNode.type === MapNodeTypeEnum.FLAG)
            ;
            if (null != closestFlag) {
                const typeObject = closestFlag.getTypeObject();
                const info = typeObject.getInfo(closestFlag);
                const spec = typeObject.getMiniMapSpec(closestFlag);
                if (typeObject instanceof MapNodeFlag) {
                    return <div className="app-search-closest-flag">
                        {0 == spec.additional ?
                        <i style={{"color": spec.color}} className={info.icon} />
                            : <span>{Emoji.getMap()[spec.icon].e}</span>}
                        <span>{closestFlag.title}  </span>
                    </div>
                }

            }

        }
        return null;
    }

    render() {


        let found = null;
        if (this.state.opened || this.state.focused) {
            let objects = this.getObjects();

            if (0 == objects.length) {
                found = <ul className="list-group app-search-results app-shadow ">
                    <li className="list-group-item">None found</li>

                </ul>;
            } else {
                found = <ul className="list-group app-search-results app-shadow">
                    {objects.map((mapNode,i) => <li key={"search-"+mapNode.id}
                                                className={"list-group-item app-search-results-item app-search-results-item-"+i+" "+(i == this.state.hover ? "app-search-results-item-hover" : "")}
                        onClick={e => this.onSearchClick(mapNode)}
                                                    onMouseMoveCapture={e => this.setState({hover: i})}
                    >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2">
                                    <MapNodeIconComponent mapNode={mapNode} />
                                    {this.getClosestFlagComponent(mapNode)}
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-6">
                                    <div className="fs-3 text">{mapNode.title}</div>

                                </div>

                                <div className={"col-lg-2 col-md-2 col-sm-4 mini-map-in"+(this.props.app.isSelected(mapNode)?' selected':'')}>
                                    <MiniMapStaticComponent cacheEnabled={true} app={this.props.app} size={this.miniMapSize} highlight={mapNode}
                                        drawingActivePosition={true}
                                    />
                                </div>
                            </div>
                        </div>



                    </li>)}
                </ul>
            }
        }

        return <div className={"app-search "+(this.state.focused || this.state.opened ? "app-search-focused" : "")}>

        <div className="form-outline ">
            <span className="keyboard-shortcuts">
                {!this.state.focused?<kbd className="keyboard-shortcut">Double Shift</kbd>:<span>
                <kbd className="keyboard-shortcut"><i className="fas fa-arrow-up"></i></kbd>
                <kbd className="keyboard-shortcut"><i className="fas fa-arrow-down"></i></kbd>
                <kbd className="keyboard-shortcut">Enter</kbd>
            </span>}
            </span>

            <input type="text" id="app-search-input"
                   ref={this._inputRef}
                   className="form-control form-control-lg shadow-1-strong app-no-delete-object-on-key-down"
                   onFocus={e => this.setState({opened: true,focused: true})}
                   onBlur={e => this.setState({focused: false})}
                value={this.state._search} onChange={e => this.setSearch(e.target.value)}
                />
            <label className="form-label" htmlFor="form1">Go to..</label>
            <div className="form-notch">
                <div className="form-notch-leading"
                />
                <div className="form-notch-middle" />
                <div className="form-notch-trailing" />
            </div>
        </div>
            {found}
        </div>
    }
}
