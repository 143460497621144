import {Vector2} from "../map/Vector2";

export class MapArea {
    constructor(public position: Vector2, public size: Vector2) {

    }

    getStart(): Vector2 {
        return this.position.sum(this.size.multiply(-0.5));
    }

    getEnd(): Vector2 {
        return this.position.sum(this.size.multiply(0.5));
    }

    public static startEndToArea(start: Vector2, end: Vector2): MapArea {
        const size = end.minus(start);
        return new MapArea(start.sum(size.multiply(0.5)),size);
    }

    public sum(other: MapArea): MapArea {
        const start = this.getStart().lesserValues(other.getStart());
        const end = this.getEnd().greaterValues(other.getEnd());
        return MapArea.startEndToArea(start, end);
    }

    public static sumAll(areas: MapArea[]): MapArea {
        if (0 === areas.length) {
            return new MapArea(new Vector2(0,0),new Vector2(0,0));
        }
        let result = areas[0];
        for(let i = 1; i < areas.length; i++) {
            result = result.sum(areas[i]);
        }
        return result;
    }

    toString() {
        return `MA{${this.position},${this.size}}`;
    }

    // public extendBorders(size: Vector2) {
    //
    //     return new MapArea(this.position.sum(size.multiply(-1)),
    //         this.size.sum(size.multiply(2)));
    // }

    public center(): Vector2 {
        return this.position;
    }

    public isColliding(other: MapArea): boolean {
        return this.getStart().x <= other.getStart().x + other.size.x &&
            this.getStart().x + this.size.x >= other.getStart().x &&
            this.getStart().y <= other.getStart().y + other.size.y &&
            this.getStart().y + this.size.y >= other.getStart().y;

        // for(const corner of this.getCollisionCheckPoints()) {
        //     if (other.isInside(corner)) {
        //         return true;
        //     }
        // }
        // for(const corner of other.getCollisionCheckPoints()) {
        //     if (this.isInside(corner)) {
        //         return true;
        //     }
        // }
        // return false;
    }

    // public getCollisionCheckPoints() {
    //     const result = this.getCorners();
    //     result.push(this.center());
    //     result.push(this.getStart().sum(this.size.multiplyVector(new Vector2(0.5, 0))));
    //     result.push(this.getStart().sum(this.size.multiplyVector(new Vector2(0, 0.5))));
    //     result.push(this.getStart().sum(this.size.multiplyVector(new Vector2(0.5, 1))));
    //     result.push(this.getStart().sum(this.size.multiplyVector(new Vector2(1, 0.5))));
    //     return result;
    // }

    // public getCorners(): Vector2[] {
    //     return [this.getStart(),
    //         this.getStart().sum(this.size.multiplyVector(new Vector2(1, 0))),
    //         this.getEnd(),
    //         this.getStart().sum(this.size.multiplyVector(new Vector2(0, 1))),
    //     ]
    // }

    public isInside(point: Vector2): boolean {
        return point.isGreaterEqualThan(this.getStart()) && point.isLesserEqualThan(this.getEnd());
    }

}