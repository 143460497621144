import {MapNodeData} from "../../../node/MapNodeData";



export class LogElement {
    public id: string;
    public content: string = "test";
    public date: any;

    public static fromData(data: MapNodeData): LogElement {
        const result = new LogElement();
        result.id = data.id;
        result.content = data.data["content"];
        result.date = data.createdAt;
        return result;
    }

    public toData(): any {
        return {
            content: this.content,
        }
    }
}


