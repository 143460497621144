import React, {useState} from 'react';


import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";

import './todo.css';
import {TodoElement, TodoImportance, TodoStatus} from "./TodoElement";
import {TodoRowComponent} from "./TodoRowComponent";
import {MapNodeToolsButton, MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";

import {from} from "@apollo/client";
import {TodoSortOperation} from "./TodoSortOperation";

// window["SortableJS"] = Sortable;

export interface TodoComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,

}


export interface TodoComponentState {
    todos: TodoElement[]
    hideCompleted: number,
    sortingTodo: TodoElement
    // textMode: boolean,
}


export class TodoComponent extends React.Component<TodoComponentProps, TodoComponentState> {


    public _listRef = React.createRef<HTMLDivElement>();

    private _onFullScreenChange = null;

    private static _onTouchStart;

    constructor(props, state) {
        super(props, state);
        const options = this.props.mapNode.getOptionsObject();
        let hideCompleted = parseInt(options.get(1));
        if (isNaN(hideCompleted)) {
            hideCompleted = 0;
        }
        this.state = {
            todos: null,
            hideCompleted: hideCompleted,
            sortingTodo: null
            // textMode: false
        }


    }



    componentWillUnmount() {
        this.props.mapNode.events.onFullScreenChange.splice(this.props.mapNode.events.onFullScreenChange.indexOf(this._onFullScreenChange), 1);

    }

    componentDidMount() {

        (async () => {

            if (null === this.props.mapNode.mapNodeDatas) {
                await this.props.app.mapNodeDataService.fetchNodeData(this.props.mapNode);

            }
            let todos = [];
            if (null != this.props.mapNode.mapNodeDatas) {

                todos = this.props.mapNode.mapNodeDatas.map(row => TodoElement.fromData(row));

            }
            if (0 === todos.length) {

                console.log("default todos ",this.props.mapNode._customFields);
                if (undefined != this.props.mapNode._customFields["defaultTodos"]
                    && this.props.mapNode._customFields["defaultTodos"].length > 0) {
                    console.log("init add new custom",this.props.mapNode._customFields["defaultTodos"]);
                    this.props.mapNode._customFields["defaultTodos"].forEach(todo => this.addNew(todo));
                } else {
                    console.log("init add new 2");
                    this.addNew();
                }

            }
            this.setState({todos: todos});

            // this.initSortable();
        })();

        this._onFullScreenChange = fullscreen => {
            setTimeout(() => {
                this.forceUpdate()
            }, 1);
        }
        this.props.mapNode.events.onFullScreenChange.push(this._onFullScreenChange);

    }

    // initSortable() {
    //     if (!this.props.app.userService.hasWriteRights(this.props.mapNode)) {
    //         return;
    //     }
    //
    //     if (null == this._listRef.current) {
    //         setTimeout(() => this.initSortable(), 100);
    //         return;
    //     }
    //     if (null == sessionStorage.getItem("disable-sortable")) {
    //         setTimeout(() => {
    //             console.log("init sortable",this._listRef.current);
    //             Sortable.create(this._listRef.current, {
    //                 direction: "vertical",
    //                 handle: ".todo-row-element-handle",
    //                 dragClass: "todo-row-element-dragging",
    //                 chosenClass: "todo-row-element-dragging-chosen",
    //                 onEnd: e => {
    //                     this.savePositions();
    //                 }
    //
    //             });
    //         },500);
    //     }
    //
    //
    //     setTimeout(() => this.saveInitCheck(), 500);
    // }

    private _saveInitChecked = false;

    saveInitCheck() {
        if (!this.props.app.userService.hasWriteRights(this.props.mapNode) || this._saveInitChecked) {
            return;
        }
        this._saveInitChecked = true;
        const todos = this.getVisibleTodos();
        if (todos.length > 1) {
            const nullCount = todos.filter(el => null == el.position);
            if (nullCount.length === todos.length) {
                console.log("save positions migration");
                this.savePositions();
            }

        }

    }

    savePositions() {
        if (!this.props.app.userService.hasWriteRights(this.props.mapNode)) {
            return;
        }
        const positionLocalIds = Array.from(this._listRef.current.querySelectorAll(".todo-row"))
            .map(el => el.getAttribute("id"));

        const positionedTodos = positionLocalIds.map(localId => this.state.todos.find(todo => localId == todo.getLocalId()));

        console.log("save positions", positionedTodos);
        if (undefined !== positionedTodos.find(todo => null == todo.id)) {
            console.log("save positions delay");
            setTimeout(() => this.savePositions(), 500);
        } else {
            let pos = 0;
            for (const todo of positionedTodos) {
                if (todo.position != pos) {
                    todo.position = pos;
                    this.saveTodo(todo);
                }
                pos++;
            }

        }

    }

    saveTodo(todo: TodoElement) {
        const mapNodeData = this.props.mapNode.mapNodeDatas.find(data => todo.id === data.id);
        this.props.app.mapNodeDataService.requestUpdateMapNodeData(mapNodeData, () => todo.toData());
    }

    componentDidUpdate() {

    }

    async addNew(todo: TodoElement = null) {
        if (null == todo) {
            todo = new TodoElement();
            todo.content = "";
            const last = this.getSortedTodos()[this.getSortedTodos().length - 1];
            if (undefined != last) {
                todo.position = last.position + 1;
            } else {
                todo.position = 0;
            }
        }
        let todos = this.state.todos;
        if (null == todos) {
            todos = [];
        }

        todos.push(todo);
        this.setState({todos: todos});

        this.props.app.mapNodeDataService.insertMapNodeData(this.props.mapNode, todo.toData()).then(mapNodeData => {

            this.props.mapNode.mapNodeDatas.push(mapNodeData);
            todo.id = mapNodeData.id;
            this.setState({todos: todos});

        });
        return todo;
        // this.state.todos.push(TodoElement.fromData(mapNodeData));
    }

    recalculateImportance() {

        const importance = Math.max.apply(null, this.state.todos.filter(el => el.status === TodoStatus.DEFAULT && 3 !== el.importance).map(el => el.importance));
        const options = this.props.mapNode.getOptionsObject();
        const currentImportance = options.get(0);

        if (currentImportance != importance) {
            options.set(0, importance);
            this.props.mapNode.setOptionsObject(options);
            this.props.app.mapNodeService.requestUpdateMapNode(this.props.mapNode);
            this.props.app.onTodoImportanceChange.forEach(callback => callback());
        }
    }

    isLast(todo: TodoElement) {
        const todos = this.getVisibleTodos();
        return todo === todos[todos.length - 1];
    }

    getEl(todo: TodoElement) {
        return document.getElementById(todo.getLocalId());
    }

    focus(todo: TodoElement) {
        if (null == todo) {
            return;
        }
        const el = this.getEl(todo);
        if (null != el) {
            el.querySelector(".todo-row-input")["focus"]();
        }
    }

    focusLast() {
        const todos = this.getVisibleTodos()
        this.focus(todos[todos.length - 1]);
    }

    focusFirst() {
        const todos = this.getVisibleTodos()
        this.focus(todos[0]);
    }

    focusPrevious(todo: TodoElement) {
        const todos = this.getVisibleTodos();
        const previous = todos[todos.indexOf(todo) - 1];
        if (undefined !== previous) {
            this.focus(todos[todos.indexOf(todo) - 1]);
        } else {
            // this.focusLast();
        }
    }

    focusNext(todo: TodoElement) {
        const todos = this.getVisibleTodos();
        this.focus(todos[todos.indexOf(todo) + 1]);
    }

    onEnter(todo: TodoElement,event: any) {
        const newTodo = new TodoElement();
        if (0 === event.target.selectionStart
            && 0 === event.target.selectionEnd
        && event.target.value.length > 0) {

            newTodo.position = todo.position;
        } else {
            newTodo.position = todo.position + 1;

        }

        const toMove = this.state.todos.filter(el => el.position >= newTodo.position);
        toMove.forEach(el => el.position = el.position + 1);
        this.addNew(newTodo).then(() => {
            this.focus(newTodo);
            toMove.forEach(el => {

                this.saveTodo(el);
            });

        });

        // if (this.isLast(todo)) {
        //     this.addNew().then(() => {
        //         this.focusLast();
        //     })
        //
        // } else {
        //     this.focusNext(todo);
        // }

    }

    onPasteRows(todo: TodoElement, rows: string[]) {
        rows = rows.filter(row => row.trim().length > 0);


        let pos = todo.position + 1;

        const toMove = this.state.todos.filter(el => el.position >= pos);
        toMove.forEach(el => el.position = el.position + rows.length);

        rows.forEach(row => {
            const newTodo = new TodoElement();
            newTodo.content = row
                // .replace(/^-/,"")
                .trim();
            // if (0 < newTodo.content.length) {
            newTodo.setContentFromText(row);
            newTodo.position = pos++;
            this.addNew(newTodo);
            // }
        });
        setTimeout(() => {
            toMove.forEach(el => {
                this.saveTodo(el);
            });
        }, 100);
    }

    onRemove(todo: TodoElement) {
        const index = this.state.todos.indexOf(todo);
        console.log("remove 2", todo.id, todo.content, index);
        this.focusPrevious(todo);
        this.state.todos.splice(index, 1);
        this.setState({todos: this.state.todos});
        // if (0 === this.state.todos.length) {
        this.allHiddenAddNewCheck(true);
        // this.addNew();
        // }
        if (todo.importance > 0) {
            this.recalculateImportance();
        }
        // const todos = this.props.mapNode.mapNodeDatas.map(row => TodoElement.fromData(row));
        // this.setState({todos: todos});

    }

    onStatusChange(todo: TodoElement) {

        if (todo.importance > 0) {
            this.recalculateImportance();
        }
        this.allHiddenAddNewCheck();
        this.setState({todos: this.state.todos});
        // const todos = this.props.mapNode.mapNodeDatas.map(row => TodoElement.fromData(row));
        // this.setState({todos: todos});

    }


    updateHideOptions() {
        if (!this.props.app.userService.hasWriteRights(this.props.mapNode)) {
            return;
        }

        const options = this.props.mapNode.getOptionsObject();


        // if (currentHidePolicy != this.state.hideCompleted) {
        options.set(1, this.state.hideCompleted);

        this.props.mapNode.setOptionsObject(options);
        this.props.app.mapNodeService.requestUpdateMapNode(this.props.mapNode);
        // }
    }


    allHiddenAddNewCheck(force = false) {
        if (0 == this.state.todos.filter(todo => TodoStatus.DEFAULT == todo.status).length
            && (1 == this.state.hideCompleted || force)) {

            this.addNew(new TodoElement());
            setTimeout(() => {
                this.focusFirst();
                this.forceUpdate();
            },1);
        }
    }

    toggleHideCompleted() {

        const hideCompleted = (this.state.hideCompleted + 1) % 2
        if (1 == hideCompleted) {// && 0 == this.state.todos.filter(todo => TodoStatus.DEFAULT == todo.status).length) {
            this.allHiddenAddNewCheck(true);
        }
        this.setState({hideCompleted: hideCompleted}, () => {
            this.updateHideOptions();
        });

    }

    // toggleTextMode() {
    //     const textMode = !this.state.textMode;
    //     this.setState({textMode: textMode});
    // }

    copyToClipboard() {

        const todosText = this.getVisibleTodos().map(todo => todo.getAsText()).join("\n");
        const textarea = document.createElement("textarea");
        textarea.value = todosText;
        textarea.style.opacity = "0";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setTimeout(() => textarea.remove(), 1000);
        // textarea.remove();
        this.props.app.notify("Copied", "dark");

    }

    hasAnyCompleted() {
        return null != this.state.todos && this.state.todos.find(todo => TodoStatus.COMPLETED == todo.status);
    }

    getSortedTodos() {
        if (null == this.state.todos) {
            return [];
        }
        return this.state.todos.sort((a, b) => {
            if (a.position > b.position) {
                return 1;
            } else if (a.position < b.position) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    getVisibleTodos() {
        let hideCompleted = this.state.hideCompleted;

        if (!this.hasAnyCompleted()) {
            hideCompleted = 0;
        }

        let todos = this.getSortedTodos();
        if (1 == hideCompleted) {
            todos = this.state.todos.filter(todo => TodoStatus.DEFAULT == todo.status);
        }
        return todos;
    }



    onSortHandleMouseDown(event,todo: TodoElement) {
        console.log("on sort start",event,todo);

        this.setState({sortingTodo: todo},() => {
            new TodoSortOperation(this,todo).run(event);
        });
    }

    render() {

        const toolButtons: MapNodeToolsButton[] = [];

        // let hideCompleted = this.state.hideCompleted;


        let result = <i className={"fa fa-spin fa-spinner"}/>
        if (null !== this.state.todos) {

            if (this.hasAnyCompleted()) {
                toolButtons.push({
                    color: "#ccc",
                    title: !this.state.hideCompleted ? "Hide completed" : "Show completed",
                    onClick: e => this.toggleHideCompleted(),
                    icon: "fas " + (this.state.hideCompleted ? "fa-eye-slash" : "fa-eye")
                });
            }

            const todos = this.getVisibleTodos();

            if (todos.length > 0) {
                toolButtons.push({
                    color: "#ccc",
                    title: "Copy to clipboard",
                    onClick: e => this.copyToClipboard(),
                    // onClick: e => this.toggleTextMode(),
                    icon: "fas fa-paste"
                });
            }

            let contents = null;
            // if (this.state.textMode) {
            //     const todosText = todos.map(todo => (TodoStatus.COMPLETED == todo.status?"[X] ":"")+todo.content).join("\n");
            //     contents = <textarea onClick={e => e.target["select"]()} readOnly={true} className="todo-component-text-mode app-ui" value={todosText} style={{height: todos.length * 27}} />
            // } else {

            contents = todos.map((todo: TodoElement, i) => <div className="todo-row-container"
                                                                key={todo.getLocalId()}

            >

                <TodoRowComponent key={todo.getLocalId()}
                                  app={this.props.app}
                                  mapNode={this.props.mapNode}
                                  onEnter={(todo,e) => this.onEnter(todo,e)}
                                  onRemove={e => this.onRemove(e)}
                                  onArrowUp={e => this.focusPrevious(e)}
                                  onArrowDown={e => this.focusNext(e)}
                                  onImportanceChange={e => this.recalculateImportance()}
                                  onStatusChange={e => this.onStatusChange(e)}
                                  onPasteRows={(todo, rows) => this.onPasteRows(todo, rows)}
                                  onSortHandleMouseDown={((event, todo1) => this.onSortHandleMouseDown(event,todo1))}
                                  todo={todo}/></div>);
            // }

            result = <div className="todos-container">
                <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode} buttons={toolButtons}/>
                <div className="todos-container-contents"
                     ref={this._listRef}
                >
                    {contents}
                </div>
            </div>


        }
        return result;
    }
}
