import {DaemonUtils} from "../utils/DaemonUtils";

export class PendingOperation {
    constructor(public context: any,public type: string) {

    }
}

export class PendingOperations {

    public _pendingOperations: PendingOperation[] = [];

    public onChange: ((newOperations: PendingOperation[]) => void)[] = [];


    public waitForFree(context=null) {
        return DaemonUtils.waitFor(() => {
            if (null != context) {
                return null == this.get(context)
            } else {
                return 0 == this._pendingOperations.length;
            }
        });
    }

    public get(context: any,type: string = null): PendingOperation {
        return this._pendingOperations.find(el => el.context === context && (null == type || type === el.type));
    }

    public set(context: any,type: string,pending: boolean): PendingOperation {
        let operation = this._pendingOperations.find(el => el.context === context && el.type === type);
        if (pending) {
            if (null == operation) {
                operation = new PendingOperation(context,type);
                this._pendingOperations.push(operation);
            }
        } else {
            if (null != operation) {
                this._pendingOperations.splice(this._pendingOperations.indexOf(operation),1);
                operation = null;
            }
        }
        this.onChange.forEach(op => op(this._pendingOperations));
        return operation;
    }

    public remove(context: any,type: string = null) {
        const pending = this._pendingOperations.find(el => el.context === context && (null == type || type === el.type));
        if (null != pending) {
            this._pendingOperations.splice(this._pendingOperations.indexOf(pending),1);
            this.onChange.forEach(op => op(this._pendingOperations));
        }
    }


}