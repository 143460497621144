import React, {useState} from 'react';
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {AppController} from "../../../controllers/AppController";
import {NotificationsComponent} from "../notifications/NotificationsComponent";


export interface PasswordLockComponentProps {

    app: AppController,
    onClose: () => void

}

export interface PasswordLockComponentState {
    confirm: boolean
    // selectedIcon: number
}

export class PasswordLockComponent extends React.Component<PasswordLockComponentProps, PasswordLockComponentState> {

    private _password = React.createRef<HTMLInputElement>();
    private _passwordRepeat = React.createRef<HTMLInputElement>();


    private _passwordString = null;


    constructor(props, state) {
        super(props, state);
        this.state = {
            confirm: false
            // selectedIcon: props.selectedIcon
        }


    }

    onSubmit(e) {
        e.preventDefault();



        // if (!window.confirm('The selected data will be ENCRYPTED with this password, there is NO RECOVERY if you forget it. Do you wan\'t to continue?')) {
        //     return;
        // }
        (async () => {
            for (const el of this.props.app.getSelected()) {
                if (el.isPasswordLockAvailable()) {
                    await this.props.app.pendingOperations.waitForFree();
                    el.password = true;
                    console.log(this._passwordString);
                    this.props.app.mapNodeService.passwordLock(el,this._passwordString).then(() => {
                        this.props.app.onViewerStateChangeCall();
                    });
                }
            }
            this.props.app.onViewerStateChangeCall();
            this.props.onClose();
        })();

    }

    private submit(e) {
        if (this.state.confirm) {
            return this.onSubmit(e);
        }
        e.preventDefault();

        const password = this._password.current.value;

        if (this._passwordRepeat.current.value !== password) {
            this.props.app.notify("Passwords do not match","danger");
            return;
        }
        if (!password) {
            return;
        }
        this._passwordString = password;

        if (!this.state.confirm) {
            this.setState({"confirm": true})
        } else {
            this.onSubmit(e);
        }
    }

    render() {
        return <div className="password-lock-component">
            <ModalComponent title={"Encrypt selected objects"} onClose={() => this.props.onClose()}>
                <form autoComplete="off" onSubmit={e => this.submit(e)}>
                    {!this.state.confirm?<div className="input-group mb-3">
                        <input  type="text"
                                ref={this._password} className="form-control password-font" placeholder="Enter password"
                               required={true}
                               aria-describedby="button-addon2"/>
                        <input type="text"
                               ref={this._passwordRepeat} className="form-control password-font" placeholder="Repeat password"
                               required={true}
                               aria-describedby="button-addon2"/>
                        <button className="btn btn-outline-danger" type="submit" id="button-addon2"
                                data-mdb-ripple-color="dark">
                            Encrypt
                        </button>

                    </div>:<div>
                        <div className="alert alert-danger">
                            The selected objects ({this.props.app.selected.length}) will be ENCRYPTED with this password, there is NO RECOVERY if you forget it. Do you want to continue?
                        </div>

                        <button type="submit"
                                className="btn btn-outline-danger">
                            Encrypt
                        </button>
                    </div>}
                </form>
            </ModalComponent>

        </div>;
    }
}
