import React, {useState} from 'react';

import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import {MapNodeData} from "../../../node/MapNodeData";
import {PlaneUploadComponent} from "../../widgets/PlaneUploadComponent";

import './files.css';
import {MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";



export interface FilesComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,

}
export interface FilesComponentState {
    planeKey: string,
    uploading: boolean,
    readOnlyFiles: any[],
    forceReload: boolean
}


export class FilesComponent extends React.Component<FilesComponentProps,FilesComponentState> {

    constructor(props,state) {
        super(props,state);
        this.state = {
            planeKey: null,
            uploading: false,
            readOnlyFiles: undefined,
            forceReload: false
        }

    }


    componentDidMount() {

        const readOnly = !this.props.app.userService.hasWriteRights(this.props.mapNode);


        (async () => {

            // const data = await this.props.app.noteService.fetchNodeData(this.props.mapNode);
            let data = null;
            if (null === this.props.mapNode.mapNodeDatas) {
                await this.props.app.mapNodeDataService.fetchNodeData(this.props.mapNode);
                if (undefined != this.props.mapNode.mapNodeDatas[0]) {
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
            } else {
                if (undefined != this.props.mapNode.mapNodeDatas[0]) {
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
            }

            if (!readOnly) {
                if (null != data && undefined != data.planeKey) {

                    // this.setState({planeKey: data.planeKey});

                } else {
                    this.props.mapNode.mapNodeDatas = [await this.props.app.mapNodeDataService.insertMapNodeData(this.props.mapNode,{planeKey: "generate"})];
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
                if ("generate" === data.planeKey) {
                    console.log("Generating");
                    data = await this.props.app.mapNodeDataService.provideButton(this.props.mapNode.mapNodeDatas[0]);
                    this.props.mapNode.mapNodeDatas[0].data = data;
                }

                if (null != data && undefined != data.planeKey) {

                    this.setState({planeKey: data.planeKey});

                }
            } else {
                console.log("readonly files fetch",data,this.props.mapNode.mapNodeDatas[0]);
                const files = await this.props.app.mapNodeDataService.getFiles(this.props.mapNode.mapNodeDatas[0]);
                this.setState({
                    readOnlyFiles: files
                })
                console.log("files",files);
            }




        })();
    }

    componentDidUpdate() {

    }

    private _lastChangeRequest = null;
    private _onFilesChangeRequest = 0;
    private _filesLoaded = false;

    private _onFilesChange(e) {
        if (0 == this._onFilesChangeRequest) {
            this._onFilesChangeRequest++;
            setTimeout(() => this._filesLoaded = true,1000);
        }
        if (!this._filesLoaded) {
            return;
        }
        const data = JSON.stringify(e.files);
        if (data !== this._lastChangeRequest) {
            this._lastChangeRequest = data;

            (async () => {
                const changed = await this.props.app.mapNodeDataService.onFilesChange(this.props.mapNode.mapNodeDatas[0],e.id);
                if (null != changed) {
                    if (changed.map != this.props.app.currentMap.usage) {
                        this.props.app.currentMap.usage = changed.map;
                        if (this.props.app.currentMap.usage > this.props.app.currentMap.usageLimit) {
                            this.setState({forceReload: true});
                            setTimeout(() => {
                                this.setState({forceReload: false});

                            },100);

                        }
                    }
                }

            })();
        } else {
            return;
        }

    }

    onFilesChange(e) {

        const uploading = null != e.files.find(file => -1 !== [2,3].indexOf(file.status));
        if (!uploading) {
            this._onFilesChange(e);
        }
        if (uploading !== this.state.uploading) {
            this.setState({uploading: uploading});
            this.props.app.pendingOperations.set(this.props.mapNode.mapObject,"uploading",uploading);
            console.log("UPLOADING",uploading);

        }

    }

    public getMapLimit() {
        return this.props.app.currentMap.usageLimit;
    }

    isOverLimit() {
        if (null != this.props.app.currentMap) {
            // console.log("USAGE",this.props.app.currentMap.usage);
            return this.props.app.currentMap.usage > this.getMapLimit();
        }
        return false;
    }

    getOptions() {
        if (this.isOverLimit()) {
            return {
                permissionsLock: {
                    allowFilesUpload: true
                }
            }
        } else {
            return {};
        }
    }

    render() {

        if (1 == this.props.app.userService.getLoggedLoaded().status) {
            return <div className="alert alert-danger">You have to confirm your email address to use this object.</div>
        }

        let editor = <LoadingComponent/>;
        if (!this.state.uploading && this.state.forceReload) {
            return editor;
        }

        const readOnly = !this.props.app.userService.hasWriteRights(this.props.mapNode);
        if (readOnly && undefined !== this.state.readOnlyFiles) {
            editor = <ul className="files-component-readonly list-group app-ui">
                {this.state.readOnlyFiles.map((file,i) => <li key={i} className="list-group-item ">
                    <div className="d-flex">
                        <div className="p-2 ">
                            <img src={file.thumb} />
                        </div>
                        <div className="p-2 flex-grow-1">
                            {file.fileName}
                        </div>
                        <div className="p-2">
                            <a href={file.downloadUrl} target="_blank" className="btn btn-primary btn-floating ripple-surface">
                                <i className={"fas fa-download"} />
                            </a>
                        </div>
                    </div>

                </li>)}
            </ul>
        } else if (-1 === [null,"generate","generating"].indexOf(this.state.planeKey)) {
                editor = <div className={"app-ui "+(this.state.uploading?"app-files-uploading":"")}>
                    {this.isOverLimit() ? <div className={"alert alert-danger"}>This map reached the storage limit {this.getMapLimit()}MB, please contact <a href="mailto:contact@dragonote.com">contact@dragonote.com to increase the limit</a></div> : null}
                    <PlaneUploadComponent options={this.getOptions()} planeKey={this.state.planeKey} onChange={e => this.onFilesChange(e)} />
                </div>
        }
        return <div className="files-component">
            <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode}/>
            {editor}
        </div>;
    }
}
