import React, {useState} from 'react';

import './flag.css';


import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import {SelectIconComponent} from "../../widgets/select-icon/SelectIconComponent";
import {ICONS} from "../../../lib/Icons";
import {MapNodeFlag} from "../../../node/MapNodeType";
import {MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {FlagModalComponent} from "./FlagModalComponent";
import {FlagEditComponent} from "./FlagEditComponent";
import {Emoji} from "../../../lib/Emoji";


export interface FlagComponentProps {

    app: AppController,
    mapNode: MapNode,

}
export interface FlagComponentState {

    selectedColor: string,
    selectedType: number,
    iconIndex: number,

}


export class FlagComponent extends React.Component<FlagComponentProps,FlagComponentState> {


    constructor(props,state) {
        super(props,state);




    }

    private loadIcon() {
        const selectedColor = this.props.mapNode.getOptionsObject().get(1,false);
        let selectedIcon = this.props.mapNode.getOptionsObject().get(0,true);
        const selectedType = this.props.mapNode.getOptionsObject().get(2,true);

        this.setState({
            selectedType: (null !== selectedType && !isNaN(selectedType))?selectedType:0,
            selectedColor: (selectedColor?selectedColor:MapNodeFlag.DEFAULT_COLOR),
            iconIndex: (null !== selectedIcon && !isNaN(selectedIcon))?selectedIcon:MapNodeFlag.DEFAULT_ICON
        });
    }


    componentDidMount() {
        this.loadIcon();
    }

    componentDidUpdate() {

    }



    getIcon() {
        return ICONS[this.state.iconIndex];
    }

    getEmoji() {
        if (undefined !== Emoji.getMap()[this.state.iconIndex]) {
            return Emoji.getMap()[this.state.iconIndex].e;
        } else {
            return "-";
        }
    }

    private openOptions() {
        FlagModalComponent.open(this.props.mapNode,() => this.loadIcon());
    }

    render() {

        if (null == this.state) {
            return null;
        }

        let options = null;
        if (this.props.app.focus === this.props.mapNode && this.props.app.userService.hasWriteRights(this.props.mapNode)) {
            options = <div className="map-node-flag-options">
                {/*<i className={"fas fa-palette"} />*/}
                <button onClick={e => this.openOptions()} className="map-node-flag-open-options btn btn-light btn-floating app-ui">
                    <i className={"fas fa-pencil-alt"} />
                </button>



            </div>
        }
        return <div className={"map-node-flag-component map-node-flag-type-"+this.state.selectedType}>
            <div className="map-node-flag-contents">


                    {options}
                {0 == this.state.selectedType ?  <i style={{color: this.state.selectedColor}} className={"map-node-flag-icon "+ this.getIcon()}/>
                    : <span className={"map-node-flag-icon map-node-flag-emoji"}
                        title={""+this.state.iconIndex}
                    >
                        {this.getEmoji()}
                    </span>}

                <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode}/>

                {this.props.app.isFocusFullScreenEdit() ? <FlagEditComponent app={this.props.app}
                                                                             mapNode={this.props.mapNode}
                                                                             callback={e => this.loadIcon()} /> : null}




            </div></div>
    }
}
