import {MapNode} from "./MapNode";
import {MapObject} from "../map/MapObject";
import {Vector2} from "../map/Vector2";

import {Model} from "../model/Model";
import {Base64} from "js-base64";

import sha256 from "crypto-js/sha256";
import {MapNodeType} from "./MapNodeType";
import {PendingOperations} from "../lib/PendingOperation";
import {DelayedOperation} from "../lib/DelayedOperation";
import {ModelUtil} from "../model/ModelUtil";
import {DaemonUtils} from "../utils/DaemonUtils";
import {MapScope} from "./MapScope";

/*
@todo data update hash
@todo separate update query
 */

export class MapNodeService {


    private _loaded = false;
    private _mapNodes: MapNode[] = null;
    private _wormholes: MapNode[] = null; // other world

    public onLoadMapNodes: (() => void) [] = [];

    private _lastId: number = 0;

    constructor(public mapScope: MapScope, public pendingOperations: PendingOperations) {

    }


    getById(id: any): MapNode {
        if (null === this._mapNodes) {
            return null;
        }
        return this._mapNodes.find(mapNode => mapNode.id === parseInt(id));
    }

    async fetchNodes(ids: number[] = null) {
        const request = {
            "mapId": this.mapScope.id
        };
        if (null != ids) {
            request["ids"] = ids;
        }
        const data = await (await Model.rest("get_map_nodes","POST",request)).json();
        if (null == this._wormholes) {
            this._wormholes = [];
        }
        if (undefined == data.wormholes) {
            console.error("no wormholes",data,data.wormholes);
        }
            data.wormholes.map(el => this.fromEntity(el))
                .forEach(el => this._wormholes.push(el));

        return data.mapNodes.map(el => this.fromEntity(el));
    }

    async getNodes(): Promise<MapNode[]> {
        if (null === this._mapNodes) {
            this._mapNodes = [];
            this._mapNodes = await this.fetchNodes();
            this._loaded = true;
        }
        return this._mapNodes;
    }

    setMapNode(mapNode: MapNode) {
        const current = this._mapNodes.find(candidate => candidate.id === mapNode.id);
        if (null == current) {
            this._mapNodes.push(mapNode);
        } else {
            for(const k in mapNode) {
                if (!/^_/.test(k)) {
                    current[k] = mapNode[k];
                }
            }
            //console.log("on update",current._onUpdate);
            current._onUpdate.forEach(e => e());
        }
    }

    async updateNodes(ids: number[]): Promise<MapNode[]> {
        //console.log("updateNodes",ids);
        if (!this._loaded) {
            return [];
        }
        const newIds = ids.filter(id => {
            const mapNode = this.getById(id);
            if (null == mapNode) {
                return true;
            }
            // if (mapNode.localUpdated) {
            //     mapNode.localUpdated = false;
            //     return false;
            // }
            if (null != mapNode.localUpdateDate
                && mapNode.localUpdateDate.getTime() > new Date().getTime() - 15000) {
                //console.log("diff",mapNode.localUpdateDate.getTime() - new Date().getTime());
                // mapNode.localUpdateDate = null;
                return false;
            }

            return true;
        });
        if (0 == newIds.length) {
            return [];
        }
        return this.loadNodes(newIds);
    }

    async loadNodes(ids: number[]): Promise<MapNode[]> {
        if (!this._loaded) {
            return [];
        }

        const mapNodes = await this.fetchNodes(ids);
        mapNodes.forEach(el => {
            // el.otherUpdateDate = new Date();
            this.setMapNode(el);
        });
        //console.log("ADD MAP NODES",mapNodes);
        if (mapNodes.length > 0) {
            this.onLoadMapNodes.forEach(e => e());
        }

        return mapNodes;
    }





    async insertMapNode(mapNode: MapNode) {
        mapNode.onLocalUpdate();
        return await (await Model.rest("insert_map_node","POST",{
            map: this.mapScope.id,
            type: mapNode.type,
            title: mapNode.title != null ? mapNode.title : "",
            data: "",
            level: mapNode.mapObject.level,
            x: mapNode.mapObject.position.x,
            y: mapNode.mapObject.position.y,
            sizeX: mapNode.mapObject.size.x,
            sizeY: mapNode.mapObject.size.y,
            options: mapNode.options != null ? mapNode.options : ""
        })).json();


    }

    async updateMapNode(mapNode: MapNode) {
        mapNode.onLocalUpdate();
        return await (await Model.rest("update_map_node","POST",{
            id: mapNode.id,
            title: mapNode.title != null ? mapNode.title : "",
            level: mapNode.mapObject.level,
            x: mapNode.mapObject.position.x,
            y: mapNode.mapObject.position.y,
            sizeX: mapNode.mapObject.size.x,
            sizeY: mapNode.mapObject.size.y,
            hidden: mapNode.hidden,
            options: mapNode.options != null ? mapNode.options : "",
        })).json();

    }

    async deleteMapNode(mapNode: MapNode) {
        mapNode.onLocalUpdate();
        DelayedOperation.deletePending("updateMapNode"+mapNode.id);
        this.pendingOperations.set(mapNode,"update",false);
        if (undefined != mapNode.mapNodeDatas) {
            mapNode.mapNodeDatas.forEach(mapNodeData => {
                DelayedOperation.deletePending("updateMapNodeData" + mapNodeData.id)
                this.pendingOperations.set(mapNodeData, "update", false);
            });
        }
        return await (await Model.rest("delete_map_node","POST",{
            id: mapNode.id,
        })).json();

    }

    async touchMapNode(mapNode: MapNode) {
        const time = new Date().getTime();
        // if (null != mapNode.touchedTime && time - mapNode.touchedTime < 60000) {
        //     return;
        // }
        mapNode.touchedTime = time;
        return await (await Model.rest("touch_map_node","POST",{
            id: mapNode.id,
        })).json();

    }


    requestUpdateMapNode(mapNode: MapNode) {

        mapNode.onLocalUpdate();
        this.pendingOperations.set(mapNode,"update",true);
        return DelayedOperation.run("updateMapNode"+mapNode.id,() => this.updateMapNode(mapNode)).then(_ => {
            this.pendingOperations.set(mapNode,"update",false);
        });


    }


    private fromEntity(obj): MapNode {
        const mapObject = new MapObject(
            new Vector2(obj.x, obj.y),
            new Vector2(obj.sizeX, obj.sizeY),
            obj.level
        );

        return new MapNode(obj.id,
            obj.title,
            obj.options,
            obj.createdAt,
            obj.updatedAt,
            obj.type, obj.data, mapObject,null,obj.password,obj.publicKey,
            ModelUtil.entityIdToNumeric(obj.map),ModelUtil.entityIdToNumeric(obj.owner),
            true === obj.hidden,
            obj.touchedAt
        );
    }



    getNodesLoaded() {
        return this._mapNodes;
    }

    isLoaded() {
        return this._loaded;
    }

    getWormholesLoaded() {
        return this._wormholes;
    }

    async addNode(type:MapNodeType, position: Vector2, level: number = 0) {

        const mapNode = new MapNode(null,
            "", "",null, null,
            type.getType(),

            "", new MapObject(
                position,
                type.getDefaultSize(),
                level));

        const data = await this.insertMapNode(mapNode);
        // mapNode.id = ModelUtil.entityIdToNumeric(data.data.createMapNode.mapNode.id);
        // mapNode.publicKey = data.data.createMapNode.mapNode.publicKey;

        mapNode.id = ModelUtil.entityIdToNumeric(data.mapNode.id);
        mapNode.publicKey = data.mapNode.publicKey;

        this._mapNodes.push(mapNode);

        return mapNode;
    }

    setNodeDeleted(mapNode: MapNode) {
        this._mapNodes.splice(this._mapNodes.indexOf(mapNode),1);
    }

    async deleteNode(mapNode: MapNode) {
        this.setNodeDeleted(mapNode);
        await this.deleteMapNode(mapNode);
    }

    private passwordHash(mapNode: MapNode,password:string) {
        return sha256(JSON.stringify([mapNode.id,mapNode.type,password,"1394594ae6b653dc40df873def274e1a9b4076b21a1b83cc597f01c45dd564d2",mapNode.publicKey])).toString();
    }

    async passwordLock(mapNode: MapNode,password: string) {

        // mapNode.setPasswordUnlockHash(this.passwordHash(mapNode,password));

        const passwordHash = this.passwordHash(mapNode,password);
        this.pendingOperations.set(mapNode,"passwordLock",true);
        try {
            mapNode.password = true;
            const resp = await Model.rest("password_lock/"+ModelUtil.entityIdToNumeric(mapNode.id),"POST",{
                "password": passwordHash
            });
            const respJson = await resp.json();
            if (null == respJson || "ok" !== respJson.status) {
                mapNode.password = false;
            }
        } catch (e) {
            console.error(e);
        }

        this.pendingOperations.set(mapNode,"passwordLock",false);
    }

    passwordUnlock(mapNode: MapNode,password: string) {
        mapNode._passwordUnlockHash = this.passwordHash(mapNode,password);
        mapNode._passwordUnlocked = true;

    }

}