import React, {useState} from 'react';

import './wormhole.css';


import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import {MapNodeType, MapNodeTypeEnum} from "../../../node/MapNodeType";
import {MapScope} from "../../../node/MapScope";
import {MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";


export interface WormholeComponentProps {

    app: AppController,
    mapNode: MapNode,

}

export interface WormholeComponentState {


}


export class WormholeComponent extends React.Component<WormholeComponentProps, WormholeComponentState> {


    constructor(props, state) {
        super(props, state);


        this.state = {}


    }


    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onClick(e) {
        e.preventDefault();
        const otherWorld = this.props.app.mapNodeService.getWormholesLoaded();
        const allWormholes = this.props.app.getNodesVisible().concat(otherWorld);

        const wormholes = allWormholes.filter(node => MapNodeTypeEnum.WORMHOLE == node.type
            && node.title.trim() == this.props.mapNode.title.trim()
        );
        if (2 > wormholes.length) {
            alert("Add another wormhole with same title");
            return false;
        }
        const next = wormholes[(wormholes.indexOf(this.props.mapNode) + 1) % wormholes.length];

        const mapPos = next.mapObject.position;
        if (null !== next.mapId && next.mapId !== this.props.app.currentMap.id) {
            const map = new MapScope();
            map.id = next.mapId;
            document.location.href = map.getMapLink(mapPos, next.mapObject.level);

        } else {

            this.props.app.setLevel(next.mapObject.level);
            this.props.app.setViewerPosition(mapPos);
        }


        return false;

    }


    render() {
        return <div className="map-node-wormhole-contents">

            <img onClick={e => this.onClick(e)} className="rotate-animation app-node-ignore-focus app-ui"
                 src="/images/wormhole.png"/>
            <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode}/>
        </div>
    }
}
