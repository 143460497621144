import {Vector2} from "../map/Vector2";
import {Rectangle} from "../map/Rectangle";
import {DisplayArea} from "../lib/DisplayArea";

export class DisplayObject {
    constructor(public position: Vector2,
                public size: Vector2,
                public zoom: number) {

    }

    getArea(): DisplayArea {
        return new DisplayArea(this.position, this.size);
    }


}