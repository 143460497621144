import {PendingOperation, PendingOperations} from "../lib/PendingOperation";
import {Model} from "../model/Model";
import {MapNode} from "./MapNode";
import {MapNodeData} from "./MapNodeData";

export class MapCommentService {

    constructor(public pendingOperations: PendingOperations) {
    }

    fromEntity(obj) {
        return obj;
    }

    async fetchComments(mapNode: MapNode,mapNodeData?: MapNodeData,
                        minId?: number) {
        const requestData = {
            "mapNodeId": mapNode.id
        };
        if (undefined !== mapNodeData) {
            requestData["mapNodeDataId"] = mapNodeData.id;
        }
        if (undefined != minId) {
            requestData["minId"] = minId;
        }

        const data = await (await Model.rest("get_map_comments","POST",requestData)).json();
        return data.mapComments.map(el => this.fromEntity(el));
    }

    async insertMapComment(message: string, mapNode: MapNode,mapNodeData?: MapNodeData) {
        const requestData = {
            "mapNodeId": mapNode.id,
            "message": message
        };
        if (undefined !== mapNodeData) {
            requestData["mapNodeDataId"] = mapNodeData.id;
        }
        const data = await (await Model.rest("insert_map_comment","POST",requestData)).json();
        return this.fromEntity(data.mapComment);
    }

    async getStatus(countOnly=true) {
        const requestData = {
            countOnly: countOnly
        };

        const data = await (await Model.rest("get_map_comments_status","POST",requestData)).json();
        return data;
    }

}