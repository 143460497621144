import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./user-notification.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {MapInvitation} from "../../../node/MapInvitation";
import {ModelUtil} from "../../../model/ModelUtil";
import {CommentsComponent} from "../comments/CommentsComponent";
import {CommentsModalComponent} from "../comments/CommentsModalComponent";
import {UserNotificationType} from "../../../node/UserNotification";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";
import {DateComponent} from "../../widgets/DateComponent";
import {UserIconComponent} from "../user-icon/UserIconComponent";

export interface UserNotificationsComponentProps {
    app: AppController
}

export interface UserNotificationsComponentState {

    unreadCount: number,
    dialogOpened: boolean,
    notifications: any[],
    notificationsLoading: boolean

}

export class UserNotificationsComponent extends React.Component<UserNotificationsComponentProps, UserNotificationsComponentState> {

    private _interval = null;
    private _onNotificationsChange = null;


    constructor(props) {
        super(props);
        this.state = {unreadCount: 0,
            dialogOpened: false,
            notifications: [],
            notificationsLoading: false

        };
    }




    componentDidMount() {
        (async() => await this.loadNotifications())();

        this._interval = setInterval(() => {
            (async() => await this.loadNotifications(true))();
        },10000);

        this._onNotificationsChange = () => {
            this.setState({
                unreadCount: this.props.app.userService
                    .getNotificationsLoaded().filter(el => el.unread).length
            })
        };
        this.props.app.userService.onNotificationsChange.push(this._onNotificationsChange);


    }


    componentWillUnmount() {
        clearInterval(this._interval);
        this.props.app.userService.onNotificationsChange.splice(
            this.props.app.userService.onNotificationsChange.indexOf(this._onNotificationsChange),1
        )
    }

    openDialog() {
        if (!this.state.dialogOpened) {
            (async() => await this.loadNotifications())();
        }
        this.setState({
            dialogOpened: !this.state.dialogOpened
        });
    }

    // async loadNotificationsCount() {
    //     this.setState({
    //         //unreadCount: (await this.props.app.userService.getNotifications(true)).count,
    //         unreadCount: (await this.props.app.userService.getNotifications(false)).length
    //     });
    // }

    async loadNotifications(quiet = false) {

        if (!quiet) {
            this.setState({notificationsLoading: true});
        }
        const notifications = (await this.props.app.userService.getNotifications(this.props.app.currentMap, false));
        const toRemove = notifications.filter(notification => UserNotificationType.TYPE_MAP_NODE_DELETED == notification.type);
        const toUpdate = notifications.filter(notification => {
            if (-1 != [UserNotificationType.TYPE_MAP_NODE].indexOf(notification.type)) {
                if (null == this.getContextMapNode(notification)
                    || -1 != this.props.app.userService.changed.indexOf(notification)
                ) {
                    return true;
                }
            }

        });
        // this.props.app.userService.changed = [];
        // //console.log("to update",this.props.app.userService.changed.map(el => el.id),toUpdate, notifications.map(el => el.id));
        // let updateState = false;
        if (toUpdate.length > 0) {
            const left = [];
            toUpdate.forEach(el => {
                //console.log("update check",this.props.app.userService.getLoggedLoaded().id,ModelUtil.entityIdToNumeric(el.contextUser))
                if (this.props.app.userService.getLoggedLoaded().id != ModelUtil.entityIdToNumeric(el.contextUser)) {
                    const mapNode = this.props.app.mapNodeService.getById(ModelUtil.entityIdToNumeric(el.contextMapNode));
                    if (null != mapNode) {

                        mapNode._otherUpdateDate = new Date(el.createdAt);
                        mapNode._otherUpdateUserId = ModelUtil.entityIdToNumeric(el.contextUser);
                    } else {
                        left.push(el);
                    }
                }
            })
            const updateNodeIds = toUpdate.map(
                el => ModelUtil.entityIdToNumeric(el.contextMapNode)
            );
            //console.log("update node ids",updateNodeIds);
            const updated = await this.props.app.mapNodeService.updateNodes(updateNodeIds);
            if (updated.length > 0) {
                // updateState = true;
                left.forEach(el => {
                    const mapNode = this.props.app.mapNodeService.getById(ModelUtil.entityIdToNumeric(el.contextMapNode));
                    if (null != mapNode) {

                        //console.log("update wtf1 ",el);
                        mapNode._otherUpdateDate = new Date(el.createdAt);
                        mapNode._otherUpdateUserId = ModelUtil.entityIdToNumeric(el.contextUser);
                    }
                });
            }
        }
        toRemove.forEach(notification => {
            const mapNode = this.props.app.mapNodeService.getById(notification.contextOtherId);
            if (null != mapNode) {
                this.props.app.mapNodeService.setNodeDeleted(mapNode);
            }
        });
        if (toRemove.length > 0) {
            this.props.app.onViewerStateChangeCall();
        }
        this.setState({
            notifications: notifications,
            notificationsLoading: false,
            unreadCount: notifications.filter(el => el.unread).length
        });
        // if (updateState) {
        //     this.props.app.onViewerStateChangeCall();
        // }
    }

    getContextMapNode(notification) {
        return this.props.app.mapNodeService.getById(ModelUtil.entityIdToNumeric(notification.contextMapNode));
    }



    getTitle(notification: any) {
        const mapNode = this.getContextMapNode(notification);

        switch (notification.type) {
            case 1:
                return `There are new comments on "${null != mapNode?mapNode.title:""}"`
            case 2:
                return `Object changed "${null != mapNode?mapNode.title:""}"`
            default:
                return `Missing info type ${notification.type}`;
        }
    }

    // readNotification(notification) {
    //     this.setState({unreadCount: this.state.unreadCount > 0 ? this.state.unreadCount-1 : 0});
    // }


    onNotificationClick(notification) {
        const mapNode = this.getContextMapNode(notification);

        this.props.app.goto(mapNode.mapObject);
        this.props.app.setFocus(mapNode,true);

        const mapNodeId = ModelUtil.entityIdToNumeric(notification.contextMapNode);
        const mapNodeDataId = (undefined != notification.contextMapNodeData)
            ? ModelUtil.entityIdToNumeric(notification.contextMapNodeData) : undefined;


        this.setState({
            dialogOpened: false,
            unreadCount: this.state.unreadCount > 0 ? this.state.unreadCount-1 : 0
        });
        this.props.app.userService.markRead(notification).then(() => {

        });

        switch(notification.type) {
            case 1:
                setTimeout(() => {
                    if (undefined != mapNodeDataId) {
                        throw new Error("not implemented yet");
                    }
                    CommentsModalComponent.instance.open(mapNode);
                },100);
                break;
        }


    }

    getShowingNotifications() {
        return this.state.notifications.filter(el => -1 !== [UserNotificationType.TYPE_COMMENT,UserNotificationType.TYPE_MAP_NODE]
            .indexOf(el.type) && el.contextUser != el.user);
    }

    markAllAsRead() {
        this.setState({
            dialogOpened: false,
            unreadCount: 0
        });
        (async () => {
            for(const notification of this.getShowingNotifications()) {
                if (notification.unread) {
                    await this.props.app.userService.markRead(notification);
                }
            }
        })();


    }

    render() {


        return <span className="user-notifications-component">


            <div className={"btn-group "}>
                 {(0 == this.state.unreadCount)?
                     <button type="button"
                             data-tooltip={this.state.dialogOpened ? "" : "Notifications"}
                             data-tooltip-position="bottom"
                             className="btn btn-dark btn-floating dropdown-toggle ripple-surface notifications-dropdown-button"

                             aria-expanded="false"
                             onClick={e => this.openDialog()}
                     >
                         <i className="fas fa-bell" />
                     </button> : <button type="button" className="btn btn-danger btn-floating dropdown-toggle ripple-surface notifications-dropdown-button"
                                         data-tooltip={this.state.dialogOpened ? "" : "Notifications"}
                                         data-tooltip-position="bottom"
                                        aria-expanded="false"
                                        onClick={e => this.openDialog()}
                     >
                         {this.state.unreadCount}
                     </button>
                 }
                {this.state.dialogOpened?<OutsideClickComponent
                    ignoreClass="notifications-dropdown-button"
                    onOutsideClick={e => this.setState({dialogOpened: false})}>
                    <ul className={"dropdown-menu"+(this.state.dialogOpened?" open-toolbar":"")}

                        data-popper-placement="bottom-start">

                        {this.state.notificationsLoading?<LoadingComponent/>:<div>

                            {0 === this.getShowingNotifications().length?<div className={"alert alert-light"} >No notifications</div>:null}
                            <div className={"user-notification-list-container "}>
                                {this.getShowingNotifications().map(notification => <li key={"notification-"+notification.id}>
                                    <a className={"dropdown-item user-notifications-component-item"+((notification.unread)?" user-notification-unread":"") }
                                       onClick={e => this.onNotificationClick(notification)}
                                    >

                                        {null != notification.contextUser ? <UserIconComponent app={this.props.app} userId={ModelUtil.entityIdToNumeric(notification.contextUser)}/>: null}
                                        <span className={"user-notifications-component-item-date badge rounded-pill badge-light"}>
                                        <DateComponent date={notification.createdAt} ago={true} />
                                    </span>

                                        <span className={"user-notifications-component-item-title"}>{this.getTitle(notification)}</span>

                                    </a>
                                </li>)}
                            </div>

                            {this.state.unreadCount > 0?<a className={"user-notifications-component-mark-all-as-read"} onClick={e => this.markAllAsRead()} >Mark all as read</a>:null}
                        </div>
                        }
                    </ul>
                </OutsideClickComponent>:null}


            </div>






        </span>
    }
}
