import React from 'react';
import {Vector2} from "../map/Vector2";
import {MapObject} from "../map/MapObject";
import {DebugComponent} from "./DebugComponent";
import {AppController} from "../controllers/AppController";


export interface MapGridComponentProps {
    app: AppController
}

export class MapGridComponent extends React.Component<MapGridComponentProps> {


    private _canvasRef = React.createRef<HTMLCanvasElement>();




    private _drawGrid() {
        const canvas: any = this._canvasRef.current
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = '#cccccc'
        context.beginPath();
        // context.arc(50+this.props.viewerState.position.x, 100+this.props.viewerState.position.y, 20, 0, 2 * Math.PI)
        // context.fill()
        const zoom = this.props.app.getZoom();
        const gridSize = 100 * zoom;
        const center = this.props.app.viewerState.size.multiply(0.5);

        const linesX = 1 + Math.ceil(center.x / gridSize);
        for (let i = -linesX; i < linesX; i++) {
            const x = center.x + i * gridSize - this.props.app.viewerState.position.x % gridSize;
            // center.x = position.x
            const dist =  this.props.app.viewerState.position.x - this.props.app.viewerState.position.x % gridSize + i * gridSize;
            context.moveTo(x, 0);
            context.lineTo(x, this.props.app.viewerState.size.y);
            if (0 === dist % 500) {
                context.moveTo(x + 1, 0);
                context.lineTo(x + 1, this.props.app.viewerState.size.y);
            }
        }

        const linesY = 1 + Math.ceil(center.y / gridSize);
        for (let i = -linesY; i < linesY; i++) {
            const y = center.y + i * gridSize - this.props.app.viewerState.position.y % gridSize;
            // center.x = position.x
            const dist =  this.props.app.viewerState.position.y - this.props.app.viewerState.position.y % gridSize + i * gridSize;
            context.moveTo(0, y);
            context.lineTo(this.props.app.viewerState.size.x, y);
            if (0 === dist % 500) {
                context.moveTo(0, y+1);
                context.lineTo(this.props.app.viewerState.size.x, y+1);
            }
        }


        // for(let i = 0; i < 1+Math.ceil(this.props.viewerState.size.x / gridSize);i++) {
        //     const x = i * gridSize - this.props.viewerState.position.x % gridSize;
        //
        //     context.moveTo(x, 0);
        //     context.lineTo(x, this.props.viewerState.size.y);
        //     if (0 == i % 5) {
        //         context.moveTo(x+1, 0);
        //         context.lineTo(x+1, this.props.viewerState.size.y);
        //     }
        // }

        // for (let i = 0; i < 1 + Math.ceil(this.props.viewerState.size.y / gridSize); i++) {
        //     const y = i * gridSize - this.props.viewerState.position.y % gridSize;
        //
        //     context.moveTo(0, y);
        //     context.lineTo(this.props.viewerState.size.x, y);
        //     if (0 == i % 5) {
        //         context.moveTo(0, y + 1);
        //         context.lineTo(this.props.viewerState.size.x, y + 1);
        //     }
        // }

        context.stroke();
    }

    componentDidMount() {
        this._drawGrid();
    }

    componentDidUpdate() {
        this._drawGrid();
        // window["grid"] = this;
    }


    render() {
        return <canvas width={this.props.app.viewerState.size.x}
                       height={this.props.app.viewerState.size.y}
                       draggable={false}
                       className="map-grid" ref={this._canvasRef}/>;

    }
}
