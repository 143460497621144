import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./share.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {MapInvitation} from "../../../node/MapInvitation";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";
import {MapUserAccess} from "../../../node/MapUserAccess";
import {Vector2} from "../../../map/Vector2";


export interface ShareComponentProps {
    app: AppController
}

export interface ShareComponentState {
    dialogOpened: boolean,
    sharing: boolean,
    invitationsOpen: boolean
}

export class ShareComponent extends React.Component<ShareComponentProps, ShareComponentState> {

    private _shareEmail = React.createRef<HTMLInputElement>();

    constructor(props) {
        super(props);
        this.state = { dialogOpened: false, sharing: false, invitationsOpen: false };
    }


    componentDidMount() {
        if (null != this.props.app.uriAction
            && ["accept_invitation","reject_invitation"].indexOf(this.props.app.uriAction.action) !== -1
        ) {
            (async () => {
                while(this.props.app.mapService.getInvitationsLoaded().length === 0) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }
                const invitation = this.props.app.mapService.getInvitationsLoaded().find(inv => inv.accessId === parseInt(this.props.app.uriAction.id));
                if (null != invitation) {
                    switch(this.props.app.uriAction.action) {
                            case "accept_invitation":
                                this.acceptInvitation(invitation);
                                break;
                            case "reject_invitation":
                                this.rejectInvitation(invitation,true);
                                break;
                    }
                }
                this.props.app.uriAction = null;
            })();
        }
    }

    openDialog() {
        this.setState({ dialogOpened: true });
    }

    onSubmit(e) {
        e.preventDefault();
        (async () => {
            this.setState({ sharing: true });
            try {

                const email = this._shareEmail.current.value;
                const mapAccess = await this.props.app.mapService
                    .shareMap(this.props.app.currentMap,email,false);
                this.props.app.notify("Shared","success");
                // this._shareEmail.current.value = "";
                this.setState({ sharing: false });

            } catch (e) {
                console.error(e);
                this.props.app.notify(e.message,"danger");
                this.setState({ sharing: false });
            }

        })();
    }

    remove(mapAccess) {
        if (window.confirm("Are you sure you want to REMOVE the user from this map?")) {
            (async () => {
                this.setState({sharing: true});
                try {
                    await this.props.app.mapService.unShareMap(mapAccess);

                } catch (e) {
                    this.props.app.notify(e.message,"danger");
                }
                this.setState({sharing: false});

            })();
        }
    }

    acceptInvitation(invitation: MapInvitation) {
        (async () => {
            const map = await this.props.app.mapService.acceptInvitation(invitation);
            this.props.app.notify("Invitation was accepted, you have access to a new map "+invitation.mapTitle,"success");
            this.forceUpdate();
            document.location.href = map.getMapLink(new Vector2(0,0),0);
        })();
    }
    rejectInvitation(invitation: MapInvitation,force=false) {
        (async () => {


            if (force || await this.props.app.confirm("Are you sure you want to reject the invitation?","warning")) {
                await this.props.app.mapService.rejectInvitation(invitation);
                this.props.app.notify("Invitation was rejected","danger");
                this.forceUpdate();
            }


        })();
    }

    toggleReadonly(mapAccess: MapUserAccess) {

        const oldReadonly = mapAccess.readOnly;
        mapAccess.readOnly = !mapAccess.readOnly;
        this.forceUpdate();
        (async () => {
            try {
                await this.props.app.mapService.saveMapPermissions(mapAccess);
                this.props.app.notify("Urser's rights updated","success");
            } catch (e) {
                this.props.app.notify(e.message,"danger");
                mapAccess.readOnly = oldReadonly;
            } finally {
                this.forceUpdate();

            }
        })();
    }

    render() {


        return <span className="share-component">

            {this.props.app.mapService.getInvitationsLoaded().length > 0?<div className="btn-group share-component-invitations">
                <button type="button" className="invitations-dropdown-button btn btn-danger btn-floating dropdown-toggle ripple-surface"
                    // data-mdb-toggle="dropdown"
                        aria-expanded="false"
                        onClick={e => this.setState({invitationsOpen: !this.state.invitationsOpen})}
                >
                    {this.props.app.mapService.getInvitationsLoaded().length}
                </button>
                {this.state.invitationsOpen?<OutsideClickComponent ignoreClass="invitations-dropdown-button" onOutsideClick={e => this.setState({invitationsOpen: false})}>
                        <ul className={"dropdown-menu"+(this.state.invitationsOpen?" open-toolbar":"")}
                            data-popper-placement="bottom-start">

                            {this.props.app.mapService.getInvitationsLoaded().map(invitation => <li className="dropdown-item">
                                <span className="invitation-info">You are invited to: <b>{invitation.mapTitle}</b> by <b>{invitation.from}</b></span>
                                <a onClick={e => this.acceptInvitation(invitation)} className="text-success" href="#"><i className="fa fa-check" /></a>
                                <a onClick={e => this.rejectInvitation(invitation)} className="text-danger" href="#"><i className="fa fa-trash" /></a>

                            </li>)}

                        </ul>
                    </OutsideClickComponent>
                    :null}

            </div>:null}


            {this.state.dialogOpened?<ModalComponent title={"Share this map"} onClose={() => this.setState({ dialogOpened: false})}>

                {this.state.sharing?<LoadingComponent />:<div>
                    <form onSubmit={e => this.onSubmit(e)}>
                        <div className="input-group mb-3">
                            <input type="email" className="form-control app-no-delete-object-on-key-down"
                                   ref={this._shareEmail}
                                   placeholder="Email address"
                                   required={true}
                            />
                            <button className="btn btn-outline-primary"
                                    type="submit"
                            >
                                Share
                            </button>

                        </div>
                    </form>

                    <div className="share-component-list">
                        {this.props.app.mapService.getOtherMapAccessesLoaded(this.props.app.currentMap).map(mapAccess => <div
                            className="share-component-list-item"
                            key={mapAccess.id}>
                            <a href="#" onClick={e => this.remove(mapAccess)}
                               title="Remove user from this map"
                               className="text-danger"><i className="fa fa-trash"/></a>

                            <a href="#" onClick={e => this.toggleReadonly(mapAccess)}
                               title="Change the user's permissions"
                               className="text-warning">
                                <i className={!mapAccess.readOnly ? "fas fa-pen" : "fas fa-eye"}/>
                                {mapAccess.readOnly ? "Reading only others" : "Editing others"}
                            </a>


                            <div className="share-component-list-item-title">
                                {mapAccess.getTitle()}
                            </div>

                        </div>)}
                    </div>

                </div>}

            </ModalComponent>:null}
            <button type="button" className="btn btn-dark btn-floating dropdown-toggle ripple-surface"
                    data-tooltip="Share this map"
                    data-tooltip-position="bottom"
                // data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    onClick={e => this.openDialog()}
            >
                        <i className="fas fa-share-alt" />
                    </button>




        </span>
    }
}
