export class DomUtil {


    static getOfClassOrParent(element,className): boolean {
        if (null == element || null == element.classList) {
            return null;
        }
        if (Array.isArray(className)) {
            for(const cn of className) {
                if (DomUtil.getOfClassOrParent(element, cn)) {
                    return element;
                }
            }
            return null;
        }
        if (element.classList.contains(className)) {
            return element;
        } else if (null != element.parentNode) {

            return DomUtil.getOfClassOrParent(element.parentNode,className);
        } else {
            return null;
        }
    }

    static getOfAttributeOrParent(element,attr,depth = 3) {
        const val = element.getAttribute(attr);
        if (undefined != val) {
            return element;
        } else {
            if (depth > 0 && undefined != element.parentNode) {
                return DomUtil.getOfAttributeOrParent(element.parentNode,attr,depth-1);
            } else {
                return null;
            }
        }
    }

    static isElementScrollable(element,untilClassName=null) {
        const result = element.scrollHeight > element.clientHeight;
        if (result) {
            return true;
        }
        if (null != untilClassName) {
            const parent = element.parentNode;
            if (null != parent && null != parent.classList && !element.classList.contains(untilClassName)) {
                return DomUtil.isElementScrollable(parent,untilClassName);
            }
        }
        return false;
    }

    static contains(parent,element): boolean {
        return parent.contains(element);
    }

    static containsOrIs(parent,element): boolean {
        return parent === element || DomUtil.contains(parent,element)
    }

    static getRealScrollWidth(element) {
        const w: any = window;
        w["domut"] = DomUtil;
        const width = element.style.width;
        element.style.width = "1px";
        const result = element.scrollWidth;
        element.style.width = width;
        return result;
    }

    private static _tmpTextarea = null;

    static getRealScrollHeight(element) {
        if (null === DomUtil._tmpTextarea) {
            DomUtil._tmpTextarea = document.createElement("textarea");
            DomUtil._tmpTextarea.className = element.className + " _dom-util-tmp-textarea";

            DomUtil._tmpTextarea.style.height = "31px";
            document.body.appendChild(DomUtil._tmpTextarea);
        }
        DomUtil._tmpTextarea.value = element.value;
        // DomUtil._tmpTextarea.style.width = element.getBoundingClientRect().width + "px";
        DomUtil._tmpTextarea.style.width = element.offsetWidth + "px";
        DomUtil._tmpTextarea.style.height = "1px";
        const result = DomUtil._tmpTextarea.scrollHeight;
        DomUtil._tmpTextarea.style.height = "29px";
        return result;

    }
}
const w: any = window;
w["domut"] = DomUtil;
