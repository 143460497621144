import React, {useState} from 'react';
import {getIconIndex, getIconTitle, ICONS} from "../../../lib/Icons";
import './select-icon.css';




export interface SelectIconComponentProps {

    color: string,
    selectedIcon: number
    onSelectIcon: (index: number) => void
}
export interface SelectIconComponentState {
    filter: string,
    // selectedIcon: number
}

export class SelectIconComponent extends React.Component<SelectIconComponentProps,SelectIconComponentState> {

    private _inputRef = React.createRef<HTMLInputElement>();

    constructor(props,state) {
        super(props,state);
        this.state = {
            filter: "",
            // selectedIcon: props.selectedIcon
        }


    }



    componentDidMount() {
        if (null != this._inputRef.current) {
            this._inputRef.current.focus();
        }
    }

    componentDidUpdate() {

    }

    onSelectIcon(index: number) {
        // this.setState({selectedIcon: index});
        this.props.onSelectIcon(index);
    }


    render() {
        const icons = ICONS.filter(icon => {
            return 0 === this.state.filter.trim().length
                || -1 !== getIconTitle(icon).indexOf(this.state.filter.toLowerCase().trim())
        }).map(icon => <i className={icon+(getIconIndex(icon) === this.props.selectedIcon ? " shadow-1-strong":"")}
                                  key={icon}
                                    title={getIconTitle(icon)}
                                  onClick={e => this.onSelectIcon(getIconIndex(icon))} style={{color: this.props.color}} />);


        return <div className="select-icon-component">
            <input type="text" className="select-icon-component-filter form-control form-control-lg shadow-1-strong"
                     ref={this._inputRef}
                   value={this.state.filter}
                   onChange={e => this.setState({ filter: e.target.value})}
                   placeholder="Search icon.." />
            <div className="select-icon-component-list">
                {icons}
            </div>
        </div>
    }
}
