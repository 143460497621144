import React, {useState} from 'react';




export interface PlaneUploadComponentProps {
    planeKey: string;
    options: any;
    onChange: (e: any) => void;
}
export interface PlaneUploadComponentState {

}

export class PlaneUploadComponent extends React.Component<PlaneUploadComponentProps,PlaneUploadComponentState> {

    button: any = null;
    optionsString: string = null;

    constructor(state,props) {
        super(state,props);
        this.state = {

        }
        let options = {};
        if (null != this.props.options) {
            options = this.props.options;
        }
        this.optionsString = JSON.stringify(options);
    }

    componentDidMount() {

        (async() => {
            this.button =  await this.getButtonFromRegistry();
            // this.button.onChange = e => {
            //     console.log("BUTTON EENT",e);
            // }
            // console.log("button",this.button);
            const w: any = window;
            w["asdf"] = this.button;
            // this.setState({button: button});

        })();
    }

    getButtonFromRegistry() {
        return new Promise((resolve,reject) => {
            const check = () => {
                if (undefined != window["PlaneUploadInstance"]
                    && null != window["PlaneUploadInstance"].getButtonRegistry()
                    && undefined !== window["PlaneUploadInstance"].getButtonRegistry()[this.props.planeKey]

                ) {
                    const btns = window["PlaneUploadInstance"].getButtonRegistry()[this.props.planeKey];
                    setTimeout(()=>{
                        btns[btns.length - 1].onChange = e => {
                            this.props.onChange(e);
                        };
                    },500);

                    resolve(btns[btns.length - 1]);
                    // console.log("found ",btns);
                } else {
                    // console.log("checking ",this.props.planeKey);
                    setTimeout(() => check(),500);
                }
            };
            check();
        });
    }

    render() {

        return <div>
            {/*{this.state.}*/}
            <div data-plane-upload-options={this.optionsString} data-plane-upload={this.props.planeKey}>loading</div>
        </div>;
    }
}
