import React, {useState} from 'react';
import "./dropdown-button.css";
import {OutsideClickComponent} from "../outside-click/OutsideClickComponent";

export interface DropdownButtonComponentButton {

    title: string,
    onClick: () => void,
    className?: string
}

export interface DropdownButtonComponentProps {

    buttons: DropdownButtonComponentButton[]

}

export interface DropdownButtonComponentState {
    opened: boolean
}

export class DropdownButtonComponent extends React.Component<DropdownButtonComponentProps, DropdownButtonComponentState> {


    constructor(props, state) {
        super(props, state);
        this.state = {
            opened: false
        }


    }


    render() {

        let menu = null;

        if (this.state.opened) {
            menu = <ul className={"dropdown-menu open-toolbar"}

                       data-popper-placement="bottom-start">
                {this.props.buttons.map(
                    (button,i) => <li key={i}><a onClick={e => {this.setState({opened: false}); button.onClick()}} className={"dropdown-item "+(null != button.className?button.className:"")} href="#">{button.title}</a></li>)}
                {/*<li>*/}
                {/*    <hr className="dropdown-divider"/>*/}
                {/*</li>*/}

            </ul>
        }

        return <div className="btn-group dropdown-button-component">
            <OutsideClickComponent onOutsideClick={e => this.setState({ opened: false })}>
            <button type="button" className="btn btn-light btn-floating dropdown-toggle ripple-surface"
                // data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    onClick={e => this.setState({opened: !this.state.opened})}
            >
                <i className="map-menu-button fas fa-ellipsis-v" />
            </button>
            {menu}
            </OutsideClickComponent>
        </div>;

        // return <div>
        //     <i onClick={e => this.setState({"opened": !this.state.opened})} className="map-menu-button fas fa-ellipsis-v" />
        //
        // </div>
    }
}
