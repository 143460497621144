import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./comments.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {MapInvitation} from "../../../node/MapInvitation";
import {MapNodeType, MapNodeTypeEnum} from "../../../node/MapNodeType";
import {DaemonUtils} from "../../../utils/DaemonUtils";
import {MapNode} from "../../../node/MapNode";
import {MapNodeData} from "../../../node/MapNodeData";
import {DateComponent} from "../../widgets/DateComponent";
import {UserIconComponent} from "../user-icon/UserIconComponent";
import {ModelUtil} from "../../../model/ModelUtil";
import {CommentsComponent} from "./CommentsComponent";
import {UserNotificationType} from "../../../node/UserNotification";

export interface CommentsModalComponentProps {
    app: AppController
}

export interface CommentsModalComponentState {

    opened: boolean,
    comments: any[],
    commentsLoading: boolean,
    mapNode: MapNode,
    mapNodeData: MapNodeData

}


export class CommentsModalComponent extends React.Component<CommentsModalComponentProps, CommentsModalComponentState> {

    private _messageRef = React.createRef<HTMLInputElement>();
    private _listRef = React.createRef<HTMLUListElement>();
    // private _interval = null;
    private _onNotificationsChange;


    public static instance: CommentsModalComponent = null;


    constructor(props) {
        super(props);
        CommentsModalComponent.instance = this;

        this.state = {opened: false, comments: [], mapNode: null, mapNodeData: null, commentsLoading: true}
    }

    componentDidMount() {
        CommentsModalComponent.instance = this;
        window["CommentsModalComponentInstance"] = this;
        // this._interval = setInterval(() => {
        //     this.commentsOpenLoadCheck();
        // }, 1000);
        this._onNotificationsChange = () => this.commentsOpenLoadCheck();
        this.props.app.userService.onNotificationsChange.push(this._onNotificationsChange);
    }

    commentsOpenLoadCheck() {
        if (!this.state.opened) {
            return;
        }
        const unreadNotifications = this.props.app.userService.getNotificationsLoaded()
            .filter(notification => notification.unread
                && UserNotificationType.TYPE_COMMENT == notification.type
                && ModelUtil.entityIdToNumeric(notification.contextMapNode) == this.state.mapNode.id
            );
        if (unreadNotifications.length > 0) {
            this.loadComments(true);
            this.props.app.userService.markRead(unreadNotifications[0]);

        }
    }

    componentWillUnmount() {
        //CommentsModalComponent.instance = undefined;
        // clearInterval(this._interval);
        this.props.app.userService.onNotificationsChange.splice(
            this.props.app.userService.onNotificationsChange.indexOf(this._onNotificationsChange)
            , 1
        )
    }

    getContextString() {
        return CommentsComponent
            .getContextString(this.state.mapNode.id,
                (undefined !== this.state.mapNodeData) ? this.state.mapNodeData.id : undefined);
    }

    getSessionKey() {
        return "comment.addNewTmp." + this.getContextString();
    }

    public open(mapNode: MapNode, mapNodeData?: MapNodeData) {
        console.log("open comments", mapNode);
        this.setState({
            opened: true,
            mapNode: mapNode,
            mapNodeData: mapNodeData
        }, () => {
            this.loadComments();
            const sessionAddNew = sessionStorage.getItem(this.getSessionKey());
            if (null != sessionAddNew) {
                setTimeout(() => {
                    if (null != this._messageRef.current) {
                        this._messageRef.current.value = sessionAddNew;


                    }
                }, 50);
            }
            this.focusInput();
        });


        // this.props.app.deselectAll();

    }

    public focusInput() {
        if (!this.state.opened) {
            return;
        }
        if (null == this._messageRef.current) {
            setTimeout(()=>this.focusInput(),10);
        } else {
            this._messageRef.current.focus();
        }
    }

    public close() {
        this.setState({
            opened: false,
            mapNode: null,
            mapNodeData: null,
            comments: []
        })
    }

    isOpen() {
        return this.state.opened;
    }


    async loadComments(newOnly=false) {
        if (!newOnly) {
            this.setState({
                comments: [],
                commentsLoading: true
            });
        }

        const scrolledDown = this.isScrolledDown();
        const minId = newOnly && this.state.comments.length > 0 ? this.state.comments[this.state.comments.length-1].id : undefined;
        const comments = await this.props.app.mapCommentService.fetchComments(this.state.mapNode, this.state.mapNodeData,minId);

        const allComments = this.state.comments.concat(comments);
        this.state.mapNode.commentsCount = allComments.length;

        this.setState(
            {comments: allComments,
                commentsLoading: false},() => {
                console.log("scrolled down",scrolledDown);
                if (!newOnly || scrolledDown) {
                    this.scrollDown();
                }
            });


    }

    scrollDown() {
        //setTimeout(() => {
            this._listRef.current.scrollTop = this._listRef.current.scrollHeight;
        //}, 50);
    }

    isScrolledDown() {
        if (null == this._listRef || null == this._listRef.current) {
            return true;
        }

        return this._listRef.current.scrollTop == this._listRef.current.scrollHeight - this._listRef.current.clientHeight;
    }

    addComment() {
        (async () => {
            const value = this._messageRef.current.value;
            if (0 == value.trim().length) {
                return;
            }
            this._messageRef.current.value = "";
            const comment = await this.props.app.mapCommentService.insertMapComment(value,
                this.state.mapNode, this.state.mapNodeData);
            this.state.comments.push(comment);
            this.state.mapNode.commentsCount++;
            this.setState({comments: this.state.comments},() => this.scrollDown());
            sessionStorage.removeItem(this.getSessionKey());
        })();
    }

    onKeyDown(e) {
        if (this.props.app.io.isKeyDown("Shift")) {
            return;
        }

        switch (e.key) {
            case "Enter":
                e.preventDefault();
                this.addComment();
                break;
        }
    }

    onAddNewChange(e) {

        sessionStorage.setItem(this.getSessionKey(), e.target.value);
    }


    getContextTitle() {
        return "Comments on \"" + this.state.mapNode.title + "\"";
    }

    render() {

        return this.state.opened ? <ModalComponent onClose={() => this.close()} title={this.getContextTitle()}>
            {this.state.commentsLoading ? <div className={"comments-component"}><LoadingComponent/></div> :
                <span
                    className={"comments-component comments-component-opened comments-component-opened-container app-ui"}>
                <ul className={"list-group"}
                    ref={this._listRef}
                >
                {this.state.comments.map(comment => <li key={comment.id} className={"list-group-item"}>
                    <div className={"row"}>
                        <div className={"col-10"}>

                                <span className={"comments-component-comment-body"}>
                                    <div>{comment.message}</div>
                                </span>


                        </div>
                        <div className={"col-2"}>
                                <span className={"comments-component-comment-header"}>
                                    <UserIconComponent app={this.props.app}
                                                       userId={ModelUtil.entityIdToNumeric(comment.user)}/>
                            <div className={"comments-component-commment-date"}>
                                <DateComponent ago={true} date={comment.createdAt}/>
                            </div>
                        </span>
                        </div>
                    </div>


                </li>)}

            </ul>
            <div className="input-group">
                <input ref={this._messageRef} type="text" className="form-control"
                       placeholder="Add a comment..."
                       onChange={e => this.onAddNewChange(e)}
                       onKeyUp={e => this.onKeyDown(e)}

                />
                <button onClick={() => this.addComment()} className="btn btn-outline-primary" type="button"
                        data-mdb-ripple-color="dark">
                    <i className="fas fa-paper-plane"></i>
                </button>
            </div>
            </span>}

        </ModalComponent> : <div/>;

        // return <span
        //     className={"comments-component app-ui " + (this.state.opened ? "comments-component-opened-container" : "")}>
        //     {this.state.opened ? <div className={"comments-component-opened"}>
        //         <ModalComponent onClose={() => this.toggleOpen()} title="Comments">
        //             <ul className={"list-group"}
        //                 ref={this._listRef}
        //             >
        //                 {this.state.comments.map(comment => <li key={comment.id} className={"list-group-item"}>
        //                     <div className={"row"}>
        //                         <div className={"col-md-10"}>
        //
        //                         <span className={"comments-component-comment-body"}>
        //                             <div>{comment.message}</div>
        //                         </span>
        //
        //
        //                         </div>
        //                         <div className={"col-md-2"}>
        //                         <span className={"comments-component-comment-header"}>
        //                             <UserIconComponent app={this.props.app}
        //                                                userId={ModelUtil.entityIdToNumeric(comment.user)}/>
        //                     <span className={"comments-component-commment-date"}>
        //                         <DateComponent ago={true} date={comment.createdAt}/>
        //                     </span>
        //                 </span>
        //                         </div>
        //                     </div>
        //
        //
        //                 </li>)}
        //
        //             </ul>
        //             <div className="input-group">
        //                 <input ref={this._messageRef} type="text" className="form-control"
        //                        placeholder="Add a comment..."
        //                        onChange={e => this.onAddNewChange(e)}
        //                        onKeyUp={e => this.onKeyDown(e)}
        //
        //                 />
        //                 <button onClick={() => this.addComment()} className="btn btn-outline-primary" type="button"
        //                         data-mdb-ripple-color="dark">
        //                     <i className="fas fa-paper-plane"></i>
        //                 </button>
        //             </div>
        //         </ModalComponent>
        //
        //     </div> : null}
        //
        //     <span className={"comments-component-button"}
        //           onClick={e => this.toggleOpen()}
        //     >
        //         <i title={"Comments"} className={" fas fa-comment comments-component-button-icon "}/>
        //         {/*<span className={"comments-component-indicator"}>5</span>*/}
        //
        //     </span>
        //
        // </span>
    }
}
