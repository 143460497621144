import React, {useState} from 'react';

import './flag.css';


import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import {SelectIconComponent} from "../../widgets/select-icon/SelectIconComponent";
import {ICONS} from "../../../lib/Icons";
import {MapNodeFlag} from "../../../node/MapNodeType";
import {MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {FlagEditComponent} from "./FlagEditComponent";


export interface FlagModalComponentProps {

    app: AppController,

}
export interface FlagModalComponentState {
    mapNode: MapNode,
    openedOptions: boolean,
    selectedColor: string,
    iconIndex: number,

}


export class FlagModalComponent extends React.Component<FlagModalComponentProps,FlagModalComponentState> {

    private static _instance: FlagModalComponent = null;

    constructor(props,state) {
        super(props,state);

    }

    private _callback = null;

    public static open(mapNode: MapNode,callback: Function) {
        const component = FlagModalComponent._instance;
        if (null == component) {
            console.log("FlagModalComponent not mounted");
            return;
        }
        component._callback = callback;
        console.log("open");
        const selectedColor = mapNode.getOptionsObject().get(1,false);
        const selectedIcon = mapNode.getOptionsObject().get(0);
        component.setState({
            mapNode: mapNode,
            openedOptions: true,
            selectedColor: (selectedColor?selectedColor:MapNodeFlag.DEFAULT_COLOR),
            iconIndex: selectedIcon?selectedIcon:MapNodeFlag.DEFAULT_ICON
        });


    }

    componentDidMount() {
        FlagModalComponent._instance = this;
    }

    componentWillUnmount() {
        // if (FlagModalComponent._instance === this) {
        //     FlagModalComponent._instance = null;
        // }
    }



    getIcon() {
        return ICONS[this.state.iconIndex];
    }

    private setOption(option,value) {
        console.log("set value",option,value);
        const options = this.state.mapNode.getOptionsObject();
        options.set(option,value);
        this.state.mapNode.setOptionsObject(options);
        this.props.app.mapNodeService.requestUpdateMapNode(this.state.mapNode);
        if (undefined != this._callback) {
            this._callback();
        }
    }

    onSelectIcon(icon: number) {
        this.setOption(0,icon);
        this.setState({iconIndex: icon});
    }

    onColorChange(e) {
        this.setState({selectedColor: e.target.value});
        this.setOption(1,this.state.selectedColor);



    }


    toggleOpenedOptions() {
        this.setState({openedOptions: !this.state.openedOptions});
    }

    render() {
        if (null == this.state || !this.state.openedOptions) {
            return <div/>;
        }
        return <ModalComponent title={"Customize your flag"} onClose={e => this.setState({openedOptions: false})}>
            <FlagEditComponent app={this.props.app} mapNode={this.state.mapNode} callback={this._callback} />
        </ModalComponent>
    }
}
