import React, {useState} from 'react';




export interface DateComponentProps {
    date: any,
    ago?: boolean
}
export interface DateComponentState {
    dateFormatted: string
}

export class DateComponent extends React.Component<DateComponentProps,DateComponentState> {

    private static formatCache = {};
    private _date: Date = null;

    constructor(props: DateComponentProps,state: DateComponentState) {
        super(props,state);

        this._date = DateComponent.parseDateString(this.props.date);

        // let parsed = "";
        // if (null != this.props.date) {
        //     parsed = this.props.date.replace(/T/," ").replace(/\+(.*)$/,"");
        // }
        // const date = Date.parse(parsed);

        this.state = {
            // dateFormatted: parsed
            dateFormatted: this._date.toLocaleString("en-CA",{hour12: false})
        }
    }

    public static parseDateString(date: string): Date {
        return new Date(Date.parse(date));
    }



    public static formatDate(date: Date,ago: boolean = false): string {
        if (ago) {
            const ago = (new Date().getTime() - date.getTime()) / 1000;
            let str = "";
            if (ago < 60) {
                str = "<1m";
            } else if (ago < 60*60) {
                str = Math.floor(ago / 60) + "m";
            } else if (ago < 3600*24) {
                str = Math.floor(ago / 3600) + "h";
            } else {
                str = Math.floor(ago / (24*3600)) + "d";
            }
            return str;
        } else {
            if (undefined === DateComponent.formatCache[date.getTime()]) {
                DateComponent.formatCache[date.getTime()] = date.toLocaleString("en-CA",{hour12: false});
            }
            return DateComponent.formatCache[date.getTime()];
        }
    }

    getDateFormatted() {
        return DateComponent.formatDate(this._date,this.props.ago);
        // if (this.props.ago) {
        //     const ago = (new Date().getTime() - this._date.getTime()) / 1000;
        //     let str = "";
        //     if (ago < 60) {
        //         str = "<1m";
        //     } else if (ago < 60*59) {
        //         str = Math.floor(ago / 60) + "m";
        //     } else if (ago < 3600*24) {
        //         str = Math.floor(ago / 3600) + "h";
        //     } else {
        //         str = Math.floor(ago / (24*3600)) + "d";
        //     }
        //
        //     return str;
        // } else {
        //     return this.state.dateFormatted;
        // }
    }

    render() {
        return <span className="app-widget-date"
            title={this.state.dateFormatted}
        >
            {this.getDateFormatted()}
        </span>
    }
}
