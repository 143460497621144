import React, {useState} from 'react';
import {Vector2} from "../map/Vector2";
import {MapObject} from "../map/MapObject";


import {AppController} from "../controllers/AppController";

import createLinkifyPlugin from 'draft-js-linkify-plugin';
import {MapNode} from "../node/MapNode";
import {NoteComponent} from "./nodes/NoteComponent";
import {MapNodeType, MapNodeTypeEnum} from "../node/MapNodeType";
import {FlagComponent} from "./nodes/flag/FlagComponent";
import {TodoComponent} from "./nodes/todo/TodoComponent";
import {WormholeComponent} from "./nodes/wormhole/WormholeComponent";
import {LogComponent} from "./nodes/log/LogComponent";
import {FilesComponent} from "./nodes/files/FilesComponent";
import {getIcon, getIconUnicode} from "../lib/Icons";
import {LoadingComponent} from "./widgets/loading/LoadingComponent";
import {NoteTxtComponent} from "./nodes/note/NoteTxtComponent";
import {PasswordUnlockComponent} from "./widgets/password-unlock/PasswordUnlockComponent";
import {MapNodeToolsComponent} from "./ui/node-tools/MapNodeToolsComponent";
import {User} from "../node/User";
import {Model} from "../model/Model";
import {ModelUtil} from "../model/ModelUtil";


export interface MapNodeComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,

}

export interface MapNodeComponentState {
    forceReload: boolean
}


export class MapNodeComponent extends React.Component<MapNodeComponentProps, MapNodeComponentState> {


    isFocused() {
        return this.props.app.focus === this.props.mapNode;
    }

    private _onUpdate = null;


    componentDidMount() {
        this._onUpdate = () => {
            //
            // this.forceUpdate();
            this.setState({forceReload: true});
        }
        this.props.mapNode._onUpdate.push(this._onUpdate);
    }

    componentWillUnmount() {
        this.props.mapNode._onUpdate.splice(this.props.mapNode._onUpdate.indexOf(this._onUpdate));
    }

    componentDidUpdate() {

    }


    // onChangeTitle(e) {
    //     this.setState({title: e.target.value});
    //     this.props.mapNode.title = e.target.value;
    //     this.props.app.mapNodeService.requestUpdateMapNode(this.props.mapNode);
    // }


    async passwordUnlock(password) {

        if (!password) {
            return;
        }
        this.props.app.mapNodeService.passwordUnlock(this.props.mapNode, password);
        try {
            await this.props.app.mapNodeDataService.fetchNodeData(this.props.mapNode);

            this.props.app.onViewerStateChangeCall();
        } catch (e) {
            this.props.mapNode._passwordUnlockHash = null;
            this.props.mapNode._passwordUnlocked = false;
            alert(e.message);
        }
        // .then(() => {
        //
        // });
    }

    fullScreenEdit() {
        this.props.app.setFocus(this.props.mapNode);
        this.props.app.focusFullScreenEdit = true;
        this.forceUpdate();
    }

    fullScreenEditExit() {
        this.props.app.focusFullScreenEdit = false;
        this.props.app.onViewerStateChangeCall();
    }

    zoomIn() {
        this.props.app.goto(this.props.mapNode.mapObject);

    }

    render() {

        if (null != this.state && this.state.forceReload) {
            setTimeout(() => this.setState({forceReload: false}), 1);
            return <div/>;
        }


        const displayRectangle = this.props.app.mapObjectToDisplayObject(this.props.mapNode.mapObject);
        const position = displayRectangle.position;
        const size = displayRectangle.size;

        const isMobile = this.props.app.io.isMobile();


        const isDesktopUncomfortable = this.props.app.isDesktopUncomfortable(this.props.mapNode.mapObject);

        let zoom = displayRectangle.zoom;
        const type: MapNodeType = this.props.mapNode.getTypeObject();
        let fullScreenToolbar = null;

        let mobileEditOverlay = null;

        const isFocus = this.props.app.focus === this.props.mapNode;


        let className = "app-node " + type.getCssClass();
        const mapNodeStyle = {};
        if (isMobile) {
            className += " map-node-mobile ";
        }




        const readOnly = !this.props.app.userService.hasWriteRights(this.props.mapNode);



        if (readOnly) {
            className += " map-node-readonly";
            // mapNodeStyle["borderColor"] = this.props.app.mapService.getUserColor(ModelUtil.entityIdToNumeric(this.props.mapNode.ownerId));
        }
        if (isFocus) {
            className += " focus";


            if (//(isMobile || isDesktopUncomfortable) &&
                this.props.app.isFocusFullScreenEdit()) {
                className += " focus-full-screen";
                fullScreenToolbar = <div className="map-node-full-screen-toolbar">
                    <i onClick={e => this.fullScreenEditExit()} className={"fa fa-times"}/>
                </div>;
                zoom = 1;
            }
        }

        if (this.props.mapNode.hidden) {

            className += " map-node-hidden ";
        }

        const onOverlayClick = () => {

            if (isMobile) {
                if (this.isFocused()) {
                    return;
                }
                if (type.isFullScreenEditable()
                    && !this.props.app.focusFullScreenEdit
                ) {
                    this.fullScreenEdit();
                } else {
                    this.zoomIn();
                }
            } else {
                if (isDesktopUncomfortable) {
                    this.fullScreenEdit();
                } else {
                    this.zoomIn();
                }

            }
        };

        // const onOverlayClick = ((isDesktopUncomfortable || isMobile) && type.isFullScreenEditable() && !this.props.app.focusFullScreenEdit)
        //     ? () => this.fullScreenEdit() : () => this.zoomIn();

        if ((isDesktopUncomfortable || isMobile) && type.isFullScreenEditable() && !this.props.app.focusFullScreenEdit) {
            let btn = null;
            if (isFocus) {
                btn = <button title="Edit" type="button"
                              onClick={e => this.fullScreenEdit()}
                              className="btn btn-dark btn-lg btn-floating app-ui"><i
                    className={readOnly ? "fas fa-search" : "fa " + (this.props.mapNode.isPasswordLocked() ? 'fa-lock' : 'fa-pen')}/>
                </button>;
            }
            mobileEditOverlay = <div onClick={e => onOverlayClick()} className="map-node-mobile-edit-overlay">
                {btn}
            </div>
        }


        if (this.props.app.isSelected(this.props.mapNode)) {
            className += " selected";
        }
        if (this.props.app.pendingOperations.get(this.props.mapNode, "saving")) {
            className += " app-map-node-saving";
        }

        // let time = this.props.mapNode.updateTime;
        // if (null == time || (null != this.props.mapNode.touchedTime && this.props.mapNode.touchedTime > this.props.mapNode.updateTime)) {
        //     time = this.props.mapNode.touchedTime;
        // }
        // if (null != time) {
        //
        //     className += " app-map-node-warm";
        // }


        let component = <div>No component</div>;
        let zoomIn = false;

        if (!this.props.app.isDisplayableContents(this.props.mapNode) && !this.props.app.focusFullScreenEdit) {
            const icon = this.props.mapNode.getTypeObject().getInfo().icon;
            zoomIn = true;
            const title = this.props.mapNode.title.trim().length > 0 ?
                <div className="app-node-component-zoom-in-title">{this.props.mapNode.title}</div> : null;


            component = <div className="app-node-component-zoom-in-icon">
                {title}
                <i onClick={e => onOverlayClick()} className={icon}/>
            </div>;
        } else if (this.props.mapNode.isPasswordLocked()) {
            className += " map-node-locked-up "
            component = <div className="app-node-password-locked-contents"
                             title="Password locked object, click on the lock to unlock">
                <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode}/>

                {(isDesktopUncomfortable || isMobile) && !this.props.app.isFocusFullScreenEdit() ?
                    <div className="password-protected-mobile-info">Password protected</div> :
                    <PasswordUnlockComponent onSubmit={password => this.passwordUnlock(password)}/>}
            </div>;
            // } else if (!this.props.app.isDisplayableContents(this.props.mapNode) && !this.props.app.focusFullScreenEdit) {
            //     const icon = this.props.mapNode.getTypeObject().getInfo().icon;
            //     zoomIn = true;
            //     const title = this.props.mapNode.title.trim().length > 0 ?
            //         <div className="app-node-component-zoom-in-title">{this.props.mapNode.title}</div> : null;
            //
            //     const onIconClick = (isMobile && type.isFullScreenEditable() && !this.props.app.focusFullScreenEdit)
            //         ? () => this.fullScreenEdit() : () => this.zoomIn();
            //     component = <div className="app-node-component-zoom-in-icon">
            //         {title}
            //         <i onClick={e => onIconClick()} className={icon}/>
            //     </div>;
        } else {
            switch (this.props.mapNode.type) {
                case MapNodeTypeEnum.NOTE:
                    component = <NoteComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
                case MapNodeTypeEnum.FLAG:
                    component = <FlagComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
                case MapNodeTypeEnum.TODO:
                    component = <TodoComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
                case MapNodeTypeEnum.WORMHOLE:
                    component = <WormholeComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
                case MapNodeTypeEnum.LOG:
                    component = <LogComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
                case MapNodeTypeEnum.FILES:
                    component = <FilesComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
                case MapNodeTypeEnum.NOTE_TXT:
                    component = <NoteTxtComponent app={this.props.app} mapNode={this.props.mapNode}/>
                    break;
            }
        }


        const zoomStyle = {};

        let zoomTransform = !this.props.app.io.isIOS();
        let zoomMargin = 30;

        // const zoomTransform = false;
        const simpleType = -1 !== [MapNodeTypeEnum.FLAG, MapNodeTypeEnum.WORMHOLE].indexOf(this.props.mapNode.type);

        if (MapNodeTypeEnum.TODO == this.props.mapNode.type) {
            zoomTransform = false;
            zoomMargin = 30;
            // zoomStyle["overflowX"] = "hidden";
        }

        if (!this.props.app.focusFullScreenEdit) {


            if (zoomTransform) {
                zoomStyle["zoom"] = zoom;
            } else {
                zoomStyle["transform"] = "scale(" + zoom + ")";
                if (!zoomIn) {
                    if (simpleType) {
                        zoomStyle["width"] = (size.x / zoom - zoomMargin) + "px";
                        zoomStyle["height"] = (size.y / zoom) + "px";
                    } else {
                        zoomStyle["width"] = (size.x / zoom - zoomMargin) + "px";
                        zoomStyle["height"] = (size.y / zoom - zoomMargin) + "px";
                    }

                    const mod = (zoom > 1) ? -1 : -1;
                    if (zoom < 1) {
                        if (simpleType) {
                            zoomStyle["marginRight"] = zoomStyle["marginLeft"] = ((size.x - (size.x / zoom)) / 2) + "px";
                            zoomStyle["marginTop"] = zoomStyle["marginBottom"] = ((size.y - (size.y / zoom)) / 2) + "px";
                        } else {
                            zoomStyle["marginRight"] = zoomStyle["marginLeft"] = mod * ((size.x - (size.x * zoom))) + "px";
                            zoomStyle["marginTop"] = zoomStyle["marginBottom"] = mod * ((size.y - (size.y * zoom))) + "px";
                        }
                    } else {
                        zoomStyle["marginRight"] = zoomStyle["marginLeft"] = ((size.x - (size.x / zoom)) / 2) + "px";
                        zoomStyle["marginTop"] = zoomStyle["marginBottom"] = ((size.y - (size.y / zoom)) / 2) + "px";
                    }

                } else {
                    zoomStyle["width"] = "100%";
                    zoomStyle["height"] = "100%";
                }


                // zoomStyle["marginLeft"] = (size.x - (size.x / zoom) / 2) + "px";
                // zoomStyle["marginTop"] = (size.y - (size.y / zoom) / 2) + "px";

                // wrapperStyle["width"] = (size.x * zoom) + "px";
                // wrapperStyle["height"] = (size.y * zoom - 30) + "px";
            }
        }


        // for(const k in {
        //     'top': Math.round(position.y) + "px",
        //     'left': Math.round(position.x) + "px",
        //     "width": Math.round(size.x) + "px",
        //     "height": Math.round(size.y) + "px",
        //
        // }) {
        //     mapNodeStyle[k] = Math.round(position.y) + "px"
        // }
        mapNodeStyle["top"] = Math.round(position.y) + "px";
        mapNodeStyle["left"] = Math.round(position.x) + "px";
        mapNodeStyle["width"] = Math.round(size.x) + "px";
        mapNodeStyle["height"] = Math.round(size.y) + "px";

        const color = type.getMapNodeColor(this.props.mapNode);
        if (null != color) {
            mapNodeStyle["backgroundColor"] = color;
        }

        return <div className={className}
                    data-id={this.props.mapNode.id}
                    style={mapNodeStyle}
        >
            {fullScreenToolbar}
            {mobileEditOverlay}
            {/*<div className="app-node-body-wrapper" style={wrapperStyle}>*/}
            <div
                // style={{"transform": `scale(${zoom})`}}
                style={zoomStyle}
                className={" " + (zoomIn ? "app-node-body-zoomed-out" : "app-node-body")}>

                {/*<div className="app-node-body-header">*/}

                {/*<input type="text" placeholder="Title.."*/}
                {/*       value={null != this.state.title? this.state.title : ""}*/}
                {/*       onChange={e => this.onChangeTitle(e)}*/}
                {/*       className="app-node-title app-ui" />*/}
                {/*<LoadingComponent/>*/}
                {/*</div>*/}
                {/*{this.props.app.mapObjectToDisplayObject(this.props.mapNode.mapObject).position.toString()}*/}
                {/*{this.props.app.mapObjectToDisplayObject(this.props.mapNode.mapObject).size.toString()}*/}

                {/*{this.props.mapNode.mapObject.position.toString()}*/}

                {component}


            </div>


            {/*</div>*/}
        </div>

    }
}
