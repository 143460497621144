import React from 'react';
import {Vector2} from "../map/Vector2";
import {AppController} from "../controllers/AppController";
import {DisplayArea} from "../lib/DisplayArea";




export enum MapActionType {
    SelectAreaPosition=1,
    SelectArea = 2
}

export class MapAction {
    constructor(public type: MapActionType,
                public params: any,
                public callback: (value: any)=>any) {

    }
}


export interface MapActionComponentProps {
    app: AppController
}

export interface MapActionComponentState {
    mousePosition: Vector2
}

export class MapActionComponent extends React.Component<MapActionComponentProps,MapActionComponentState> {



    constructor(props: MapActionComponentProps,state: MapActionComponentState) {
        super(props,state);
        switch(this.props.app.mapAction.type) {
            case MapActionType.SelectAreaPosition:

                this.state ={mousePosition: new Vector2(Math.round(window.innerWidth / 2),Math.round(window.innerHeight / 2))};
                break;
            case MapActionType.SelectArea:
                this.state ={mousePosition: null};
                break;
        }

    }

    getSelectAreaPositionComponent() {
        let mousePosition: Vector2 = this.state.mousePosition;

        const size: Vector2 = (this.props.app.mapAction.params as Vector2);
        const position = mousePosition.sum(size.multiply(-0.5));
        return <div className="map-action-selectAreaPosition"
            style={{
                "position": "absolute",
                width: size.x,height: size.y,
                transform:`translate(${position.x}px,${position.y}px)`}}
        >

        </div>
    }


    private _startPosition: Vector2 = null;

    getSelectAreaComponent() {
        const mousePosition: Vector2 = this.state.mousePosition;
        if (null == mousePosition) {
            return null;
        }
        if (null === this._startPosition) {
            this._startPosition = mousePosition;
        }

        const displayArea = DisplayArea.fromPositions(this._startPosition,mousePosition);

        const size = displayArea.size;
        const position = displayArea.position;
        return <div className="map-action-selectArea"
                    style={{
                        "position": "absolute",
                        width: size.x,height: size.y,
                        // marginTop: position.y,marginLeft: position.x,
                        transform:`translate(${position.x}px,${position.y}px)`}}
        >
            {/*<div className="debug">{position.toString()},{size.toString()}</div>*/}
        </div>



        return <div className="map-action-selectArea"/>
    }



    getActionComponent() {
        switch(this.props.app.mapAction.type) {
            case MapActionType.SelectAreaPosition:
                return this.getSelectAreaPositionComponent();
            case MapActionType.SelectArea:
                return this.getSelectAreaComponent();

        }
    }

    handleMouseMove(e) {

        this.setState({mousePosition: new Vector2(e.nativeEvent.offsetX,e.nativeEvent.offsetY)});
        this.forceUpdate();

    }
    handleMouseUp(e) {

        this.props.app.mapAction.callback(this.state.mousePosition);
    }

    onKeyUp(e) {
        if ("Enter" === e.key && MapActionType.SelectAreaPosition === this.props.app.mapAction.type) {
            this.props.app.mapAction.callback(this.state.mousePosition);
        }
    }

    private _onKeyUp;

    componentDidMount() {
        this._onKeyUp = e => this.onKeyUp(e);
        this.props.app.io.onKeyUp.push(this._onKeyUp);
    }

    componentWillUnmount() {
        this.props.app.io.onKeyUp.splice(this.props.app.io.onKeyUp.indexOf(this._onKeyUp),1);
    }

    render() {



        return <div className="map-action app-ui"
            onMouseMove={e => this.handleMouseMove(e)}
                    onMouseUp={e => this.handleMouseUp(e)}

        >
            {this.getActionComponent()}
        </div>
    }
}
