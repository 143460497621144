import React, {useState} from 'react';


import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";
import md5 from 'crypto-js/md5';

import './note.css';
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import {MapNodeToolsButton, MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";
import {CommentsComponent} from "../../ui/comments/CommentsComponent";
import {DateComponent} from "../../widgets/DateComponent";
import {InputUtil} from "../../../utils/InputUtil";
import {NoteTxtColorComponent} from "./NoteTxtColorComponent";
import {MapNodeNoteTxt} from "../../../node/MapNodeType";

export interface NoteTxtComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,

}

export interface NoteTxtComponentState {
    content: string,
    dataLoaded: boolean,
    contentState: string
}


export class NoteTxtComponent extends React.Component<NoteTxtComponentProps, NoteTxtComponentState> {

    constructor(props, state) {
        super(props, state);
        this.state = {
            content: this.getDefaultContent(),//props.note.note,
            dataLoaded: false,
            contentState: "",
        }
    }

    private getDefaultContent() {
        if (undefined != this.props.mapNode._customFields["defaultContent"]) {
            return this.props.mapNode._customFields["defaultContent"];
        }
        return "";
    }

    componentDidMount() {

        (async () => {


            // const data = await this.props.app.noteService.fetchNodeData(this.props.mapNode);
            let data = null;
            if (null === this.props.mapNode.mapNodeDatas) {
                await this.props.app.mapNodeDataService.fetchNodeData(this.props.mapNode);
                if (undefined != this.props.mapNode.mapNodeDatas[0]) {
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
            } else {
                if (undefined != this.props.mapNode.mapNodeDatas[0]) {
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
            }


            if (null != data && undefined != data.content) {

                this.setState({content: data.content});

            } else {

                this.setState({content: this.getDefaultContent()});
            }

            if (undefined === this.props.mapNode.mapNodeDatas[0]) {
                this.props.mapNode.mapNodeDatas = [
                    await this.props.app.mapNodeDataService.insertMapNodeData(this.props.mapNode, () => this.getData())
                ]
            }
            this.setState({dataLoaded: undefined !== this.props.mapNode.mapNodeDatas[0]});

        })();
    }

    private getData() {

        return {
            content: this.state.content
        };
    }

    componentDidUpdate() {

    }

    private firstLoad = true;


    onChange(e) {
        const changed = e.target.value != this.state.content;
        // console.log("onChange", changed);
        this.setState({content: e.target.value});
        if (changed) {

            (async () => {
                await this.props.app.mapNodeDataService.requestUpdateMapNodeData(this.props.mapNode.mapNodeDatas[0], () => this.getData());

            })();

        }
        this.firstLoad = false;

    }

    onKeyDown(e) {
        // console.log(e);
        if (-1 !== ["PageUp", "PageDown"].indexOf(e.key)) {
            e.preventDefault();
        }
        if (e.key == 'Tab') {
            e.preventDefault();
            var start = e.target.selectionStart;
            var end = e.target.selectionEnd;

            // set textarea value to: text before caret + tab + text after caret
            e.target.value = e.target.value.substring(0, start) +
                "\t" + e.target.value.substring(end);

            // put caret at right position again
            e.target.selectionStart =
                e.target.selectionEnd = start + 1;
        }

        InputUtil.onCtrlCSelectAll(e);
    }

    // public getLastEditDate() {
    //     if ( null == this.props.mapNode.mapNodeDatas || undefined == this.props.mapNode.mapNodeDatas[0]) {
    //         return null;
    //     }
    //     return this.props.mapNode.mapNodeDatas[0].updatedAt;
    // }

    private getTitle(): string {
        if (null == this.props.mapNode.mapNodeDatas || undefined == this.props.mapNode.mapNodeDatas[0]) {
            return "";
        }
        return this.props.mapNode.mapNodeDatas[0].getUpdateInfo();
    }

    private getLink() {
        const text = this.getData().content;
        if (null == text || 0 == text.length) {
            return null;
        }
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const resp = urlRegex.exec(text);
        if (null != resp && undefined !== resp[1]) {
            return resp[1];
        }
        return null;
    }


    private onColorSelect(index) {
        const options = this.props.mapNode.getOptionsObject();
        const colorIndex = options.get(0);
        if (colorIndex != index) {
            options.set(0, index);
            this.props.mapNode.setOptionsObject(options);
            this.props.app.mapNodeService.requestUpdateMapNode(this.props.mapNode);
            this.props.app.onViewerStateChangeCall();
        }
    }

    render() {


        let editor = <LoadingComponent/>;
        if (null !== this.state.content && this.state.dataLoaded) {
            editor = <div className="app-ui note-txt-contents"
                          title={this.getTitle()}
            >

                <textarea onChange={e => this.onChange(e)}
                          onKeyDown={e => this.onKeyDown(e)}
                          readOnly={!this.props.app.userService.hasWriteRights(this.props.mapNode)}
                          placeholder={"Note.."}
                          value={this.state.content}
                />

            </div>

        }
        // return editor;
        let buttons = [
            {
                component: <CommentsComponent key={"comments"} app={this.props.app}
                                              mapNode={this.props.mapNode}
                />
            },{
                component: <NoteTxtColorComponent key={"colors"} app={this.props.app} mapNode={this.props.mapNode}
                            onColorSelect={index => this.onColorSelect(index)}
                />            }];
        const link = this.getLink();
        if (null != link) {
            buttons.push({
                component: <a className={"app-ui"} title={link} key={"link"} href={link} target={"_blank"}><i className={"fa fa-link map-node-tool-button"}/> </a>
            });
        }

        return <div className="note-txt-component-container"
        >
            <MapNodeToolsComponent app={this.props.app}
                                   mapNode={this.props.mapNode}
                                   buttons={buttons}
            />
            {editor}
        </div>;
    }
}

