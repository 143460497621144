import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./app-status.css";

export interface AppStatusComponentProps {
    app: AppController
}

export interface AppStatusComponentState {
    pending: boolean
}

export class AppStatusComponent extends React.Component<AppStatusComponentProps, AppStatusComponentState> {


    constructor(props) {
        super(props);

        this.state = {pending: false};

    }


    componentDidMount() {
        this.props.app.pendingOperations.onChange.push(operations => {
            this.setState({pending: operations.length > 0});
        });
        const component = this;
        window.addEventListener('beforeunload', function (e) {
            if (component.state.pending && component.props.app.pendingOperations._pendingOperations.length > 0) {
                e.preventDefault();
                e.returnValue = 'wait';
            }

        });


    }



    render() {
        let pending = null;
        // if (this.state.pending) {
            pending = <LoadingComponent/>;
        // }
        return <span className={"app-status-component "+(this.state.pending?"pending":"")}>
            {pending}
        </span>
    }
}
