import React, {ElementRef, useState} from 'react';


import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";

import {TodoElement} from "./TodoElement";
import {MapNodeData} from "../../../node/MapNodeData";
import {DaemonUtils} from "../../../utils/DaemonUtils";
import {DomUtil} from "../../../utils/DomUtil";
import {InputUtil} from "../../../utils/InputUtil";


export interface TodoRowComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,
    todo: TodoElement,
    onEnter: (todo: TodoElement,e: any) => void,
    onRemove: (e: any) => void,
    onArrowUp: (todo: TodoElement) => void,
    onArrowDown: (todo: TodoElement) => void,
    onImportanceChange: (todo: TodoElement) => void,
    onStatusChange: (todo: TodoElement) => void,
    onPasteRows: (todo: TodoElement, rows: string[]) => void,
    onSortHandleMouseDown: (event: any, todo: TodoElement) => void


}

enum TodoImportance {
    STANDARD, WARNING, ERROR, QUESTION
}

enum TodoStatus {
    DEFAULT, COMPLETED
}


export interface TodoRowComponentState {
    todo: TodoElement

}


export class TodoRowComponent extends React.Component<TodoRowComponentProps, TodoRowComponentState> {

    private static _lastLocalId = null;

    inputRef: any;
    elementRef: any;

    constructor(props, state) {
        super(props, state);
        this.state = {
            todo: this.props.todo,
        }
        this.inputRef = React.createRef();
        this.elementRef = React.createRef();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }


    toggleCheck() {
        if (!this.props.app.userService.hasWriteRights(this.props.mapNode)) {
            return;
        }
        this.state.todo.status = (this.state.todo.status + 1) % 2;
        this.setState({todo: this.state.todo});
        const mapNodeData = this.getMapNodeData();
        if (null != mapNodeData) {
            this.props.app.mapNodeDataService.requestUpdateMapNodeData(mapNodeData, () => this.state.todo.toData());
        }
        this.props.onStatusChange(this.state.todo);
    }

    toggleImportance() {
        if (!this.props.app.userService.hasWriteRights(this.props.mapNode)) {
            return;
        }

        this.state.todo.importance = (this.state.todo.importance + 1) % 4;
        this.setState({todo: this.state.todo});
        const mapNodeData = this.getMapNodeData();
        if (null != mapNodeData) {
            this.props.app.mapNodeDataService.requestUpdateMapNodeData(mapNodeData, () => this.state.todo.toData());
        }
        this.props.onImportanceChange(this.state.todo);
    }


    getMapNodeData(): MapNodeData {
        return this.props.mapNode.mapNodeDatas.find(data => this.props.todo.id === data.id);
    }

    setValue(value: string) {
        this.state.todo.content = value;
        this.setState({todo: this.state.todo});

        DaemonUtils.waitFor(() => null != this.state.todo.id).then(() => {
            const mapNodeData = this.getMapNodeData();
            this.props.app.mapNodeDataService.requestUpdateMapNodeData(mapNodeData, () => this.state.todo.toData());
        });

    }

    onChange(e) {
        this.setValue(e.target.value)

        // this.setState({todo: this.state.todo});
    }

    remove() {

        const mapNodeData = this.getMapNodeData();
        if (null != mapNodeData) {
            this.props.app.mapNodeDataService.deleteMapNodeData(mapNodeData, this.props.mapNode);
        }

        this.props.onRemove(this.state.todo);
    }

    private _removing = false;

    onKeyUp(e) {

        switch (e.key) {
            case "Enter":
                e.preventDefault();
                this.props.onEnter(this.state.todo,e);
                break;
            case "Backspace":
                if (this._removing) {
                    if (0 === e.target.value.length) {
                        this.remove();
                    }
                }
                break;
            case "ArrowUp":
                if (!this.props.app.io.isKeyDown("Shift")) {
                    this.props.onArrowUp(this.state.todo);
                }
                break;
            case "ArrowDown":
                if (!this.props.app.io.isKeyDown("Shift")) {
                    this.props.onArrowDown(this.state.todo);
                }

                break;
        }

    }

    onKeyDown(e) {


        switch (e.key) {
            case "Enter":
                e.preventDefault();
                break;
            case "Backspace":
                if (0 === e.target.value.length) {
                    this._removing = true;
                }
                break;
            case "ArrowUp":
            case "ArrowDown":
                if (!this.props.app.io.isKeyDown("Shift")) {
                    e.preventDefault();
                }
                break;
        }
        InputUtil.onCtrlCSelectAll(e);

    }

    getImportanceClass() {
        switch (this.state.todo.importance) {
            case TodoImportance.STANDARD:
                return "todo-importance-standard";
            case TodoImportance.WARNING:
                return "todo-importance-warning";
            case TodoImportance.ERROR:
                return "todo-importance-error";
            case TodoImportance.QUESTION:
                return "todo-importance-question";
        }
    }

    getImportanceIcon() {
        switch (this.state.todo.importance) {
            case TodoImportance.STANDARD:
                return "fas fa-exclamation-circle text-muted";
            case TodoImportance.WARNING:
                return "fas fa-exclamation-circle text-warning";
            case TodoImportance.ERROR:
                // return "fas fa-radiation-alt text-danger";
                return "fas fa-skull-crossbones text-danger";
            case TodoImportance.QUESTION:
                // return "fas fa-radiation-alt text-danger";
                return "fas fa-question-circle text-secondary";

        }
    }

    onPaste(e) {
        e.preventDefault();
        const text = e.clipboardData.getData("Text");
        const lines: string[] = text.split("\n");

        if (1 == lines.length) {
            const newValueBefore = e.target.value.substring(0, e.target.selectionEnd);
            const newValueAfter = e.target.value.substring(e.target.selectionEnd);

            const newValue = newValueBefore + lines[0] + newValueAfter;

            this.setValue(newValue);
            setTimeout(() => {
                e.target.selectionEnd = newValueBefore.length + lines[0].length;
                this.forceUpdate();
            }, 10);
        } else {
            this.props.onPasteRows(this.props.todo, lines);
        }


    }

    private _title = null;

    private getTitle(): string {
        if (null == this._title && this.props.app.isOptimizing()) {
            return this._title;
        }
        if (null == this.getMapNodeData()) {
            return "";
        }
        this._title = this.getMapNodeData().getUpdateInfo();
        return this._title;
    }

    // private getInputWidth() {
    //     const scrollWidth = DomUtil.getRealScrollWidth(this.inputRef.current);
    //     if (scrollWidth > this.elementRef.current.clientWidth) {
    //         return scrollWidth;
    //     } else {
    //         return this.elementRef.current.clientWidth;
    //     }
    // }
    //
    // private getInputStyle() {
    //     const result = {};
    //     if (null != this.inputRef.current && null != this.elementRef.current) {
    //         result["width"] = this.getInputWidth() + "px";
    //     }
    //     return result;
    // }

    private _textareaStyle = null;

    private getTextareaStyle() {
        if (this.props.app.isOptimizing() && null !== this._textareaStyle) {
            return this._textareaStyle;
        }
        const result = {};
        if (null != this.inputRef.current) {
            let scrollHeight = DomUtil.getRealScrollHeight(this.inputRef.current);
            // let scrollHeight = this.inputRef.current.scrollHeight;

            if (scrollHeight < 30) {
                scrollHeight = 30;
            }
            result["height"] = scrollHeight + 3;
            this._textareaStyle = result;
        } else {
            setTimeout(() => {
                this.forceUpdate();
            }, 100);
        }
        return result;
    }

    onFocus(e) {
        const scroll = () => {
            if (undefined != e.target["scrollIntoViewIfNeeded"]) {
                e.target["scrollIntoViewIfNeeded"]();
            } else {
                e.target.scrollIntoView();
            }
        };
        const w: any = window;
        if (undefined === w["DISABLE_TODO_SCROLL"]) {
            scroll();
            // [50, 100, 200, 300, 500].forEach(time => setTimeout(() => scroll(), time))
        }
    }
    private getLink() {
        const text = this.state.todo.content;
        if (null == text || 0 == text.length) {
            return null;
        }
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const resp = urlRegex.exec(text);
        if (null != resp && undefined !== resp[1]) {
            return resp[1];
        }
        return null;
    }


    render() {

        const link = this.getLink();

        return <div
            className={"todo-row " + (TodoStatus.COMPLETED === this.state.todo.status ? "todo-row-checked" : "") + " " + this.getImportanceClass()}
            title={this.getTitle()}

            id={this.props.todo.getLocalId()}>
            <i onMouseDown={event => this.props.onSortHandleMouseDown(event, this.props.todo)}
               onTouchStart={event => this.props.onSortHandleMouseDown(event, this.props.todo)}
               className="todo-row-element-handle app-ui-no-center-map fas fa-grip-vertical app-ui"/>
            <i onClick={e => this.toggleCheck()}

               className={"todo-row-checkbox app-ui app-ui-no-center-map " + (TodoStatus.COMPLETED === this.state.todo.status ? "far fa-check-square" : "far fa-square")}/>
            {/*<div className="todo-row-input-container" ref={this.elementRef}>*/}
            {/*<input type="text" className={"todo-row-input app-ui"} value={this.state.todo.content}*/}
            {/*       // style={this.getInputStyle()}*/}
            {/*         */}
            {/*       onChange={e => this.onChange(e)}*/}
            {/*       ref={this.inputRef}*/}
            {/*       onKeyUp={e => this.onKeyUp(e)}*/}
            {/*       onKeyDown={e => this.onKeyDown(e)}*/}
            {/*       onPaste={e => this.onPaste(e)}*/}
            {/*       readOnly={!this.props.app.userService.hasWriteRights(this.props.mapNode)}*/}


            {/*/>*/}
            {/*{this.state.todo.position}*/}
            <textarea className={"todo-row-input app-ui app-no-delete-object-on-key-down"}
                      value={this.state.todo.content}
                      placeholder="Type here.."
                      style={this.getTextareaStyle()}
                      rows={1}
                      onChange={e => this.onChange(e)}
                      onFocus={e => this.onFocus(e)}
                      ref={this.inputRef}
                      onKeyUp={e => this.onKeyUp(e)}
                      onKeyDown={e => this.onKeyDown(e)}
                      onPaste={e => this.onPaste(e)}
                      readOnly={!this.props.app.userService.hasWriteRights(this.props.mapNode)}


            />


            {/*</div>*/}
            {null != link ? <a className={"app-ui app-ui-no-center-map"} title={link} href={link} key={"link"}  target={"_blank"}><i className={"fa fa-link todo-row-link"}/> </a> : null}
            <i onClick={e => this.toggleImportance()}
               className={"app-ui app-ui-no-center-map todo-row-importance-icon " + this.getImportanceIcon()}/>

        </div>
    }
}
