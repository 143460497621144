import React from 'react';


import {AppController} from "../../controllers/AppController";
import {Vector2} from "../../map/Vector2";
import {MapAction, MapActionType} from "../MapActionComponent";
import {
    MapNodeFiles,
    MapNodeFlag, MapNodeLog,
    MapNodeNote,
    MapNodeNoteTxt,
    MapNodeTodo,
    MapNodeType,
    MapNodeWormhole
} from "../../node/MapNodeType";
import {DaemonUtils} from "../../utils/DaemonUtils";
import {PasswordLockComponent} from "../ui/password-lock/PasswordLockComponent";
import {FeedbackComponent} from "../ui/feedback/FeedbackComponent";
import {TodoElement, TodoImportance} from "../nodes/todo/TodoElement";
import {MapNode} from "../../node/MapNode";
import {DisplayArea} from "../../lib/DisplayArea";
import {MapArea} from "../../map/MapArea";
import {MapObject} from "../../map/MapObject";


export interface BottomToolsComponentProps {
    app: AppController

}

export interface BottomToolsComponentState {
    opened: boolean,
    passwordLockPrompt,
    feedbackDialog: boolean

}

export class BottomToolsButton {
    constructor(public title: string, public icon: string, public onClick: () => void, public className: string = null, public additionalIcon: string = null) {

    }

    getClassName(): string {
        if (null == this.className) {
            return "btn btn-dark btn-lg btn-floating add-map-object-button";
        } else {
            return this.className;
        }
    }

}

export class BottomToolsComponent extends React.Component<BottomToolsComponentProps, BottomToolsComponentState> {

    public static instance: BottomToolsComponent;


    constructor(props: BottomToolsComponentProps) {
        super(props);
        this.state = {opened: false, passwordLockPrompt: false, feedbackDialog: false};
        // window["addHelloWorldNote"] = () => this.addHelloWorldNote();
    }


    private findPosition(type: MapNodeType) {
        let position = this.props.app.viewerState.position;
        const newNodeArea = new DisplayArea(this.props.app.viewerState.position, type.getDefaultSize());
        const nodes = this.props.app.mapNodeService.getNodesLoaded();
        const areas = nodes.map(node => {
            // return node.mapObject.getArea();
            const displayObject = this.props.app.mapObjectToDisplayObject(node.mapObject);
            return displayObject.getArea();
        });
        const getColliding = (vector: Vector2) => {
            const newArea = this.props.app.mapObjectToDisplayObject(new MapObject(vector, type.getDefaultSize(),this.props.app.viewerState.level)).getArea();
            // const newArea = new MapArea(vector, type.getDefaultSize());
            for (const index in areas) {
                const area = areas[index];
                if (newArea.isColliding(area)) {
                    return nodes[index];
                }
            }
            return null;
        };
        const collding = getColliding(position);
        if (null != collding) {
            position = collding.mapObject.position;
        }

        const vectors = [
            {vector: new Vector2(0, 10), distance: 0,result: position},
            {vector: new Vector2(0, -10), distance: 0,result: position},
            {vector: new Vector2(10, 0), distance: 0,result: position},
            {vector: new Vector2(-10, 0), distance: 0,result: position},
            // {vector: new Vector2(10, 10), distance: 0,result: position},
            // {vector: new Vector2(-10, -10), distance: 0,result: position},
            // {vector: new Vector2(10, 10), distance: 0,result: position},
            // {vector: new Vector2(-10, -10), distance: 0,result: position},
        ];

        for(const vector of vectors) {
            while(vector.distance < 2000 && null != getColliding(vector.result)) {
                vector.result = vector.result.sum(vector.vector);
                vector.distance += 10;
            }
        }
        vectors.sort((a,b) => a.distance - b.distance);
        // console.log("position winner",vectors,areas,newNodeArea);
        return vectors[0].result;
    }


    addNode(type: MapNodeType) {

        if (this.props.app.io.isTouch()) {
            const position = this.findPosition(type);
            this.props.app.viewerState.position = position;
            this.props.app.mapNodeService.addNode(type, position, this.props.app.viewerState.level).then(note => {

                note.recentlyAdded = new Date().getTime();
                this.props.app.setFocus(note, true);
                this.props.app.focusFullScreenEdit = true;

                this.props.app.onViewerStateChangeCall();

            });
        } else {
            this.props.app.setMapAction(new MapAction(MapActionType.SelectAreaPosition, type.getDefaultSize(), position => {
                if (null == position) {
                    return;
                }
                const mapPosition = this.props.app.canvasPositionToMapPosition(position);
                this.props.app.mapNodeService.addNode(type, mapPosition, this.props.app.viewerState.level).then(note => {
                    note.recentlyAdded = new Date().getTime();
                    this.props.app.setFocus(note, true);
                    this.props.app.onViewerStateChangeCall();
                });
            }));
        }


    }

    addHelloWorldNote() {

        let layout = [this.props.app.viewerState.position.sum(new Vector2(-220, 0)),
            this.props.app.viewerState.position.sum(new Vector2(0, -240)),
            this.props.app.viewerState.position.sum(new Vector2(220, 0))
        ];
        if (this.props.app.io.isMobile()) {
            layout = [this.props.app.viewerState.position.sum(new Vector2(0, 0)),
                this.props.app.viewerState.position.sum(new Vector2(0, -240)),
                this.props.app.viewerState.position.sum(new Vector2(0, 260))
            ];
        }

        this.props.app.mapNodeService.addNode(new MapNodeNoteTxt(), layout[0], this.props.app.viewerState.level).then(note => {
            note.title = "Welcome to DragoNote!";
            const helloWorldContent = `💠 To move around the map, drag or scroll the background area using your mouse / touchpad / arrow keys.

🔎 Use ctrl+scroll / touchpad pinch to zoom. You can also use the buttons "+" and "-" on the bottom-left corner.

🚀 To add an object, use the buttons on the bottom-right corner.

🌐 Change the map using the globe button, close to the mini-map.

To get more information visit https://info.dragonote.com/`;
            note._customFields["defaultContent"] = helloWorldContent;
            this.props.app.onViewerStateChangeCall();
            this.props.app.mapNodeService.requestUpdateMapNode(note);
            DaemonUtils.waitFor(() => null != note.mapNodeDatas && undefined !== note.mapNodeDatas[0] && null != note.mapNodeDatas[0].id).then(() => {
                this.props.app.mapNodeDataService.requestUpdateMapNodeData(note.mapNodeDatas[0], () => {
                    return {
                        "content": helloWorldContent
                    }
                })
            })

        });

        // let i = 0;
        // const effect = flag => {
        //     const icons = [1326,1325];
        //     const icon = icons[++i % 2];
        //     console.log("effect",i,icon);
        //     const options = flag.getOptionsObject();
        //     options.set(0,icon);
        //     // options.set(1,"#f0690f");
        //     flag.setOptionsObject(options);
        //     this.props.app.onViewerStateChangeCall();
        //     this.props.app.mapNodeService.requestUpdateMapNode(flag);
        //
        //     if (i < 10) {
        //         setTimeout(() => effect(flag),1000);
        //     }
        // };

        this.props.app.mapNodeService.addNode(new MapNodeFlag(), layout[1], this.props.app.viewerState.level).then(flag => {
            flag.title = "Welcome!";
            const options = flag.getOptionsObject();
            // switch(Math.floor(3*Math.random())) {
            //     case 0:
            //         options.set(0,1325);
            //         options.set(1,"#f0690f");
            //         break;
            //     case 1:
            //         options.set(0,462);
            //         options.set(1,"#458c26");
            //         break;
            //         case 2:
            //         options.set(0,205);
            //         options.set(1,"#a96e0a");
            //         break;
            // }
            // options.set(0,1268);
            const set = () => {
                options.set(0, 1394);
                options.set(1, "#260d03");
                flag.setOptionsObject(options);
                this.props.app.onViewerStateChangeCall();
                this.props.app.mapNodeService.requestUpdateMapNode(flag);
            };
            set();
            setTimeout(() => set(), 1000);
            // effect(flag);


            DaemonUtils.waitFor(() => null != flag.mapNodeDatas && undefined !== flag.mapNodeDatas[0] && null != flag.mapNodeDatas[0].id).then(() => {
                set();
            })

        });


        this.props.app.mapNodeService.addNode(new MapNodeTodo(), layout[2], this.props.app.viewerState.level).then(todo => {
            todo.title = "The tutorial";

            todo._customFields["defaultTodos"] = [
                new TodoElement("Click on the flag above, change the icon", TodoImportance.QUESTION),
                new TodoElement("Add text note", TodoImportance.WARNING),
                new TodoElement("Add todo list", TodoImportance.STANDARD),
                new TodoElement("Add two wormholes with the same name", TodoImportance.STANDARD),
                new TodoElement("Delete this object", TodoImportance.ERROR),
            ];
            console.log("added todo", todo);

            this.props.app.onViewerStateChangeCall();
            this.props.app.mapNodeService.requestUpdateMapNode(todo);


        });


    }

    private _onKeyDown;
    private _onKeyUp;

    componentDidMount() {
        let w: any = window;
        w["bottom"] = this;
        DaemonUtils.waitFor(() => this.props.app.mapNodeService.isLoaded()
            && null !== this.props.app.mapService.getMapsLoaded())
            .then(() => {

                if (this.props.app.mapNodeService.getNodesLoaded().length == 0
                    && "General" == this.props.app.currentMap.title
                    && this.props.app.mapService.getMapsLoaded().length == 1
                    && null != this.props.app.currentMap.createdAt
                    && new Date().getTime() - new Date(this.props.app.currentMap.createdAt).getTime() < 10000
                ) {
                    console.log("ADD HELLO WORLD");
                    this.addHelloWorldNote();

                }
            });

        this._onKeyDown = e => this.handleKeyDown(e);
        this._onKeyUp = e => this.handleKeyUp(e);

        this.props.app.io.onKeyDown.push(this._onKeyDown);
        this.props.app.io.onKeyUp.push(this._onKeyUp);

    }

    componentWillUnmount() {
        this.props.app.io.onKeyDown.splice(this.props.app.io.onKeyDown.indexOf(this._onKeyDown), 1);
        this.props.app.io.onKeyUp.splice(this.props.app.io.onKeyUp.indexOf(this._onKeyUp), 1);
    }

    private addNoteKeyBindings = ["Q", "W", null, "E", "R", "T", "Y"]

    private handleKeyDown(e) {
        if (e.ctrlKey || e.metaKey) {
            let zoom = 0;
            switch (e.code) {
                case "Equal":
                    zoom = 1;
                    break;
                case "Minus":
                    zoom = -1;
                    break;
            }
            if (zoom != 0) {
                e.preventDefault();
                this.props.app.moveLevel(zoom);
                return;
            }

        }
        switch (e.key) {
            case "Delete":


                const deleting = this.props.app.selected.length > 0
                    && (!e.target.classList.contains("app-no-delete-object-on-key-down"))
                    && (-1 === ["input", "textarea"].indexOf(e.target.tagName.toLowerCase())
                        || e.target.value.length == e.target.selectionEnd && e.target.value.length == e.target.selectionStart);
                if (deleting) {
                    this.deleteSelected();
                }
                break;
            default:
                break;
        }
    }


    private handleKeyUp(e) {
        if (null != this.props.app.mapAction) {
            return;
        }

        if (e.target.tagName.toLowerCase() == "input" || e.target.tagName.toLowerCase() == "textarea") {
            return;
        }
        if (e.ctrlKey || e.metaKey || e.altKey) {
            return;
        }

        if (/^Key\w$/i.test(e.code)) {
            const key = e.code.replace(/^Key/i, "").toUpperCase();
            const index = this.addNoteKeyBindings.indexOf(key);
            if (-1 !== index) {
                this.addNode(MapNodeType.getAllTypes()[index]);
            }
        }
    }


    // getAddMapWidgetButton(icon, onClick: () => void) {
    //     // return <button type="button" onClick={()=>onClick()} className="btn btn-primary btn-lg btn-floating add-map-object-button">
    //     //     <i className={icon}></i>
    //     // </button>
    //     return <button type="button" onClick={() => onClick()}
    //                    className="btn btn-default btn-lg btn-floating add-map-object-button">
    //         <i className={icon}></i>
    //         <i className="fa fa-plus-circle"/>
    //     </button>
    // }

    deleteSelected() {

        (async () => {
            const selected = this.props.app.getSelected();

            const confirmed = await this.props.app.confirm(`Are you sure you want to delete selected (${selected.length}) objects?`, "danger");

            if (!confirmed) {
                return;
            }

            Promise.all(
                selected.map(mapNode => {
                    this.props.app.mapNodeService.deleteNode(mapNode);
                    this.props.app.focusHistory.remove(mapNode);
                })
            ).then(() => {
                // this.props.app.deselectAll();
                // this.props.app.onViewerStateChangeCall();
            })
            setTimeout(() => {
                this.props.app.deselectAll();
            }, 100);
        })();


    }

    toggleHideSelected() {
        let deselect = false;
        this.props.app.getSelected().forEach(el => {
            el.hidden = !el.hidden;
            if (el.hidden && !this.props.app.showHidden) {
                deselect = true;
            }
            this.props.app.mapNodeService.requestUpdateMapNode(el);
        });

        if (deselect) {
            this.props.app.deselectAll();
        }

        this.props.app.onViewerStateChangeCall();

    }

    copySelected() {

    }

    moveLevelSelected(move: number) {

        const selected = this.props.app.getSelected();
        const oldArea = this.props.app.getSelectedDisplayArea();
        // const oldMapArea = this.props.app.getSelectedMapArea();
        // let mod = 1;
        // if (move < 0) {
        //     mod = 2;
        // } else {
        //     mod = 0.5;
        // }
        // const mv = oldArea.size.multiply(mod * 0.5).divide(this.props.app.getZoom());
        const fromCenterMap = {};
        const oldZooms = {};
        const newZooms = {};
        selected.forEach(mapNode => {
            const display = this.props.app.mapObjectToDisplayObject(mapNode.mapObject);
            const fromCenter = display.getArea().center().minus(oldArea.center());
            fromCenterMap[mapNode.id] = fromCenter.dist();
            const oldZoom = oldZooms[mapNode.id] = this.props.app.levelToZoom(mapNode.mapObject.level);
            mapNode.mapObject.level += move;
            const newZoom = newZooms[mapNode.id] = this.props.app.levelToZoom(mapNode.mapObject.level);
            // let mod = newZoom / oldZoom;
            // if (oldZoom < newZoom) {
            //     // mod = 1 / mod;
            // }

            // mod = mod * this.props.app.getZoom();
            // let movePositionFromCenter = fromCenter.multiply(mod).minus(fromCenter);
            // if (newZoom < oldZoom) {
            //     // movePositionFromCenter = fromCenter.minus(fromCenter.multiply(mod));
            // }
            // console.log(movePositionFromCenter,mod,newZoom,oldZoom,this.props.app.getZoom());
            mapNode.mapObject.position = mapNode.mapObject.position
                .multiply(newZoom / oldZoom)
            // .sum(movePositionFromCenter)
            ;
            // this.props.app.mapNodeService.requestUpdateMapNode(mapNode);


        });
        if (selected.length > 1) {
            const newArea = this.props.app.getSelectedDisplayArea();
            // const mod = newArea.position.minus(oldArea.position);
            // console.log(mod);
            const centerMoved = newArea.center().minus(oldArea.center());
            console.log("centerMoved", centerMoved);
            selected.forEach(mapNode => {
                const display = this.props.app.mapObjectToDisplayObject(mapNode.mapObject);
                const newFromCenter = display.getArea().center().minus(newArea.center());
                const newProp = newFromCenter.dist() / newArea.size.dist();
                const oldProp = newFromCenter.dist() / oldArea.size.dist();
                const targetFromCenter = newFromCenter.multiply(oldProp / newProp);
                let moveToTarget = targetFromCenter.minus(newFromCenter);
                // if (newArea.size.dist() > oldArea.size.dist()) {
                //     moveToTarget = newFromCenter.minus(targetFromCenter);
                // }
                const moveMap = moveToTarget.multiply(2).divide(this.props.app.getDisplayZoom(mapNode.mapObject.level));
                const centerMovedMap = centerMoved.divide(this.props.app.getDisplayZoom(mapNode.mapObject.level))
                console.log(moveMap);
                mapNode.mapObject.position = mapNode.mapObject.position.sum(moveMap)
                // .minus(centerMovedMap.multiply(1))
                ;
                this.props.app.mapNodeService.requestUpdateMapNode(mapNode);

            });
        } else {
            this.props.app.mapNodeService.requestUpdateMapNode(selected[0]);

        }


        this.props.app.onViewerStateChangeCall();
    }

    setPasswordSelected() {
        this.setState({passwordLockPrompt: true});

    }


    getButtons(): BottomToolsButton[] {
        const result = [];

        const toolsOpenClassName = "btn btn-dark btn-lg btn-floating add-map-object-button tools-bottom-toggle-button";


        if (null != this.props.app.mapAction) {
            result.push(new BottomToolsButton("Cancel", "fa fa-times", () => this.props.app.setMapAction(null)));
        } else if (this.props.app.getSelected().length == 0) {

            if (!this.props.app.userService.getLoggedLoaded().isRatingSent()) {
                result.push(new BottomToolsButton("Feedback", "🤩", () => this.setState({feedbackDialog: true})));
            }
            MapNodeType.getAllTypes().forEach((type, index) => {
                if (!(type instanceof MapNodeNote)) {
                    result.push(new BottomToolsButton(type.getInfo().title + " (" + this.addNoteKeyBindings[index] + ")", type.getInfo().icon, () => this.addNode(type),
                        "btn btn-dark btn-lg btn-floating add-map-object-button btn-bottom-tools-toggle"
                        , "fa fa-plus-circle"));
                }

            });


            result.push(new BottomToolsButton("Add", this.state.opened ? "fas fa-angle-double-down" : "fa fa-plus", () => this.setState({opened: !this.state.opened}), toolsOpenClassName + " tools-bottom-toggle-add"));


            // result.push(new BottomToolsButton("Flag","far fa-flag", () => this.addNode(new MapNodeFlag()), null, "fa fa-plus-circle"));
            // result.push(new BottomToolsButton("Note","far fa-sticky-note", () => this.addNode(new MapNodeNote()), null, "fa fa-plus-circle "));
            // result.push(new BottomToolsButton("Todo","fas fa-clipboard-check", () => this.addNode(new MapNodeTodo()), null, "fa fa-plus-circle"));
            // result.push(new BottomToolsButton("Wormhole","far fa-circle", () => this.addNode(new MapNodeWormhole()), null, "fa fa-plus-circle"));

            // result.push(new BottomToolsButton(MapNodeWormhole.IMAGE, () => this.addNode(new MapNodeWormhole()), "btn btn-light btn-lg btn-floating add-map-object-button add-map-object-img-button","fa fa-plus-circle"));

            // result.push(new BottomToolsButton("Log","fas fa-book", () => this.addNode(new MapNodeLog()), null, "fa fa-plus-circle"));
            // result.push(new BottomToolsButton("File list","fas fa-file-upload", () => this.addNode(new MapNodeFiles()), null, "fa fa-plus-circle"));
        } else {
            // result.push(new BottomToolsButton("fa fa-search-plus", () => this.copySelected()));
            // result.push(new BottomToolsButton("fa fa-search-minus", () => this.copySelected()));

            const selected = this.props.app.getSelected();
            const readOnly = selected.find(el => !this.props.app.userService.hasWriteRights(el));


            if (!readOnly) {

                let hiddenStatus = null;
                for (const el of selected) {
                    if (null === hiddenStatus) {
                        hiddenStatus = el.hidden;
                    } else if (hiddenStatus != el.hidden) {
                        hiddenStatus = null;
                        break;
                    }
                }

                result.push(new BottomToolsButton("Make bigger", "fa fa-sort-amount-up", () => this.moveLevelSelected(-1)));
                result.push(new BottomToolsButton("Make smaller", "fa fa-sort-amount-down", () => this.moveLevelSelected(1)));
                // result.push(new BottomToolsButton("Copy selected","fa fa-copy", () => this.copySelected()));
                result.push(new BottomToolsButton("Delete", "fa fa-trash", () => this.deleteSelected(), "btn btn-danger btn-lg btn-floating add-map-object-button"));

                if (null !== hiddenStatus) {
                    if (hiddenStatus) {
                        result.push(new BottomToolsButton("Resurrect the ghost", "fas fa-skull", () => this.toggleHideSelected(), "btn btn-light text-success btn-lg btn-floating add-map-object-button"));
                    } else {
                        result.push(new BottomToolsButton("Make a ghost", "fas fa-skull", () => this.toggleHideSelected(), "btn btn-secondary btn-lg btn-floating add-map-object-button"));
                    }
                }


                if (this.props.app.getSelected().every(el => el.isPasswordLockAvailable())) {
                    result.push(new BottomToolsButton("Encrypt with password", "fa fa-lock", () => this.setPasswordSelected()));

                }
            }


            result.push(new BottomToolsButton("Deselect", "fa fa-times", () => this.props.app.deselectAll()));

            result.push(new BottomToolsButton("Options",
                this.state.opened ? "fas fa-angle-double-down" : "fas fa-ellipsis-v", () => this.setState({opened: !this.state.opened}), toolsOpenClassName + " tools-bottom-toggle-options"));


        }


        return result;
    }


    getIcon(button: BottomToolsButton) {
        if (/^\//.test(button.icon)) {
            return <img className="img-icon" src={button.icon}/>
        } else {
            if (!/fa/.test(button.icon)) {
                return <span>{button.icon}</span>
            } else {
                return <i className={button.icon}/>
            }
        }
    }


    render() {

        if (this.state.passwordLockPrompt) {
            return <PasswordLockComponent app={this.props.app}
                                          onClose={() => this.setState({passwordLockPrompt: false})}/>;
        }
        if (this.state.feedbackDialog) {
            return <FeedbackComponent app={this.props.app} onClose={() => this.setState({feedbackDialog: false})}/>;
        }

        const offset = this.props.app.getSelected().length > 0 ? 100 : 0;
        const buttons = this.getButtons().map((button, i) => {

            let additionalIcon = null;

            if (null != button.additionalIcon) {
                additionalIcon = <i className={"additional-icon " + button.additionalIcon}/>;
            }
            return <button key={i + offset} data-tooltip={button.title}

                           type="button" onClick={() => button.onClick()} className={button.getClassName()}>
                {this.getIcon(button)}
                {additionalIcon}
            </button>
        });


        return <div className={"tools-bottom-right" + (this.state.opened ? ' tools-bottom-right-opened' : '')}>

            {buttons}

        </div>
    }
}
