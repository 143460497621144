import React from 'react';
import {Vector2} from "../map/Vector2";
import {MapObject} from "../map/MapObject";
import {MiniMapComponent} from "./MiniMapComponent";
import {AppController} from "../controllers/AppController";
import {MapAction, MapActionType} from "./MapActionComponent";
import {BottomToolsComponent} from "./tools/BottomToolsComponent";
import {Model} from "../model/Model";
import {AppStatusComponent} from "./ui/status/AppStatusComponent";
import {AppSearchComponent} from "./ui/search/AppSearchComponent";
import {FocusHistoryComponent} from "./ui/focus-history/FocusHistoryComponent";
import {MapChooseComponent} from "./ui/map-choose/MapChooseComponent";
import {DomUtil} from "../utils/DomUtil";
import {ShareComponent} from "./ui/share/ShareComponent";
import {TodoIconComponent} from "./ui/todo-icon/TodoIconComponent";
import {UserNotificationsComponent} from "./ui/user-notifications/UserNotificationsComponent";
import {User} from "../node/User";
import {UserIconComponent} from "./ui/user-icon/UserIconComponent";
import {ModalComponent} from "./widgets/modal/ModalComponent";
import {AboutComponent} from "./ui/about/AboutComponent";
import {OutsideClickComponent} from "./widgets/outside-click/OutsideClickComponent";
import {FeedbackComponent} from "./ui/feedback/FeedbackComponent";
import {ConfirmAccountComponent} from "./ui/confirm-account/ConfirmAccountComponent";



export interface MainToolsComponentProps {
    app: AppController

}
export interface MainToolsComponentState {
    logged: User,
    userToolbarOpen: boolean,
    miniMapOpen: boolean,
    aboutOpen: boolean,
    confirmAccountOpen: boolean

}

export class MainToolsComponent extends React.Component<MainToolsComponentProps,MainToolsComponentState> {



    constructor(props: MainToolsComponentProps) {
        super(props);
        this.state = {logged: null,userToolbarOpen: false,miniMapOpen: !this.props.app.io.isMobile(),aboutOpen: false,confirmAccountOpen: false};

    }

    componentDidMount() {
        // const me = () => {
        this.props.app.userService.getLogged().then(user => {
            this.setState({logged: user,confirmAccountOpen: 1 == user.status});

        });
            // Model.rest("me").then(resp => resp.json().then(data => {
            //     if (undefined != data.email && data.email !== this.state.logged) {
            //         this.setState({logged: data.email});
            //     }
            //     // setTimeout(()=>me(),60000);
            // },err => {
            //     // this.setState({logged: null});
            //     // this.logOut();
            //
            // }));
        // };
        // me();

    }

    // addNote() {
    //
    //     const noteSize = new Vector2(400,237);
    //     this.props.app.setMapAction(new MapAction(MapActionType.SelectAreaPosition,noteSize,position => {
    //         const mapPosition = this.props.app.canvasPositionToMapPosition(position);
    //         this.props.app.noteService.addNode(mapPosition,this.props.app.viewerState.level).then(note => {
    //             this.props.app.onViewerStateChangeCall();
    //         });
    //     }));
    // }
    //
    // getAddMapWidgetButton(icon,onClick: () => void) {
    //     // return <button type="button" onClick={()=>onClick()} className="btn btn-primary btn-lg btn-floating add-map-object-button">
    //     //     <i className={icon}></i>
    //     // </button>
    //     return <button type="button" onClick={()=>onClick()} className="btn btn-default btn-lg btn-floating add-map-object-button">
    //         <i className={icon}></i>
    //         <i className="fa fa-plus-circle"/>
    //     </button>
    // }


    logOut() {
        Model.logOut();
        window.location.reload();
    }

    // getLoggedInitials() {
    //     if (null != this.state.logged) {
    //         const name = this.props.app.mapService.getUserName(this.state.logged.id);
    //         if (name.length > 0) {
    //             return name[0];
    //         } else {
    //             return "-";
    //         }
    //     } else {
    //         return null;
    //     }
    // }

    getLoggedColor() {
        if (null != this.state.logged) {
            return this.props.app.mapService.getUserColor(this.state.logged.id);
        } else {
            return null;
        }
    }

    getZoomInfo() {
        let zoom: any = this.props.app.viewerState.level;
        if (zoom != 0) {
            if (zoom > 0) {
                zoom = "+" + zoom;
            }
        }
            return <button
                           data-tooltip="Reset zoom (Ctrl 0)"
                           data-tooltip-position="right"
                           onClick={()=>this.props.app.setLevel(0)} disabled={0 == zoom}
                           className="btn btn-floating btn-dark zoom-out ripple-surface">{zoom}</button>

        // } else {
        //     return null;
        // }
    }

    openAbout() {
        this.setState({aboutOpen: true})
    }

    render() {

        return <div className="main-tools app-ui app-interface">

            {this.state.confirmAccountOpen ? <ModalComponent title={"Email confirmation"} onClose={() => this.setState({confirmAccountOpen: false})}>
                <ConfirmAccountComponent app={this.props.app} onClose={() => this.setState({confirmAccountOpen: false})} />
            </ModalComponent> : null}

            {this.state.aboutOpen?<ModalComponent title={"DragoNote - Quick whiteboard notes"} onClose={() => this.setState({aboutOpen: false})}>
                <AboutComponent />
            </ModalComponent>:null}
            <div className="top-tools row">
                <div className="col-lg-3 col-md-2 d-none d-md-block">
                    
                </div>
                <div className="col-lg-6 col-md-6 col-4 col-sm-7">
                    <AppSearchComponent app={this.props.app} />
                </div>
                <div className="col-lg-3 col-md-4 col-8 col-sm-5 top-tools-right text-right">



                    {1 == this.props.app.userService.getLoggedLoaded().status ?
                        <button onClick={() => this.setState({confirmAccountOpen: true})} type="button" className="btn btn-danger ripple-surface confirm-account-button">Confirm your email</button>
                        : <span>


                            {this.props.app.hasAnyHidden() ? <button type="button" className={'btn  btn-floating dropdown-toggle ripple-surface main-tools-toggle-show-hidden ' + (!this.props.app.showHidden ? 'btn-secondary' : 'btn-light text-secondary')}
                                // data-mdb-toggle="dropdown"
                                                                     data-tooltip={this.props.app.showHidden ? 'Hide ghosts' : 'Show ghosts' }
                                                                     data-tooltip-position="bottom"
                                                                     data-tooltip-click-keep="1"
                                                                     aria-expanded="false"
                                                                     onClick={e => this.props.app.toggleShowHidden()}
                            >
                                <i className={'fas fa-ghost'} />
                            </button> : null}
                            <TodoIconComponent app={this.props.app} />


                    <ShareComponent app={this.props.app} />
                    <UserNotificationsComponent app={this.props.app} />
                    <AppStatusComponent app={this.props.app} />



                        </span>}




                    {null != this.state.logged? <div className="btn-group">
                        {/*<button type="button" className="btn btn-dark btn-floating dropdown-toggle ripple-surface"*/}
                        {/*        // data-mdb-toggle="dropdown"*/}
                        {/*        aria-expanded="false"*/}
                        {/*        onClick={e => this.setState({userToolbarOpen: !this.state.userToolbarOpen})}*/}
                        {/*>*/}
                        {/*    */}
                        {/*</button>*/}
                        <span className="user-toolbar-button" onClick={e => this.setState({userToolbarOpen: !this.state.userToolbarOpen})}>
                        <UserIconComponent onButtonClick={() => {}} app={this.props.app} userId={this.state.logged.id} />
                    </span>
                        {this.state.userToolbarOpen?
                            <OutsideClickComponent
                                ignoreClass="user-toolbar-button"
                                onOutsideClick={() => this.setState({"userToolbarOpen": false})}>
                                <ul className={"dropdown-menu"+(this.state.userToolbarOpen?" open-toolbar":"")}

                                    data-popper-placement="bottom-start">

                                    <li><a className="dropdown-item no-pointer-events"
                                           href="#">{this.state.logged.username}</a></li>
                                    {/*<li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                                    <li><a
                                        onClick={e => this.openAbout()}
                                        // href="https://info.dragonote.com/"
                                        href="#"
                                        className="dropdown-item" >About the app</a></li>
                                    <li>
                                        <hr className="dropdown-divider"/>
                                    </li>
                                    <li><a onClick={e => this.logOut()} className="dropdown-item" href="#">Log out</a></li>
                                </ul>
                            </OutsideClickComponent>: null}

                    </div>:null}





                </div>
            </div>
            {/*<div className="tools-top-center">*/}
            {/*    <AppSearchComponent app={this.props.app} />*/}
            {/*</div>*/}
            {/*<div className="tools-top-right">*/}
            {/*    <TodoIconComponent app={this.props.app} />*/}
            {/*    <ShareComponent app={this.props.app} />*/}
            {/*    <UserNotificationsComponent app={this.props.app} />*/}
            {/*    <AppStatusComponent app={this.props.app} />*/}
            {/*    {null != this.state.logged? <div className="btn-group">*/}
            {/*        /!*<button type="button" className="btn btn-dark btn-floating dropdown-toggle ripple-surface"*!/*/}
            {/*        /!*        // data-mdb-toggle="dropdown"*!/*/}
            {/*        /!*        aria-expanded="false"*!/*/}
            {/*        /!*        onClick={e => this.setState({userToolbarOpen: !this.state.userToolbarOpen})}*!/*/}
            {/*        /!*>*!/*/}
            {/*        /!*    *!/*/}
            {/*        /!*</button>*!/*/}
            {/*        <span onClick={e => this.setState({userToolbarOpen: !this.state.userToolbarOpen})}>*/}
            {/*            <UserIconComponent onButtonClick={() => {}} app={this.props.app} userId={this.state.logged.id} />*/}
            {/*        </span>*/}
            {/*        <ul className={"dropdown-menu"+(this.state.userToolbarOpen?" open-toolbar":"")}*/}

            {/*            data-popper-placement="bottom-start">*/}

            {/*            <li><a className="dropdown-item no-pointer-events"*/}
            {/*                   href="#">{this.state.logged.username}</a></li>*/}
            {/*            /!*<li><a className="dropdown-item" href="#">Something else here</a></li>*!/*/}
            {/*            <li><a*/}
            {/*                onClick={e => this.openAbout()}*/}
            {/*                // href="https://info.dragonote.com/"*/}
            {/*                href="#"*/}
            {/*                className="dropdown-item" >About the app</a></li>*/}
            {/*            <li>*/}
            {/*                <hr className="dropdown-divider"/>*/}
            {/*            </li>*/}
            {/*            <li><a onClick={e => this.logOut()} className="dropdown-item" href="#">Log out</a></li>*/}
            {/*        </ul>*/}
            {/*    </div>:null}*/}
            {/*</div>*/}


            {/*<div className="tools-bottom-right">*/}
                <BottomToolsComponent app={this.props.app} />
                {/*<SelectedMainToolsComponent app={this.props.app} />*/}
                {/*<div className="add-map-objects-toolbar">*/}
                {/*    {this.getAddMapWidgetButton("far fa-sticky-note",()=>this.addNote())}*/}
                {/*    {this.getAddMapWidgetButton("fas fa-clipboard-list",()=>this.addNote())}*/}
                {/*    {this.getAddMapWidgetButton("fas fa-file-upload",()=>this.addNote())}*/}
                {/*    {this.getAddMapWidgetButton("far fa-flag",()=>this.addNote())}*/}
                {/*</div>*/}


            {/*</div>*/}
            <div className={"tools-bottom-left card shadow-5-strong " + (!this.state.miniMapOpen?"mini-map-closed":"")}

            >
                {/*<FocusHistoryComponent app={this.props.app} />*/}
                <div className="card-header" onClick={e => {
                    if (!DomUtil.getOfClassOrParent(e.target,["map-choose-open-component","map-choose-button"])) {
                        if (this.props.app.io.isMobile()) {
                            this.setState({miniMapOpen: !this.state.miniMapOpen});
                        } else {
                            this.setState({miniMapOpen: true});
                        }
                    }
                }}>
                    <MapChooseComponent app={this.props.app} />
                </div>
                <div className={'card-body mini-map-container'}>
                    <MiniMapComponent app={this.props.app} />
                </div>



                <div className="zoom-tools toolbar" >
                    <button
                        data-tooltip="Zoom in (Ctrl+)"
                        data-tooltip-position="right"
                        onClick={()=>this.props.app.moveLevel(1)} className="btn btn-floating btn-dark zoom-in ripple-surface"><i className="fa fa-plus"/></button>
                    <button
                        data-tooltip="Zoom out (Ctrl-)"
                        data-tooltip-position="right"
                        onClick={()=>this.props.app.moveLevel(-1)} className="btn btn-floating btn-dark zoom-out ripple-surface"><i className="fa fa-minus"/></button>
                    {this.getZoomInfo()}
                </div>
            </div>
        </div>
    }
}
