import React, {useState} from 'react';


import {convertFromRaw, convertToRaw} from "draft-js";
import Editor from '@draft-js-plugins/editor';

import {EditorState, RichUtils, AtomicBlockUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

import {AppController} from "../../controllers/AppController";

import createImagePlugin from '@draft-js-plugins/image';

import createLinkifyPlugin from 'draft-js-linkify-plugin';
import {MapNode} from "../../node/MapNode";

import md5 from 'crypto-js/md5';
import {MapNodeToolsComponent} from "../ui/node-tools/MapNodeToolsComponent";


export interface NoteComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,

}

export interface NoteComponentState {
    content: string,
    editorState: EditorState,
    contentState: string,
    dataLoaded: boolean

}

const linkifyPlugin = createLinkifyPlugin({
    target: '_blank'
});


/*
@todo
dante
update data to db


https://medium.com/@siobhanpmahoney/building-a-rich-text-editor-with-react-and-draft-js-part-2-4-persisting-data-to-server-cd68e81c820

https://medium.com/@siobhanpmahoney/building-a-rich-text-editor-with-react-and-draft-js-part-2-2-embedding-links-d71b57d187a7


 */

/**
 * @deprecated
 */
export class NoteComponent extends React.Component<NoteComponentProps, NoteComponentState> {

    private imagePlugin: any;
    // private editorState: any;
    // private setEditorState: any;

    constructor(props, state) {
        super(props, state);
        this.state = {
            content: "",//props.note.note,
            editorState: null,
            contentState: null,
            dataLoaded: false,
        }

        this.imagePlugin = createImagePlugin();

    }


    // private _noteRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        // const observer = new MutationObserver(changes => {
        //     this.props.note.note = this._noteRef.current.innerHTML;
        //     this.setState({content: this.props.note.note});
        //     console.log("updated note",this.props.note.note);
        //
        //
        // }).observe(this._noteRef.current, {childList: true,subtree: true,attributes: true,characterData: true});
        // this._noteRef.current.innerHTML = this.state.content;
        (async () => {

            // const data = await this.props.app.noteService.fetchNodeData(this.props.mapNode);
            let data = null;
            if (null === this.props.mapNode.mapNodeDatas) {
                await this.props.app.mapNodeDataService.fetchNodeData(this.props.mapNode);
                if (undefined != this.props.mapNode.mapNodeDatas[0]) {
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
            } else {
                if (undefined != this.props.mapNode.mapNodeDatas[0]) {
                    data = this.props.mapNode.mapNodeDatas[0].data;
                }
            }


            if (null != data && undefined != data.content) {
                const state = EditorState.createWithContent(convertFromRaw(data.content));

                // console.log("state", state);
                this.setState({editorState: state});

            } else {
                this.setState({editorState: EditorState.createEmpty()});
            }

            if (undefined === this.props.mapNode.mapNodeDatas[0]) {
                this.props.mapNode.mapNodeDatas = [
                    await this.props.app.mapNodeDataService.insertMapNodeData(this.props.mapNode, () => this.getData(this.state.editorState))
                ]
            }
            this.setState({dataLoaded: undefined !== this.props.mapNode.mapNodeDatas[0]});

        })();
    }

    componentDidUpdate() {

    }

    private firstLoad = true;

    private getData(editorState: EditorState) {
        const content = editorState.getCurrentContent();
        const raw = convertToRaw(content);
        return {
            content: raw
        };
    }

    private getContentState() {

    }

    onEditorChange(editorState: EditorState) {

        const data = this.getData(editorState);

        const contentState = md5(JSON.stringify(data)).toString();

        const changed = null !== this.state.contentState && contentState !== this.state.contentState;

        this.setState({editorState: editorState, contentState: contentState});

        const getData = () => data;

        if (!this.firstLoad && changed) {

            (async () => {
                // this.props.app.pendingOperations.set(this.props.mapNode,"saving",true);
                // if (undefined === this.props.mapNode.mapNodeDatas[0]) {
                //     this.props.mapNode.mapNodeDatas = [
                //         await this.props.app.mapNodeDataService.insertMapNodeData(this.props.mapNode, getData())
                //     ]
                // } else {
                await this.props.app.mapNodeDataService.requestUpdateMapNodeData(this.props.mapNode.mapNodeDatas[0], () => getData());

                // }
                // this.props.app.pendingOperations.set(this.props.mapNode,"saving",false);

            })();

        }
        this.firstLoad = false;

    }

    insertImage(url) {
        const contentState = this.state.editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            {src: url},)
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(this.state.editorState, {currentContent: contentStateWithEntity});
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    };

    handlePastedFiles(files) {
        const formData = new FormData();
        formData.append('file', files[0])
        fetch('/api/uploads',
            {method: 'POST', body: formData})
            .then(res => res.json())
            .then(data => {
                if (data.file) {
                    this.setState({editorState: this.insertImage(data.file)}) //created below
                }
            }).catch(err => {
            console.log(err)
        })
    }


    render() {


        let editor = <i className={"fa fa-spin fa-spinner"}/>
        if (null !== this.state.editorState && this.state.dataLoaded) {
            editor = <div className="app-ui"><Editor editorState={this.state.editorState}

                                                     plugins={[this.imagePlugin, linkifyPlugin]}
                                                     handlePastedFiles={this.handlePastedFiles}
                                                     placeholder={"Note.."}
                                                     onChange={state => this.onEditorChange(state)}/></div>

        }
        return <div>
            <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode}/>
            {editor}
        </div>;
    }
}
