import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {OutsideClickComponent} from "../../widgets/outside-click/OutsideClickComponent";

import "./feedback.css";
import {NotificationsComponent} from "../notifications/NotificationsComponent";


export interface FeedbackComponentProps {
    app: AppController,
    onClose: () => void
}

export interface FeedbackComponentState {
    rating: number
}

export class FeedbackComponent extends React.Component<FeedbackComponentProps, FeedbackComponentState> {

    private _infoRef = React.createRef<HTMLTextAreaElement>();

    private _ratings = ["Bad 🤬","Poor 😨","OK 😐","Good 😎","Excelent! 🤩"];


    constructor(props) {
        super(props);

        this.state = {rating: -1};

    }

    onSubmit(e) {
        e.preventDefault();



        // if (!window.confirm('The selected data will be ENCRYPTED with this password, there is NO RECOVERY if you forget it. Do you wan\'t to continue?')) {
        //     return;
        // }
        (async () => {
            // for (const el of this.props.app.getSelected()) {
            //     if (el.isPasswordLockAvailable()) {
            //         await this.props.app.pendingOperations.waitForFree();
            //         el.password = true;
            //         console.log(this._passwordString);
            //         this.props.app.mapNodeService.passwordLock(el,this._passwordString).then(() => {
            //             this.props.app.onViewerStateChangeCall();
            //         });
            //     }
            // }
            // this.props.app.onViewerStateChangeCall();
            // this.props.onClose();
        })();

    }

    private submit(e) {
        e.preventDefault();
        if (this.state.rating < 0 || this.props.app.userService.getLoggedLoaded().isRatingSent()) {
            return;
        }

        this.props.app.userService.sendFeedback(this.state.rating,this._infoRef.current.value).then(resp => {
            console.log("feedback resp",resp);
        });
        this.props.app.notify("Thanks! 👌","success");
        this.props.onClose();

    }


    getRatingTitle() {
        if (undefined == this._ratings[this.state.rating-1]) {
            return "";
        }
        return this._ratings[this.state.rating-1];
    }



    render() {

        return <div className="feedback-component">
            <ModalComponent title={"How do you like this app?"} onClose={() => this.props.onClose()}>
                <form autoComplete="off" onSubmit={e => this.submit(e)}>
                    <div className="mb-4">
                       <div className="rating">
                           <div className="rating-stars">
                               {this._ratings.map((title,i) => <i
                                   onClick={e => this.setState({rating: i+1})}
                                   key={i} className={"fa-sm text-primary fa-star "+(this.state.rating >= i+1 ? "fas" : "far")} title={title} />)}
                           </div>

                           <span className="rating-title">{this.getRatingTitle()}</span>
                       </div>
                    </div>
                    <div className=" mb-4">
                         <textarea
                             ref={this._infoRef} className="form-control " placeholder="Is there something missing? What can I improve?"
                             required={true}
                             aria-describedby="button-addon2"/>
                    </div>
                    <div className="input-group mb-3">
                        {/*<input  type="text"*/}
                        {/*        ref={this._password} className="form-control password-font" placeholder="Enter password"*/}
                        {/*        required={true}*/}
                        {/*        aria-describedby="button-addon2"/>*/}


                        <button disabled={-1 ==this.state.rating} className="btn btn-outline-primary" type="submit" id="button-addon2"
                                data-mdb-ripple-color="dark">
                            Submit
                        </button>

                    </div>
                </form>
            </ModalComponent>

        </div>;
    }
}
