import {ModelUtil} from "../model/ModelUtil";

export class MapUserAccess {
    public id;
    public map;
    public user;
    public readOnly;
    public invitationEmail: string;
    public userTitle: string;

    public getTitle(): string {
        return this.userTitle;
    }

    public getMapId() {
        return ModelUtil.entityIdToNumeric(this.map);
    }
}