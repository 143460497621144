import {Vector2} from "../map/Vector2";
import {Base64} from "js-base64";

export class MapScope {
    public id;
    public title;
    public miniMap: string;
    public createdAt;
    public usage: number;
    public usageLimit: number;

    private _miniMapUnserialized = null;


    public getMapLink(position: Vector2,level: number): string {
        return "/?_="+Base64.toBase64(position.x+","+position.y+","+level+","+this.id).toString()
    }


    public getMiniMapUnserialized() {
        if (null === this._miniMapUnserialized) {
            if (null == this.miniMap) {
                this._miniMapUnserialized = [];
            }
            this._miniMapUnserialized = JSON.parse(this.miniMap);
        }
        return this._miniMapUnserialized;
    }


    public setMiniMapUnserialized(miniMap: any[]): boolean {
        const miniMapToSerialize = miniMap.map(el => {
            const newEl = {};
            for(const k in el) {
                if (-1 === ['g'].indexOf(k)) {
                    newEl[k] = el[k];
                }
            }
            return newEl;
        });

        const serialized = JSON.stringify(miniMapToSerialize);
        if (this.miniMap === serialized) {
            return false;
        }
        this._miniMapUnserialized = miniMap;
        this.miniMap = serialized;
        return true;
    }
}
