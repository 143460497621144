import React, {useState} from 'react';
import {Emoji} from "../../../lib/Emoji";
import {LoadingComponent} from "../loading/LoadingComponent";
import './select-emoji.css';





export interface SelectEmojiComponentProps {

    selectedIcon: number
    onSelectIcon: (index: number) => void
}
export interface SelectEmojiComponentState {
    filter: string,
    loaded: boolean
    // selectedIcon: number
}

export class SelectEmojiComponent extends React.Component<SelectEmojiComponentProps,SelectEmojiComponentState> {
    private _inputRef = React.createRef<HTMLInputElement>();


    constructor(props,state) {
        super(props,state);
        this.state = {
            filter: "",
            loaded: false
        }


    }



    componentDidMount() {
        (async () => {
            while(null == Emoji.getCategories()) {
                await new Promise(r => setTimeout(r, 50));
            }
            this.setState({loaded: true},() => {
                if (null != this._inputRef.current) {
                    this._inputRef.current.focus();
                }
            });

        })();
    }

    componentDidUpdate() {

    }

    onSelectIcon(index: number) {
        // this.setState({selectedIcon: index});
        this.props.onSelectIcon(index);
    }

    private _filteredCacheFor = {};
    private _filteredCache = {};

    private getFiltered(categoryId) {
        const filterKey = this.state.filter.trim().toLowerCase();
        if (this._filteredCacheFor[categoryId] !== filterKey) {
            this._filteredCacheFor[categoryId] = filterKey;
            const categories = Emoji.getCategories();
            if (0 == filterKey.length) {
                this._filteredCache[categoryId] = categories[categoryId].emojis.filter(emoji => !Emoji.isMissing(emoji.id));
            } else {
                this._filteredCache[categoryId] = categories[categoryId]
                    .emojis.filter(emoji => !Emoji.isMissing(emoji.id) &&
                        (categories[categoryId].n.toLowerCase().indexOf(filterKey) !== -1
                        || emoji.n.toLowerCase().indexOf(filterKey) !== -1));
            }

        }

        return this._filteredCache[categoryId];
    }

    render() {
        if (!this.state.loaded) {
            return <LoadingComponent/>;
        }


        return <div className="select-emoji-component">
            <input type="text" className="select-emoji-component-filter form-control form-control-lg shadow-1-strong"
                    ref={this._inputRef}
                   value={this.state.filter}
                   onChange={e => this.setState({ filter: e.target.value})}
                   placeholder="Search emoji.." />

            <div className="select-emoji-component-data">
                {Emoji.getCategories().map((category,categoryId) => <div key={categoryId}>
                    {0 !== this.getFiltered(categoryId).length ?
                        <div>
                            <h5>{category.n}</h5>
                            <div className="select-emoji-component-list">
                                {this.getFiltered(categoryId).map((emoji,j) => <span key={j}
                                                                                     className={"select-emoji-component-list-item "+(emoji.id === this.props.selectedIcon ? " shadow-1-strong":"")}
                                                                                     title={emoji.id + " - " + emoji.n}
                                                                                     onClick={e => this.onSelectIcon(emoji.id)}>{emoji.e}
                        </span>)}
                            </div>
                        </div>
                        : null}

                </div>)}
            </div>


        </div>
    }
}
