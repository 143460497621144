import React from 'react';

import {AppComponent} from "./AppComponent";
import {Model} from "../model/Model";
import {LoadingComponent} from "./widgets/loading/LoadingComponent";
import {ModalComponent} from "./widgets/modal/ModalComponent";
import {AppController} from "../controllers/AppController";
import {AboutComponent} from "./ui/about/AboutComponent";
import {loadIcons} from "../lib/Icons";


export interface AppLoaderProps {

}

export interface AppLoaderState {
    error: string,
    logged: boolean,
    loading: boolean,
    resourcesLoaded: boolean,
    // 1 - login, 2 - register
    loginFormType: number,
    aboutOpen: boolean,
    passwordChanged: boolean
}

export class AppLoader extends React.Component<AppLoaderProps, AppLoaderState> {


    constructor(props) {
        super(props);

        this.state = {error: null, logged: false, loading: true, loginFormType: 1,aboutOpen: false,passwordChanged: false,resourcesLoaded: false};

        loadIcons().then(() => {
            // console.log("resources loaded!");
            this.setState({resourcesLoaded: true})
        });
        const w: any = window;
        // window["apploader"] = this;

        if (window.innerWidth < 800) {
            try {
                const w: any = window;
                w.screen.orientation.lock("portrait");
            } catch (e) {
                console.error(e);
            }
        }
    }


    componentDidMount() {

        Model.refreshToken().then(resp => {
            this.setLogged();

        }, error => {
            this.setState({loading: false, logged: false});
        });

        // document.addEventListener("resize",() => {
        //     console.log("set body zoom ",window.innerWidth / window.outerWidth);
        //     document["body"].style.zoom = "" + window.innerWidth / window.outerWidth;
        // });

    }

    private _lastTime: number = null;

    private refreshTokenDaemon() {
        if (null === this._lastTime) {
            this._lastTime = Date.now();
        }


        if (!this.state.logged) {
            return;
        }
        if (Date.now() - this._lastTime > 15 * 60000) {
            Model.refreshToken().then(resp => {
                console.log("token refreshed");
            }, error => {
                console.log("error refresh token", error);
                window.location.reload();
            });
            this._lastTime = Date.now();
        }
        setTimeout(() => this.refreshTokenDaemon(), 1000);
    }

    private setLogged() {
        this.setState({loading: false, logged: true});
        this.refreshTokenDaemon();
    }


    handleError(error: string) {
        console.log("ERROR",error);
        this.setState({error: error, loading: false});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.loading) {
            console.error("request pending");
            return;
        }
        this.setState({error: null, loading: true});
        const email = e.target.querySelector("#email").value;
        const password = e.target.querySelector("#password").value;

        // let invitationCode = null;

        if (2 === this.state.loginFormType) {
            if (password !== e.target.querySelector("#repeatPassword").value) {
                this.setState({error: "Passwords must match",loading: false});
                return;
            }
            // invitationCode = e.target.querySelector("#invitationCode").value;
        }


        const register = () => {
            return fetch(Model.getApiUrl() + "register", {
                "headers": {
                    "accept": "application/json",
                    "content-type": "application/json",
                },
                "body": JSON.stringify({email: email, plainPassword: password,
                    // invitationCode: invitationCode
                }),
                "method": "POST",
                "mode": "cors",
                // "credentials": "include"
            });
        };


        const login = () => {


            this.setState({loading: true});
            Model.authorize(email, password).then(ok => {
                this.setLogged();
            }, error => {
                this.setState({loading: false});

                this.handleError(error);
            });


        }
        switch (this.state.loginFormType) {
            case 1:
                login();
                break;
            case 2:
                register().then(response => {
                    if (response.ok) {
                        login();
                    } else {
                        (async () => {
                            const data = await response.json();
                            let error = "error";
                            if (null != data && undefined != data.error) {
                                error = data.error;
                            }

                            this.handleError(error);
                        })();

                    }
                }, err => {
                    // this.setState({error: err,loading: false});
                    this.handleError(err);
                });
                break;
        }


    }


    render() {

        if (this.state.loading) {
            return <LoadingComponent fullPage={true}/>;
        }
        if (this.state.logged) {
            if (!this.state.resourcesLoaded) {
                return <LoadingComponent fullPage={true}/>;
            }
            return <AppComponent/>;
        }

        let errorMsg = null;
        if (null != this.state.error) {
            errorMsg = <div className="alert alert-danger">
                <i className="fas fa-exclamation-circle"/>
                {this.state.error}</div>
        }

        const submitName = 1 == this.state.loginFormType ? "Log in" : "Register";

        const repeatPassword = 2 === this.state.loginFormType ? <div>
            <div className=" mb-4">
            <input onChange={() => this.setState({passwordChanged: true})} placeholder="Repeat password" name="repeatPassword" type="password" required={true} id="repeatPassword" className="form-control"/>
            {/*<label className="form-label" htmlFor="repeatPassword">Repeat password</label>*/}
            {/*<div className="form-notch">*/}
            {/*    <div className="form-notch-leading"/>*/}
            {/*    <div className="form-notch-middle"/>*/}
            {/*    <div className="form-notch-trailing"/>*/}
            {/*</div>*/}
                {this.state.passwordChanged ? <div className="alert alert-warning mt-4">Remember your password, there is no reset</div> : null}
        </div>
            <div className="mb-4">
                <input placeholder="Invitation code" name="invitationCode" type="text" required={true} id="invitationCode" className="form-control"/>
            </div>
            <div className="mb-4">
                <input type="checkbox" id="agree"
                    required={true}
                /> <label htmlFor="agree"> I agree to:</label>

                <a href="https://info.dragonote.com/terms-of-use/"
                   style={{"marginLeft": "5px","marginRight": "5px"}}
                   target="_blank"
                   className="terms-link"
                >
                    terms of use
                </a>
                <span style={{'color': 'white'}}>&</span>
                <a href="https://info.dragonote.com/privacy-policy/"
                   style={{"marginLeft": "5px"}}
                   className="terms-link"
                   target="_blank"
                >
                    privacy policy
                </a>

            </div>
        </div> : null;

        return <div className="">
            {this.state.aboutOpen?<ModalComponent title={"DragoNote - Quick whiteboard notes"} onClose={() => this.setState({aboutOpen: false})}>
                <AboutComponent />
            </ModalComponent>:null}
            <div className="app-loader-background-container">
                <div className="app-loader-background "/>
            </div>
            <div className="app-loader-container">
                <div className="app-loader">
                    <div id="logo-main-container">
                        <img id="logo-main" src="/images/logo-white.png" />
                        {/*<img id="logo-main" src="/images/logo white.png" />*/}
                    </div>

                    <h1 className="text-center">DragoNote</h1>
                    <form onSubmit={e => this.handleSubmit(e)}>
                        {errorMsg}
                        <div className="d-flex justify-content-center  mb-3 radio-buttons">
                            <div className="form-check form-check-inline p-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    value="1"
                                    onChange={e => this.setState({loginFormType: 1})}
                                    checked={1 === this.state.loginFormType}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">Log in</label>
                            </div>

                            <div className="form-check form-check-inline p-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    value="2"
                                    onChange={e => this.setState({loginFormType: 2})}
                                    checked={2 === this.state.loginFormType}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">Sign up</label>
                            </div>
                        </div>

                        <div className=" mb-4">
                            <input placeholder="Email" name="email" required={true} type="email" id="email" className="form-control"/>
                            {/*<label className="form-label" htmlFor="email"*/}
                            {/*>Email</label>*/}
                            {/*<div className="form-notch">*/}
                            {/*    <div className="form-notch-leading"/>*/}
                            {/*    <div className="form-notch-middle"/>*/}
                            {/*    <div className="form-notch-trailing"/>*/}
                            {/*</div>*/}
                        </div>

                        <div className=" mb-4">
                            <input placeholder="Password"

                                   name="password" type="password" required={true} id="password" className="form-control"/>
                            {/*<label className="form-label" htmlFor="password">Password</label>*/}
                            {/*<div className="form-notch">*/}
                            {/*    <div className="form-notch-leading"/>*/}
                            {/*    <div className="form-notch-middle"/>*/}
                            {/*    <div className="form-notch-trailing"/>*/}
                            {/*</div>*/}
                        </div>
                        {repeatPassword}
                        {/*<div className="row mb-4">*/}
                        {/*    <div className="col d-flex justify-content-center">*/}

                        {/*    </div>*/}

                        {/*    <div className="col">*/}

                        {/*        <a href="#!">Forgot password?</a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <button type="submit" className="btn btn-dark btn-block">
                            {/*<i className="fa fa-lock"/>*/}
                            {submitName}</button>
                    </form>

                    <div className="mb-4"/>
                    <div className="mb-4"/>
                    <div className="text-center ">
                        <a href="#" onClick={() => this.setState({aboutOpen: true})} className="text-light">About the app</a>
                    </div>

                </div>
            </div>
            </div>;
    }
}
