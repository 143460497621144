import {MapObject} from "../map/MapObject";

import {Vector2} from "../map/Vector2";
import {DebugComponent} from "../components/DebugComponent";

import {MapAction} from "../components/MapActionComponent";
import {IO} from "../io/IO";
import {Rectangle} from "../map/Rectangle";
import {DisplayObject} from "../display/DisplayObject";
import {DisplayArea} from "../lib/DisplayArea";
import {MapArea} from "../map/MapArea";
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import { gql } from '@apollo/client';
import {MapNodeService} from "../node/MapNodeService";
import {MapNode} from "../node/MapNode";
import {MapNodeDataService} from "../node/MapNodeDataService";
import React from "react";
import {PendingOperations} from "../lib/PendingOperation";
import {Base64} from "js-base64";
import {DelayedOperation} from "../lib/DelayedOperation";
import {DaemonUtils} from "../utils/DaemonUtils";
import {MapService} from "../node/MapService";
import {MapScope} from "../node/MapScope";
import {FocusHistory} from "../lib/FocusHistory";
import {UserService} from "../node/UserService";
import {MapCommentService} from "../node/MapCommentService";
import {MapNodeType, MapNodeTypeEnum} from "../node/MapNodeType";
import {ICONS, iconToPng,resetIconToPngCache} from "../lib/Icons";
import md5 from "crypto-js/md5";
import {Emoji} from "../lib/Emoji";
import {Settings} from "../lib/Settings";


export class AppController {

    public static VERSION = "1.8.9";



    public log = {};

    private _currentMapInitialId = null;
    public currentMap: MapScope;
    public viewerState: MapObject;
    public userService: UserService;
    public mapService: MapService;
    public mapNodeService: MapNodeService;
    public mapNodeDataService: MapNodeDataService;
    public mapCommentService: MapCommentService;
    public mapSize: Vector2;

    public focus: MapNode;

    public _focusFullScreenEdit: boolean = false;

    public selected: MapNode[] = [];

    public onViewerStateChange: (() => void)[] = [];

    public mapAction: MapAction;

    private _mapMoving: boolean = false;

    public mapNodesMoving: boolean = false;

    public io: IO = new IO();

    public globalClass = "no-shadows";

    public pendingOperations: PendingOperations = new PendingOperations();

    public focusHistory: FocusHistory = null;

    public notify: (text,type) => void;

    public confirm: (title,type) => Promise<boolean>;

    public onTodoImportanceChange: (() => void)[] = [];

    public showHidden = false;

    public disableScrollZoom = false;

    public uriAction = null;

    public settings = new Settings("app");// not used yet

    public desktopUnconfortableEnabled = false;

    constructor() {

        //console.log("app init");

        this.viewerState = new MapObject(new Vector2(0, 0),
            new Vector2(0, 0),0);

        this.runUriAction();

        // const uri = window.location.pathname;
        const positionUri = new URLSearchParams(document.location.search).get("_");
        if (null != positionUri) {
            const uri = Base64.decode(positionUri).toString();
            if (null != uri) {
                const rows = uri.replace(/^\//,'').split(",");
                if (rows.length === 4) {
                    let x = parseFloat(rows[0]);
                    let y = parseFloat(rows[1]);
                    let level = parseInt(rows[2]);
                    const mapId = parseInt(rows[3]);
                    if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(level)) {
                        if (0 == x && 0 == y && 0 == level) {
                            const storagePos = localStorage.getItem("lastPosition-"+mapId);
                            if (undefined != storagePos) {
                                //console.log("AUTO LOAD POSITION FROM SESSION",storagePos);
                                const storagePosUnserialized = JSON.parse(storagePos);
                                x = storagePosUnserialized.x;
                                y = storagePosUnserialized.y;
                                level = storagePosUnserialized.l;
                            }

                        }
                        this.viewerState.position = new Vector2(x, y);
                        this.viewerState.level = level;
                    }
                    if (!Number.isNaN(mapId)) {
                        this._currentMapInitialId = mapId;
                    }


                }
            }
        }

        if (window.location.host == "localhost:3000") {
            window["app"] = this;
        }




    }

    public set mapMoving(value) {
        if (this._mapMoving === value) {
            return;
        }
        this._mapMoving = value;
        DelayedOperation.run("mapMovingUpdateBodyClass", () => {
            if (this._mapMoving) {
                if (!document.body.classList.contains("app-map-dragging")) {
                    document.body.classList.add("app-map-dragging");
                }
            } else {
                if (document.body.classList.contains("app-map-dragging")) {
                    document.body.classList.remove("app-map-dragging");
                }
            }
        }, 100);
    }

    public get mapMoving() {
        return this._mapMoving;
    }

    private runUriAction() {
        const action = new URLSearchParams(document.location.search).get("action");
        if (null != action) {

        }
        const id = new URLSearchParams(document.location.search).get("id");
        this.uriAction = {
            action: action,
            id: id
        }
    }

    public isOptimizing() {
        return this.mapMoving || this.mapNodesMoving;
    }

    public set focusFullScreenEdit(value) {
        this._focusFullScreenEdit = value;
        if (null != this.focus && undefined != this.focus.events.onFullScreenChange) {
            this.focus.events.onFullScreenChange.forEach(callback => callback(value));
        }
    }

    public get focusFullScreenEdit() {
        return this._focusFullScreenEdit;
    }

    public async initLoad() {
        this.mapService = new MapService(this.pendingOperations);
        const maps = await this.mapService.fetchMyMaps();
        if (null != this._currentMapInitialId) {
            this.currentMap = maps.find(map => map.id === this._currentMapInitialId);
        }
        if (null == this.currentMap) {
            this.currentMap = maps[0];
        }
        this.userService = new UserService(this);
        this.mapNodeService = new MapNodeService(this.currentMap, this.pendingOperations);
        this.mapNodeService.onLoadMapNodes.push(() => this.onViewerStateChangeCall());

        this.mapNodeDataService = new MapNodeDataService(this.pendingOperations,this.mapNodeService);
        this.mapCommentService = new MapCommentService(this.pendingOperations);
        this.focusHistory = new FocusHistory(this.currentMap.id,this.mapNodeService);
        await this.userService.getLogged();
        this.mapSize = new Vector2(10000,10000);
        DaemonUtils.waitFor(() => this.mapNodeService.isLoaded(),100).then(() => {

            this.focusHistory.load();
            this.recalculateMapSize();
            this.onTodoImportanceChange.forEach(c => c());
            this.updateFavicon();
            [1000,2000,3000,4000,5000,10000].forEach(time => {
                setTimeout(() => {
                    resetIconToPngCache();
                    this.updateFavicon(true)
                },time);
            });


        });
        this.showHidden = "1" == localStorage.getItem("showHidden-"+this.currentMap.id);

    }

    public isNodeVisible(mapNode: MapNode) {
        const result = this.showHidden || !mapNode.hidden;
        if (!result && this.pendingOperations.get(mapNode.mapObject)) {
            return true;
        }
        return result;
    }

    public getNodesVisible(): MapNode[] {
        return this.mapNodeService.getNodesLoaded().filter(el => this.isNodeVisible(el));
    }

    //
    // public isReadOnly(object) {
    //     return !this.userService.hasWriteRights(object);
    // }

    public toggleShowHidden() {
        this.showHidden = !this.showHidden;

        if (!this.showHidden) {
            if (null != this.focus && !this.isNodeVisible(this.focus)) {
                this.setFocus(null);
            }

            this.selected = this.getSelected().filter(el => this.isNodeVisible(el));
        }


        this.onViewerStateChangeCall();

        localStorage.setItem("showHidden-"+this.currentMap.id,this.showHidden ? "1" : "0");

    }

    public hasAnyHidden() {
        return null != this.mapNodeService.getNodesLoaded().find(el => el.hidden);
    }

    public isFocusFullScreenEdit() {
        return null != this.focus &&
            this.focus.getTypeObject().isFullScreenEditable()
            && this.focusFullScreenEdit
    }




    public recalculateMapSize() {
        let area = new MapArea(new Vector2(0,0),new Vector2(0,0));
        this.mapNodeService.getNodesLoaded().forEach(mapNode => {

            const noteObj = mapNode.mapObject;
            const zoom = this.levelToZoom(noteObj.level);
            const notePosition = noteObj.position.divide(zoom);
            const noteSize = noteObj.size.divide(zoom);
            area = area.sum(new MapArea(notePosition,noteSize));
        });
        const max = Math.max(area.size.x+1000,area.size.y+1000,10000);
        this.mapSize = new Vector2(max,max);
        this.onViewerStateChangeCall();
    }


    private _previousUrl: string = null;

    updateUrl() {
        // const url = "/?_="+Base64.toBase64(this.viewerState.position.x+","+this.viewerState.position.y+","+this.viewerState.level+","+this.currentMap.id).toString();
        const url = this.currentMap.getMapLink(this.viewerState.position,this.viewerState.level);
        if (url !== this._previousUrl) {
            this._previousUrl = url;
            DelayedOperation.run("app-update-url",() => {
                window.history.replaceState(null, null, url);
            },1000);
        }
        localStorage.setItem("lastPosition-"+this.currentMap.id,JSON.stringify( {
            x: this.viewerState.position.x,
            y: this.viewerState.position.y,
            l: this.viewerState.level
        }));

    }



    setGlobalClass(value) {
        this.globalClass = value;
        this.onViewerStateChangeCall();
    }



    setFocus(focus: MapNode, deselectOthers = false) {
        const sameFocus = focus == this.focus;
        // if (focus == this.focus && this.focusFullScreenEdit) {
        if (focus == this.focus) {
            return;
        }
        //console.log("focus",focus);//new Error());
        // if (-1 !== this.focusHistory.indexOf(focus)) {
        //     this.focusHistory.splice(this.focusHistory.indexOf(focus),1);
        // }
        // this.focusHistory.push(focus);
        // if (this.focusHistory.length > 9) {
        //     this.focusHistory.splice(0,1);
        // }
        this.focusHistory.onFocus(focus);
        // if (!sameFocus) {
            this.mapNodeService.touchMapNode(focus).then(() => {

            });
        // }

        if (deselectOthers) {
            this.deselectAll();
        }
        this.focus = focus;
        if (undefined != focus) {
            this.setSelected(focus);

        }
        if (null == focus && this.focusFullScreenEdit) {
            this.focusFullScreenEdit = false;
        }

    }

    public deselectAll() {
        this.selected = [];
        this.focus = null;
        this.focusFullScreenEdit = false;
        this.onViewerStateChangeCall();
    }

    setSelected(note: MapNode): void {
        if (-1 === this.selected.indexOf(note)) {
            this.selected.push(note);
        }
    }

    isSelected(note: MapNode): boolean {
        return -1 !== this.selected.indexOf(note)
    }

    getSelected(): MapNode[] {
        if (null != this.focus && !this.isSelected(this.focus)) {
            this.setSelected(this.focus);
        }

        return this.selected;

    }

    setLevel(value: number) {
        let oldLevel = this.viewerState.level;
        this.viewerState.level = value;

        const oldZoom = this.levelToZoom(oldLevel);
        const newZoom = this.getZoom();

        this.setViewerPosition(this.viewerState.position.multiply(newZoom / oldZoom))


        this.onViewerStateChangeCall();
    }

    moveLevel(value: number) {
        const newLevel = this.viewerState.level + value;
        if (newLevel < -5 || newLevel > 5) {
            return;
        }
        this.setLevel(newLevel);
    }

    onViewerStateChangeCall() {
        this.updateUrl();

        this.onViewerStateChange.forEach( callback => callback());
        this.updateFaviconRequest();

    }

    setViewerPosition(vector: Vector2) {
        this.viewerState.position = vector;
       this.onViewerStateChangeCall();
    }

    moveViewerPosition(vector: Vector2) {
        this.setViewerPosition(vector.sum(this.viewerState.position));
        // this.log["viewerState"] = "" + this.viewerState;
        this.updateUrl();
        this.updateFaviconRequest();


    }

    updateFaviconRequest() {
        DelayedOperation.run("app-update-favicon",() => {
            this.updateFavicon();
        },1000);
    }


    private _faviconFlag = null;

    updateFavicon(force=false) {
        let candidates = this.getNodesVisible().filter(el => el.type == MapNodeTypeEnum.FLAG && this.isDisplayable(el.mapObject));



        let max = null;

        candidates.forEach(el => {
            if (null == max || el.mapObject.level < max.mapObject.level) {
                max = el;
            }
        });




        const viewerCenter = this.viewerState.size
            // .divide(this.props.app.getZoom())
            .multiply(0.5)

            // .minus(this.viewerState.position
                // .multiply(this.props.app.getZoom())
            // )
        ;
        let flag = max;
        if (null != max) {
            const newCandidates = candidates.filter(el => el.mapObject.level == max.mapObject.level);
            if (newCandidates.length > 0) {
                flag = this.searchClosestObject(viewerCenter,el => true,newCandidates);
            }
        }

        if (null === max) {
            candidates = this.getNodesVisible().filter(el => el.type == MapNodeTypeEnum.FLAG);
            flag = this.searchClosestObject(viewerCenter,el => true,candidates);
        }

        if (null != flag) {
            const spec = flag.getTypeObject().getMiniMapSpec(flag);
            if (JSON.stringify(spec) != JSON.stringify(this._faviconFlag) || force) {
                this._faviconFlag = spec;
                let img = (null == spec.additional || 0 == parseInt(spec.additional) || isNaN(parseInt(spec.additional))) ? iconToPng(spec.icon,spec.color) : Emoji.emojiToPng(spec.icon);
                if (null != img) {
                    const favicon = document.head.querySelector("[rel=icon]");
                    favicon.setAttribute("type","image/png");
                    favicon.setAttribute("href",img);

                }

            }
            let title = flag.title;
            if (null == title || 0 == title.length) {
                title = "Untitled";
            }
            const theTitle = title + " - DragoNote v"+AppController.VERSION;
            if (document.title !== theTitle) {
                document.title = theTitle;
            }
        }

    }

    levelToZoom(level): number {
        const zoomMod = 2;
        let result = 1;
        if (level > 0) {
            for(let i = 0; i < level; i++) {
                result *= zoomMod;
            }
        } else {
            for(let i = 0; i < -level; i++) {
                result /= zoomMod;
            }
        }
        return result;
    }

    getZoom() {
        return this.levelToZoom(this.viewerState.level);
    }

    canvasPositionToMapPosition(vector: Vector2) {
        return vector.minus(this.viewerState.size.multiply(0.5)
            .minus(this.viewerState.position));
    }



    getSelectedDisplayArea(): DisplayArea {
        const areas: DisplayArea[] = this.getSelected().map(el => this.mapObjectToDisplayObject(el.mapObject).getArea());
        const area = DisplayArea.sumAll(areas);
        return area;
    }

    getSelectedMapArea(): MapArea {
        return MapArea.sumAll(this.getSelected().map(el => el.mapObject.getArea()));
    }

    setMapAction(mapAction: MapAction) {
        this.mapAction = mapAction;
        if (null != mapAction) {
            const callback: any = this.mapAction.callback;
            this.mapAction.callback = (resp) => {
                callback(resp);
                this.setMapAction(null);
            };
        }

        this.onViewerStateChangeCall();
    }

    getDisplayZoom(objectLevel: number): number {
        const zoomDiff = this.viewerState.level - objectLevel;
        return this.levelToZoom(zoomDiff);
    }


    mapObjectToDisplayObject(mapObject: MapObject): DisplayObject {
        const zoom = this.getDisplayZoom(mapObject.level);

        const viewerCenter = this.viewerState.size
            // .divide(this.props.app.getZoom())
            .multiply(0.5)

            .minus(this.viewerState.position
                // .multiply(this.props.app.getZoom())
            )
        ;

        const notePosition = mapObject.position.multiply(zoom);

        const displayPosition = viewerCenter
            .sum(notePosition
                .minus(mapObject.size
                    .multiply(zoom)
                    .multiply(0.5)
                )
            )
        ;

        const displaySize = mapObject.size.multiply(zoom);

        return new DisplayObject(displayPosition,displaySize,zoom);
    }

    public isInDisplayArea(mapObject:MapObject,displayArea: DisplayArea): boolean {
        const displayObject = this.mapObjectToDisplayObject(mapObject);
        return displayObject.getArea().isColliding(displayArea);
    }

    public isDisplayable(mapObject: MapObject): boolean {
        if (null != this.pendingOperations.get(mapObject)) {
            return true;
        }
        const displayObject = this.mapObjectToDisplayObject(mapObject);
        const displayArea = displayObject.getArea();
        const viewerArea = new DisplayArea(new Vector2(0,0),this.viewerState.size);

        return viewerArea.isColliding(displayArea);
    }

    public isDisplayableContents(mapNode: MapNode): boolean {
        if (null != this.pendingOperations.get(mapNode.mapObject)) {
            return true;
        }
        const zoomTreshold: Vector2 = mapNode.getTypeObject().getDisplayContentsLevelTreshold();
        const levelDiff = this.viewerState.level - mapNode.mapObject.level;
        return (null === zoomTreshold.x || levelDiff >= zoomTreshold.x)
            && (null === zoomTreshold.y || levelDiff <= zoomTreshold.y);

    }

    public isFittingScreen(mapObject: MapObject): boolean {

        const displayObject = this.mapObjectToDisplayObject(mapObject);
        const displayArea = displayObject.getArea();
        const viewerArea = new DisplayArea(new Vector2(0,0),this.viewerState.size);

        return displayArea.isInsideAnotherArea(viewerArea);
    }

    public getNotFittingScreenVector(mapObject: MapObject): Vector2 {

        const displayObject = this.mapObjectToDisplayObject(mapObject);
        const displayArea = displayObject.getArea();
        const viewerArea = new DisplayArea(new Vector2(0,0),this.viewerState.size);
        const margin = 20;

        if (!displayArea.isInsideAnotherArea(viewerArea)) {
            const corners = displayArea.getCornersNotInside(viewerArea);

            const vector = new Vector2(0,0);
            for(const corner of corners) {

                const diff: Vector2 = new Vector2(0,0);
                if (corner.x < 0) {
                    diff.x = corner.x - margin;
                } else if (corner.x > viewerArea.size.x) {
                    diff.x = corner.x - viewerArea.size.x + margin;
                }
                if (corner.y < 0) {
                    diff.y = corner.y - margin;
                } else if (corner.y > viewerArea.size.y) {
                    diff.y = corner.y - viewerArea.size.y + margin;
                }

                if (diff.normalize().x > vector.normalize().x) {
                    if (diff.x < 0 || displayArea.size.x < viewerArea.size.x) {
                        vector.x = diff.x;
                    }
                }
                if (diff.normalize().y > vector.normalize().y) {
                    if (diff.y < 0 || displayArea.size.y < viewerArea.size.y) {
                        vector.y = diff.y;
                    }
                }

            }
            return vector;

        }
        return null;
    }


    public goto(mapObject: MapObject,levelMargin=0) {
        const oldLevel = this.viewerState.level;
        this.viewerState.position = mapObject.position;
        this.viewerState.level = mapObject.level;
        // if (0 == levelMargin) {
        //
        // }

        const displaySize = this.mapObjectToDisplayObject(mapObject);
        if (displaySize.size.x > this.viewerState.size.x || displaySize.size.x > this.viewerState.size.y) {
            this.moveLevel(-1);
        } else {
            let diff = oldLevel - this.viewerState.level;
            if (diff < 0) {
                diff = -diff;
            }
            if (diff > 0 && diff <= levelMargin) {
                this.setLevel(oldLevel);
            }
        }
        this.onViewerStateChangeCall();

    }

    public searchClosestObject(displayPosition: Vector2, filter: (mapNode: MapNode) => boolean,mapNodes: MapNode[] = null) {
        let winner = null;
        let winnerDist = null;
        if (null === mapNodes) {
            mapNodes = this.getNodesVisible();
        }
        mapNodes.filter(el => filter(el)).forEach(el => {
            const dist = displayPosition.distance(this.mapObjectToDisplayObject(el.mapObject).position);
           if (null == winner || dist < winnerDist) {
               winner = el;
               winnerDist = dist;
           }
        });
        return winner;
    }

    public getObjectViewerDistance(displayPosition: Vector2,mapNode: MapNode) {
        return displayPosition.distance(this.mapObjectToDisplayObject(mapNode.mapObject).position);
    }


    public isDesktopUncomfortable(mapObject: MapObject): boolean {
        if (!this.desktopUnconfortableEnabled || this.io.isMobile()) {
            return false;
        }
        const displayRectangle = this.mapObjectToDisplayObject(mapObject);

        return displayRectangle.size.x > this.viewerState.size.x * 0.6
            && displayRectangle.size.y > this.viewerState.size.y * 0.6;
    }

}
