export class DaemonUtils {


    public static waitFor(cond: () => boolean,timeout=500): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const check = () => cond() ? resolve(true) : setTimeout(() => check(),timeout);
            check();
        })
    }

    public static wait(ms): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            setTimeout(() => resolve(true),ms);
        })
    }

}
