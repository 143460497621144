import {Vector2} from "../map/Vector2";



export class IO {

    public static instance: IO;

    public mouseDown = false;
    public mouseDownTraveled = 0;
    public mouseDownTarget: any = null;
    public mouseDownButton: number = 0;

    public onMouseMove: ((event: any) => void)[] = [];
    public onMouseUp: ((event: any) => void)[] = [];

    public onTouchStart: ((event: any) => void)[] = [];


    public drag = false;

    public onDrag: ((event: any,movement: Vector2) => void)[] = [];

    public onDragEnd: ((event: any) => void)[] = [];



    public keysDown = {};
    public onKeyUp: ((event: any) => void)[] = [];
    public onKeyDown: ((event: any) => void)[] = [];

    public onWheelStopMovingMapMove: (() => void)[] = [];

    public lastWheelUp: Date = new Date();

    public lastActivity: Date = new Date();


    public isMobile(): boolean { return window.innerWidth <= 1024};

    public isIOS() {
        return /(iphone|ipad)/i.test(window.navigator.userAgent);
    }

    public isTouch() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator["msMaxTouchPoints"] > 0));
    }

    constructor() {
        IO.instance = this;
    }

    handleMouseMove(event) {

        // //console.log("move");
        if (this.mouseDown) {
            const move = new Vector2(-event.movementX, -event.movementY);

            this.mouseDownTraveled += move.dist();
        }
        this.onMouseMove.forEach(callback => callback(event));

        if (this.drag) {

            this.onDrag.forEach(callback => callback(event,new Vector2(event.movementX, event.movementY)));
        }
        this.lastActivity = new Date();

        if (1 == this.mouseDownButton && this.mouseDown) {
            event.preventDefault();
        }

    }

    handleMouseDown(event) {
        // console.log("mouse down",event);
        this.mouseDown = true;
        this.mouseDownTarget = event.target;
        this.mouseDownButton = event.button;

        this.drag = true;
        this.mouseDownTarget = event.target;
        if (event.button === 1) {
            // event.preventDefault();
        }
        this.lastActivity = new Date();
        if (event.button === 1) {
            event.preventDefault();
        }
    }

    handleMouseUp(event) {
        //console.log("mouse up");
        if (1 == this.mouseDownButton && this.mouseDown) {
            event.preventDefault();
            this.lastWheelUp = new Date();
        }
        this.mouseDown = false;
        this.mouseDownTraveled = 0;
        this.onMouseUp.forEach(callback => callback(event));
        if (this.drag) {
            this.onDragEnd.forEach(callback => callback(event));
        }
        this.drag = false;

        this.lastActivity = new Date();


    }

    private _touchPos: Vector2 = null;


    handleTouchStart(e) {
        //console.log("touch start");
        this._touchPos = new Vector2(e.touches[0].clientX, e.touches[0].clientY);
        this.drag = true;
        this.mouseDownTarget = e.target;

        this.onTouchStart.forEach(callback => callback(e));
        this.lastActivity = new Date();
    }

    handleTouchMove(e) {
        const pos = new Vector2(e.touches[0].clientX, e.touches[0].clientY)
        const move = pos.minus(this._touchPos);
        this.onDrag.forEach(callback => callback(e,move));
        this._touchPos = pos;
        this.lastActivity = new Date();
    }

    handleTouchEnd(event) {
        this.drag = false;
        this.onDragEnd.forEach(callback => callback(event));
        //console.log("drag end");
        this.lastActivity = new Date();
    }



    handleKeyUp(event: KeyboardEvent) {
        if (undefined != this.keysDown[event.key]) {
            this.keysDown[event.key] = false;
        }
        this.onKeyUp.forEach(callback => callback(event));

    }

    handleKeyDown(event: KeyboardEvent) {
        // //console.log("keydown",event);

        if (undefined === this.keysDown[event.key]
            || false === this.keysDown[event.key]
        ) {
            this.keysDown[event.key] = true;
        }
        this.onKeyDown.forEach(callback => callback(event));
        this.lastActivity = new Date();
    }

    isKeyDown(key: string): boolean {
        return true === this.keysDown[key];
    }

    public cancelAllKeysDown() {
        for(const k in this.keysDown) {
            this.keysDown[k] = false;
        }
    }

}
