import React, {useState} from 'react';



import {AppController} from "../../../controllers/AppController";

import {MapNode} from "../../../node/MapNode";

import './log.css';
import {LogElement} from "./LogElement";
import {LogRowComponent} from "./LogRowComponent";
import {IO} from "../../../io/IO";
import {ModelUtil} from "../../../model/ModelUtil";
import {MapNodeToolsComponent} from "../../ui/node-tools/MapNodeToolsComponent";


export interface LogComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,

}


export interface LogComponentState {
    logs: LogElement[],
    addNew: string

}


export class LogComponent extends React.Component<LogComponentProps, LogComponentState> {

    constructor(props, state) {
        super(props, state);
        this.state = {
            logs: null,
            addNew: ""
        }
    }

    componentDidMount() {

        (async () => {

            if (null === this.props.mapNode.mapNodeDatas) {
                await this.props.app.mapNodeDataService.fetchNodeData(this.props.mapNode);

            }
            // console.log("logs",this.props.mapNode.mapNodeDatas);
            const logs = this.props.mapNode.mapNodeDatas.map(row => LogElement.fromData(row));

            this.setState({logs: logs});

            const sessionAddNew = sessionStorage.getItem(this.getSessionKey());
            if (null != sessionAddNew) {
                this.setState({addNew: sessionAddNew});

            }

        })();
    }

    componentDidUpdate() {

    }

    async addNew() {

        let log = new LogElement();
        log.content = this.state.addNew;
        const mapNodeData = await this.props.app.mapNodeDataService.insertMapNodeData(this.props.mapNode, log.toData());
        this.props.mapNode.mapNodeDatas.push(mapNodeData);
        this.state.logs.push(LogElement.fromData(mapNodeData));
        this.setState({logs: this.state.logs,addNew: ""});
        sessionStorage.removeItem(this.getSessionKey());
    }

    onKeyUp(e) {

    }

    onKeyDown(e) {
        if (this.props.app.io.isKeyDown("Shift")) {
            return;
        }

        switch(e.key) {
            case "Enter":
                e.preventDefault();
                this.addNew()
                break;
        }
    }

    getSessionKey() {
        return "log.addNewTmp."+this.props.mapNode.id;
    }

    onAddNewChange(e) {
        this.setState({addNew: e.target.value});
        sessionStorage.setItem(this.getSessionKey(),e.target.value);
    }

    render() {

        const readOnly = !this.props.app.userService.hasWriteRights(this.props.mapNode);

        let result = <i className={"fa fa-spin fa-spinner"}/>
        if (null !== this.state.logs) {
            result = <div className={"log-component app-ui"}>
                {/*<textarea placeholder="Type here and press enter.." */}
                {/*          className="log-add-textarea"*/}
                {/*          value={this.state.addNew}*/}
                {/*          onChange={e => this.setState({addNew: e.target.value})}*/}
                {/*          onKeyDown={e => this.onKeyDown(e)}*/}
                {/*          onKeyUp={e => this.onKeyUp(e)}*/}

                {/*/>*/}

                {!readOnly?<div className="form-outline" >
                    <textarea className="form-control log-add-textarea"  rows={4}
                              value={this.state.addNew}
                              onChange={e => this.onAddNewChange(e)}
                              onKeyDown={e => this.onKeyDown(e)}
                              onKeyUp={e => this.onKeyUp(e)}
                    />
                    <label className="form-label" >Add log</label>
                    <div className="form-notch">
                        <div className="form-notch-leading" />
                        <div className="form-notch-middle" />
                        <div className="form-notch-trailing" />
                    </div>
                </div>:null}


                <ul className="logs-container list-group">{this.state.logs.sort((a,b): number =>{
                    if (ModelUtil.entityIdToNumeric(a.id) > ModelUtil.entityIdToNumeric(b.id)) {
                        return -1;
                    } else if (ModelUtil.entityIdToNumeric(a.id) < ModelUtil.entityIdToNumeric(b.id)) {
                        return 1;
                    } else {
                        return 0;
                    }
                }).map((log: LogElement, i) =>
                <LogRowComponent key={log.id}
                                  app={this.props.app}
                                  mapNode={this.props.mapNode}

                                  log={log}/>)}</ul>

            </div>
        }
        return <div>
            <MapNodeToolsComponent app={this.props.app} mapNode={this.props.mapNode}/>
            {result}
        </div>;
    }
}
