import React, {Ref, useState} from 'react';

import {DomUtil} from "../../../utils/DomUtil";
import {AppController} from "../../../controllers/AppController";
import {IO} from "../../../io/IO";


export interface OutsideClickComponentProps {

    onOutsideClick: (e: Event) => void,
    ignoreClass?: string


}

export interface OutsideClickComponentState {

}

export class OutsideClickComponent extends React.Component<OutsideClickComponentProps, OutsideClickComponentState> {

    private _ref = React.createRef<HTMLSpanElement>();
    private _mouseUpEvent;

    constructor(props, state) {
        super(props, state);
        this.state = {

        }
    }

    componentDidMount() {
        this._mouseUpEvent = event => {
            if (null != this.props.ignoreClass) {
                if (DomUtil.getOfClassOrParent(event.target,this.props.ignoreClass)) {
                    return;
                }
            }
            if (!DomUtil.containsOrIs(this._ref.current,event.target)) {
                this.props.onOutsideClick(event);
            }
        };
        IO.instance.onMouseUp.push(this._mouseUpEvent);
    }

    componentWillUnmount() {
        IO.instance.onMouseUp.splice(IO.instance.onMouseUp.indexOf(this._mouseUpEvent),1);
    }


    render() {
        return <span ref={this._ref}>
            {this.props.children}
        </span>
    }
}
