import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./map-node-tools.css";
import {MapNode} from "../../../node/MapNode";
import {UserIconComponent} from "../user-icon/UserIconComponent";
import {FlashingIconComponent} from "../flashing-icon/FlashingIconComponent";
import {InputUtil} from "../../../utils/InputUtil";


export interface MapNodeToolsButton {
    onClick?(e): void,

    title?: string,
    icon?: string,
    color?: string,
    component?: any
}

export interface MapNodeToolsComponentProps {
    app: AppController;
    mapNode: MapNode;
    buttons?: MapNodeToolsButton[];
}

export interface MapNodeToolsComponentState {
    title: string

}

export class MapNodeToolsComponent extends React.Component<MapNodeToolsComponentProps, MapNodeToolsComponentState> {

    private _titleRef = React.createRef<HTMLInputElement>();

    constructor(props, state) {
        super(props, state);
        this.state = {
            title: props.mapNode.title,
        }


    }

    onChangeTitle(e) {
        this.setState({title: e.target.value});
        this.props.mapNode.title = e.target.value;
        this.props.app.mapNodeService.requestUpdateMapNode(this.props.mapNode);
    }

    // private _onUpdate = null;

    componentDidMount() {
        // this._onUpdate = () => {
        //     this.setState({title: this.props.mapNode.title});
        // }
        // this.props.mapNode._onUpdate.push(this._onUpdate);
        if (this.props.mapNode.isRecenltyAdded()) {
            (async () => {
                for(let i = 0; i < 3; i++) {
                    if (null != this._titleRef.current) {
                        this._titleRef.current.focus();
                        break;
                    }
                    await new Promise(_ => setTimeout(_, 100));
                }
            })();


        }
    }

    componentWillUnmount() {
        // this.props.mapNode._onUpdate.splice(this.props.mapNode._onUpdate.indexOf(this._onUpdate));
    }


    onFullScreenClick() {
        if (this.props.mapNode !== this.props.app.focus) {
            this.props.app.setFocus(this.props.mapNode, true);
        }
        this.props.app.focusFullScreenEdit = true;
        this.props.app.onViewerStateChangeCall();
    }

    private getLastAccessIcon() {
        let time = this.props.mapNode.updateTime;
        let color = null;
        // const colors = {"color": "red"};
        // let hasAny = false;
        let theTime = null;
        if (null != time && new Date().getTime() - time < 3600000) {
            theTime = time;
            // colors["color"] = "rgba(249,49,84,1,"+(1 - (0.7 * (new Date().getTime() - time) / 3600000))+")";
            // hasAny = true;
            color = "text-danger";
            // if (new Date().getTime() - time < 300000) {
            //
            // } else {
            //     color = "text-warning";
            // }
        }
        time = this.props.mapNode.touchedTime;
        // if (null != time && (null == theTime || time > theTime)) {
        //     theTime = time;
        // }
        if (null == color) {
            if (null != time && new Date().getTime() - time < 3600000) {
                theTime = time;
                color = "text-primary";
                // if (new Date().getTime() - time < 300000) {
                // } else {
                //     color = "text-info";
                // }
            }
        }
        if (null != color) {
            let title = "";
            if (null != this.props.mapNode.updateTime) {
                title += "Updated at " + new Date(this.props.mapNode.updateTime).toLocaleString();
            }
            if (null != this.props.mapNode.touchedTime) {
                title += "\nTouched at " + new Date(this.props.mapNode.touchedTime).toLocaleString();
            }
            let opacity = 1 - (0.7 * (new Date().getTime() - theTime) / 3600000);


            return <i className={"fa fa-fire-alt map-node-tool-button "+color} style={{opacity: opacity}}
                      title={title}/>
        } else {
            return null;
        }
    }

    onKeyDown(e) {
        InputUtil.onCtrlCSelectAll(e);
    }

    render() {

        let buttons = null;
        if (undefined != this.props.buttons) {
            buttons = this.props.buttons.map(
                (button, i) => {
                    if (undefined === button.component) {
                        return <i key={"btn-" + i} className={"map-node-tool-button " + button.icon}
                                  onClick={e => button.onClick(e)}
                                  title={button.title}
                                  style={{color: button.color}}
                        />;
                    } else {
                        return button.component;
                    }
                })
        }
        let userIcon = null;
        // if (this.props.app.userService.isDifferentOwner(this.props.mapNode)) {
        //     userIcon = <UserIconComponent app={this.props.app} userId={this.props.mapNode.ownerId} />
        // }

        const readOnly = !this.props.app.userService.hasWriteRights(this.props.mapNode);

        let readOnlyBtn = null;

        if (readOnly) {
            if (null != this.props.mapNode._otherUpdateUserId) {
                readOnlyBtn = <i
                    onClick={e => this.props.app.notify("Read only - currently other user is editing this object or you don't have edit rights on this object", "warning")}
                    className="fas fa-lock read-only-info text-danger map-node-tool-button "
                    title={"Read only - currently other user is editing this object or you don't have edit rights on this object"}/>;

                // readOnlyBtn = <FlashingIconComponent classes={["fas fa-lock text-danger read-only-info","fas fa-lock read-only-info"]} />
                // readOnlyBtn = <div>
                //     {this.props.mapNode._otherUpdateDate.toLocaleString()}
                // </div>
                setTimeout(() => this.forceUpdate(), 15000); //@todo
            } else {
                readOnlyBtn = <i
                    onClick={e => this.props.app.notify("Read only - you don't have rights to edit this object", "warning")}
                    className="fas fa-lock read-only-info text-danger map-node-tool-button "
                    title={"Read only - you don't have rights to edit this object"}/>;
            }
        }

        if (readOnly && null != this.props.mapNode._otherUpdateUserId) {
            userIcon = <UserIconComponent app={this.props.app} userId={this.props.mapNode._otherUpdateUserId}/>
        }

        const fullScreenButton = this.props.mapNode.getTypeObject().isFullScreenEditable()
            && !this.props.app.isFocusFullScreenEdit()
            && !this.props.app.io.isMobile();

        const lastAccessIcon = this.props.mapNode.getTypeObject().hasLastEditIcon()
            && !this.props.app.isFocusFullScreenEdit();


        return <div className="map-node-tools-component">


            <input type="text" placeholder="Title.."
                   value={null != this.state.title ? this.state.title : ""}
                   ref={this._titleRef}
                   readOnly={readOnly}
                   onChange={e => this.onChangeTitle(e)}
                   onKeyDown={e => this.onKeyDown(e)}
                   className="app-node-title app-ui"/>

            <div className="map-node-tools-buttons">
                {lastAccessIcon ? this.getLastAccessIcon() : null}

                {fullScreenButton ? <i onClick={e => this.onFullScreenClick()} title="Full screen mode"
                                       className="map-node-full-screen-button map-node-tool-button fas fa-expand text-muted"/> : null}

                {buttons}

                {readOnlyBtn}

                {userIcon}
            </div>
        </div>
    }
}
