import {MapNodeData} from "../../../node/MapNodeData";

export enum TodoImportance {
    STANDARD,WARNING,ERROR,QUESTION
}

export enum TodoStatus {
    DEFAULT,COMPLETED
}

export class TodoElement {

    static lastLocalId = 0;

    public localId = null;

    public id: string;
    public content: string = "";
    public status: TodoStatus = TodoStatus.DEFAULT;
    public importance: TodoImportance = TodoImportance.STANDARD;
    public position = null;

    constructor(content: string = null,importance: TodoImportance = null) {
        if (null != content) {
            this.content = content;
        }
        if (null != importance) {
            this.importance = importance;
        }
    }

    getAsText() {
        let importanceString = "";
        for(let i = 0;i < this.importance;i++) {
            importanceString += "!";
        }
        if (3 == this.importance) {
            importanceString = "?";
        }
        return (TodoStatus.COMPLETED == this.status?"[X]":"[ ]") + importanceString + " " + this.content;
    }

    setContentFromText(text: string) {
        const test = /^\[([x ])]([!?]*)\s(.*?)$/i.exec(text);
        if (test) {
            if (test[1].toLowerCase() == "x") {
                this.status = TodoStatus.COMPLETED;
            }
            if (test[2].length > 0 && test[2].length < 3) {
                if ("?" == test[2]) {

                    this.importance = 3;
                } else {

                    this.importance = test[2].length;
                }
            }
            this.content = test[3];
        } else {
            this.content = text;
        }
    }

    getLocalId() {
        if (null === this.localId) {
            this.localId = "todo-element-local-"+ (++ TodoElement.lastLocalId);
        }
        return this.localId;
    }

    public static fromData(data: MapNodeData): TodoElement {
        const result = new TodoElement();
        result.id = data.id;
        result.content = data.data["content"];
        result.status = data.data["status"];
        result.importance = data.data["importance"];
        result.position = data.data["position"];
        return result;
    }

    public toData(): any {
        return {
            content: this.content,
            status: this.status,
            importance: this.importance,
            position: this.position
        }
    }
}


