import React from 'react';
import {Vector2} from "../../../map/Vector2";
import {MapObject} from "../../../map/MapObject";

import {AppController} from "../../../controllers/AppController";
import {DomUtil} from "../../../utils/DomUtil";
import {FONTS, getIconUnicode, ICONS} from "../../../lib/Icons";
import {MapNodeFlag, MapNodeType, MapNodeTypeEnum} from "../../../node/MapNodeType";
import {MapNode} from "../../../node/MapNode";
import md5 from "crypto-js/md5";
import {MiniMapComponent} from "../../MiniMapComponent";
import {Emoji} from "../../../lib/Emoji";



export interface MiniMapStaticComponentProps {

    app: AppController,
    size: Vector2

    highlight: MapNode,
    fromObjects?: any[],
    onCanvasRender?: (serialized: any[],canvas) => void,
    drawingActivePosition?: boolean,
    cacheEnabled?: boolean,
    drawLastPosition?: boolean,
}


export class MiniMapStaticComponent extends React.Component<MiniMapStaticComponentProps> {

    private _canvasRef = React.createRef<HTMLCanvasElement>();

    private static _cachedKey = null;
    private static _cached = null;


    constructor(props: MiniMapStaticComponentProps) {
        super(props);
        const w: any = window;

    }

    getSize(): Vector2 {
        return this.props.size;
    }

    private _drawFromCached() {

        const canvas: any = this._canvasRef.current
        const ctx = canvas.getContext("2d");
        ctx.drawImage(MiniMapStaticComponent._cached,0,0);
    }

    private _lastViewerPositionTime;
    private _viewerPositions = [];


    private addViewerPosition(viewerPosition,context) {
        if (true !== this.props.drawLastPosition) {
            if (this._viewerPositions.length > 0) {
                this._viewerPositions = [];
            }
            return;
        }
        const lastPosition = this._viewerPositions[this._viewerPositions.length - 1];
        if (undefined == lastPosition || lastPosition.distance(viewerPosition) > 10) {
            this._viewerPositions.push(viewerPosition);
        }
        const limit = 30;
        if (this._viewerPositions.length > limit) {
            this._viewerPositions = this._viewerPositions.slice(this._viewerPositions.length - limit);
        }

        let last = null;
        const canvasSize = this.getSize();
        let i = 0;
        const all = this._viewerPositions.length;
        for(const position of this._viewerPositions) {
            if (null == last) {
                last = position;
                continue;
            }
            i++;
            context.beginPath();
            context.moveTo(last.x + canvasSize.x/2,last.y+canvasSize.y/2);
            context.lineTo(position.x+canvasSize.x/2,position.y+canvasSize.y/2);
            context.strokeStyle = `rgba(255, 0, 0, ${0.4*(i/all)})`;
            context.stroke();
            last = position;


        }

    }

    private _draw() {

        if (this.props.app.isOptimizing()) {

            return;
        }

        const canvasSize = this.getSize();
        const canvas: any = this._canvasRef.current
        if (null == canvas) {
            setTimeout(() => this._draw(),500);
            return;
        }
        let cacheKey = null;
        if (this.props.cacheEnabled) {
            cacheKey = ""+md5(JSON.stringify([
                this.props.app.getNodesVisible().map(node => [node.id,node.mapObject.toString()]),
            ]));

            if (MiniMapStaticComponent._cachedKey === cacheKey) {
                this._drawFromCached();
                return;
            } else {
                // console.log("cache miss",MiniMapStaticComponent._cachedKey,cacheKey);
            }
        }

        const objects = [];



        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        const drawObject = fromObject => {

            const object = {f: null,g: null,i:null,is: null,p: null,s: null};
            for(const k in fromObject) {
                if (!/^_/.test(k)) {
                    object[k] = fromObject[k];
                }
            }

            context.beginPath();

            if (null != fromObject["_f"]) {
                context.fillStyle = fromObject["_f"];
            } else if (null == object.f) {
                context.fillStyle = "#cccccc";
            } else {
                context.fillStyle = object.f;
            }
            if (null != object.g) {
                context.globalAlpha = object.g;
            }
            if (null === object.i) {
                if (null == object.s || null == object.p) {
                    // console.error("invalid object",object);
                    return;
                }
                context.fillRect(object.p[0],object.p[1],object.s[0],object.s[1]);
            } else {
                let size = object.is;
                context.font=`900 ${size}px ${FONTS[object.i[1]]}`;
                context.fillText(object.i[0],object.p[0],object.p[1]);
            }
            context.globalAlpha = 1;
            objects.push(object);
        };

        if (undefined !== this.props.fromObjects) {
            if (null !== this.props.fromObjects) {
                this.props.fromObjects.forEach(object => drawObject(object));
            }
            return;
        }



        const scale = (this.getSize().x / this.props.app.mapSize.x);


        const viewer = this.props.app.viewerState.scale(scale);

        const viewerSize = viewer.size.divide(this.props.app.getZoom());
        const viewerPosition = viewer.position.divide(this.props.app.getZoom());

        this.addViewerPosition(viewerPosition,context);

        const getImportance = (mapNode: MapNode) => {
            if (this.props.highlight === mapNode) {
                return 3;
            }
            if ([MapNodeTypeEnum.FLAG].indexOf(mapNode.type) !== -1) {
                return 2;
            } else {
                if (null != mapNode.getTypeObject()) {


                    const minimapSpec = mapNode.getTypeObject().getMiniMapSpec(mapNode);
                    if (minimapSpec.color !== null) {
                        return 1;
                    } else {
                        return 0;
                    }
                }

            }
        }


        const sorted = this.props.app.getNodesVisible().sort((a,b) => {
            if (getImportance(a) > getImportance(b)) {
                return 1;
            } else if (getImportance(a) < getImportance(b)) {
                return -1;
            } else {
                return 0;
            }
        });





        const drawNode = node => {
            if (null == node.getTypeObject()) {
                return;
            }
            const minimapSpec = node.getTypeObject().getMiniMapSpec(node);

            const noteObj = node.mapObject.scale(scale);
            const zoom = this.props.app.levelToZoom(noteObj.level);
            const notePosition = noteObj.position.divide(zoom);
            const noteSize = noteObj.size.divide(zoom);

            const object = {_f:null, f: null,g: null,i:null,is: null,p: null,s: null};

            if (this.props.app.focus === node) {
                object._f = '#00b74a';
            } else if (this.props.app.isSelected(node)) {
                // context.fillStyle = '#FFA900';

                object._f = '#00b74a';


            } else {
                if (null === minimapSpec.color) {
                    object.f = null;
                } else {
                    object.f = minimapSpec.color;
                }



            }


            // if (null != this.props.highlight) {
            //     object.g = 0.5;
            // }
            if (null === minimapSpec.icon) {
                object.p = new Vector2(notePosition.x + canvasSize.x / 2 - noteObj.size.x / 2,
                    notePosition.y + canvasSize.y / 2 - noteObj.size.y / 2
                    ).round().array();
                object.s = new Vector2(noteSize.x,noteSize.y).round().array();
                // context.fillRect(
                //     notePosition.x + canvasSize.x / 2 - noteObj.size.x / 2,
                //     notePosition.y + canvasSize.y / 2 - noteObj.size.y / 2,
                //     noteSize.x,
                //     noteSize.y
                // );

            } else {
                let size = 15;
                for(let i = 1;i < zoom;i++) {
                    size = size * 0.5;
                }
                object.is = size;
                let marginLeft = -noteObj.size.x;
                switch(minimapSpec.additional) {
                    case MapNodeFlag.TYPE_ICON:
                        let unicode = getIconUnicode(minimapSpec.icon);
                        object.i = [unicode.unicode,unicode.fontIndex];
                        break;
                    case MapNodeFlag.TYPE_EMOJI:

                        object.i = [Emoji.getMap()[minimapSpec.icon].e,-1];
                        break;
                }

                object.p = new Vector2(notePosition.x + canvasSize.x / 2 - noteObj.size.x / 2 + marginLeft,
                    notePosition.y + canvasSize.y / 2 - noteObj.size.y / 2 + size / 2
                ).round().array();
                // context.fillText(unicode.unicode,notePosition.x + canvasSize.x / 2 - noteObj.size.x / 2,notePosition.y + canvasSize.y / 2 - noteObj.size.y / 2 + size / 2);

            }
            // if (!this.props.app.isDisplayable(node.mapObject)) {
            //     object.g = 0.5;
            // }
            drawObject(object);
            // context.globalAlpha = 1;

            // if (this.props.highlight === node) {
            //     context.beginPath();
            //     context.strokeStyle = '#f10634';
            //     context.lineWidth = 3;
            //     const highlightSize = new Vector2(noteSize.x,noteSize.y);
            //     const padding = 10;
            //     context.rect(
            //         notePosition.x + canvasSize.x / 2 - noteObj.size.x / 2 - padding,
            //         notePosition.y + canvasSize.y / 2 - noteObj.size.y / 2 - padding,
            //         noteSize.x + padding,
            //         noteSize.y + padding
            //     );
            //     context.stroke();
            //     context.lineWidth = 1;
            //
            // }



            context.strokeStyle = '#000000';





        };



        sorted.forEach(node => drawNode(node));


        // context.strokeStyle = '#f10634';
        // if (null !== this.props.highlight) {
        // if (!this.isDrawingActivePosition()) {
        //     context.lineWidth = 2;
        //     context.beginPath();
        //     context.rect(
        //         viewerPosition.x + canvasSize.x / 2 - viewerSize.x / 2,
        //         viewerPosition.y + canvasSize.y / 2 - viewerSize.y / 2,
        //         viewerSize.x,
        //         viewerSize.y
        //     );
        //     context.stroke();
        // }
        // }


        if (null != this.props.onCanvasRender) {

            const serialized = objects.map(obj => {
                for(const k in obj) {
                    if (null ==obj[k]) {
                        delete obj[k];
                    }
                }
                return obj;
            });
            this.props.onCanvasRender(serialized,canvas);
        }
        if (this.props.cacheEnabled && null != cacheKey && cacheKey !== MiniMapStaticComponent._cachedKey) {
            MiniMapStaticComponent._cachedKey = cacheKey;
            MiniMapStaticComponent._cached = canvas
        }

    }

    public isDrawingActivePosition() {
        return true === this.props.drawingActivePosition;
    }

    public getDrawingPositionStyle() {
        const scale = (this.getSize().x / this.props.app.mapSize.x);

        const viewer = this.props.app.viewerState.scale(scale);

        const viewerSize = viewer.size.divide(this.props.app.getZoom());
        const viewerPosition = viewer.position.divide(this.props.app.getZoom());

        const canvasSize = this.getSize();

        return {
            "left": viewerPosition.x + canvasSize.x / 2 - viewerSize.x / 2,
            "top": viewerPosition.y + canvasSize.y / 2 - viewerSize.y / 2 ,
            "width": viewerSize.x,
            "height": viewerSize.y

        }
    }

    public getHighlightStyle() {
        const scale = (this.getSize().x / this.props.app.mapSize.x);

        const viewer = this.props.app.viewerState.scale(scale);

        const viewerSize = viewer.size.divide(this.props.app.getZoom());
        const viewerPosition = viewer.position.divide(this.props.app.getZoom());

        const canvasSize = this.getSize();
        const node = this.props.highlight;
        const noteObj = node.mapObject.scale(scale);
        const zoom = this.props.app.levelToZoom(noteObj.level);
        const notePosition = noteObj.position.divide(zoom);
        const padding = 10;
        const noteSize = noteObj.size.divide(zoom);

        return {
            "left": notePosition.x + canvasSize.x / 2 - noteObj.size.x / 2 - padding,
            "top": notePosition.y + canvasSize.y / 2 - noteObj.size.y / 2 - padding ,
            "width": noteSize.x + padding,
            "height": noteSize.y + padding

        }
    }


    private _onWheelStop = null;

    componentDidMount() {
        this._draw();
        [1000,3000,6000].forEach(timer => setTimeout(() => this._draw(),timer));
        if (this.props.drawLastPosition) {
            this._onWheelStop = () => this._draw();
            this.props.app.io.onWheelStopMovingMapMove.push(this._onWheelStop);
        }
    }

    componentWillUnmount() {
        if (null != this._onWheelStop) {
            this.props.app.io.onWheelStopMovingMapMove.splice(this.props.app.io.onWheelStopMovingMapMove.indexOf(this._onWheelStop),1);
        }
    }

    componentDidUpdate() {
        this._draw();
    }



    render() {

        return <div className="mini-map " style={{width: this.getSize().x,height: this.getSize().y}}>
            <canvas width={this.getSize().x}
                    height={this.getSize().y}
                    className="mini-map-canvas" ref={this._canvasRef}/>
            {this.isDrawingActivePosition() ? <div className="mini-map__drawing-position" style={this.getDrawingPositionStyle()}></div> : null}
            {null != this.props.highlight ? <div className="mini-map__highlight" style={this.getHighlightStyle()}></div> : null}
        </div>
    }
}
