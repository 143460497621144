import {MapNode} from "./MapNode";
import {MapObject} from "../map/MapObject";
import {Vector2} from "../map/Vector2";

import {Model} from "../model/Model";
import {Base64} from "js-base64";

import sha256 from "crypto-js/sha256";
import {MapNodeType} from "./MapNodeType";
import {PendingOperations} from "../lib/PendingOperation";
import {DelayedOperation} from "../lib/DelayedOperation";
import {ModelUtil} from "../model/ModelUtil";
import {DaemonUtils} from "../utils/DaemonUtils";
import {MapUserAccess} from "./MapUserAccess";
import {MapScope} from "./MapScope";
import {MapInvitation} from "./MapInvitation";
import {User} from "./User";
import {UserNotification} from "./UserNotification";
import {AppController} from "../controllers/AppController";

export class UserService {

    private _logged: User = undefined;
    private _notifications = [];

    public onNotificationsChange: (() => void)[] = [];

    public changed = [];

    public pendingOperations;

    constructor(public app: AppController) {
        this.pendingOperations = app.pendingOperations;
    }


    getLoggedLoaded(): User {
        return this._logged;
    }

    async getLogged() {
        if (undefined === this._logged) {
            this._logged = null;
            const data = await (await Model.rest("me")).json();
            this._logged = Object.assign(new User(), data);
        }
        return this._logged;
    }

    public isDifferentOwner(object: any): boolean {
        return null != object.ownerId && object.ownerId != this._logged.id;
    }

    public hasWriteRights(object: any): boolean {
        if (null == this.getLoggedLoaded()) {
            return false;
        }
        if (object instanceof MapNode) {
            if (null != object._otherUpdateDate
            && object._otherUpdateDate.getTime() > new Date().getTime() - 15000) {
                return false;
            }
            let result = null == object.ownerId
                || object.ownerId == this._logged.id
                ;
            if (false == result) {
                const access = this.app.mapService.getMapAccessForMapNode(object);
                if (null != access && !access.readOnly) {
                    return true;
                }
            }
            return result;
        }
        return false;
    }

    async getNotifications(map: MapScope, countOnly=true) {
        const requestData = {
            countOnly: countOnly,
            mapId: map.id
        };
        // console.log("request data",requestData);
        const previousUnread = this._notifications.filter(el => el.unread).length;
        const previousNotifications = this._notifications;
        const data = await (await Model.rest("get_notifications","POST",requestData)).json();
        this._notifications = data.notifications.map(obj => Object.assign(new UserNotification(), obj));
        let changed = this._notifications.filter(el => el.unread).length != previousUnread;
        // let changed = true;
        this.changed = [];
        // if (!changed) {
            this._notifications.forEach(notification => {
               const previous = previousNotifications.find(el => el.id == notification.id);

               if (null == previous || previous.createdAt != notification.createdAt) {
                   changed = true;
                   // console.log("CHANGED",notification);
                   this.changed.push(notification);
               }
            });
        // }
        if (changed) {
            this.onNotificationsChange.forEach(el => el());
        }
        return this._notifications;
    }

    getNotificationsLoaded() {
        return this._notifications;
    }


    async markRead(notification: any) {
        notification.unread = false;

        await (await Model.rest("set_notification_read","POST",{id: ModelUtil.entityIdToNumeric(notification.id)})).json();
        this.onNotificationsChange.forEach(el => el());
    }

    async getInfo(key: string) {
        return await (await Model.rest("e6e309716f923114fd4902/"+key)).json();
    }

    async sendFeedback(rating,info: string) {
        this.getLoggedLoaded().rating = rating;
        await (await Model.rest("send_feedback","POST",{
            info: info,
            rating: rating
        })).json();
    }

    async resendCode() {

        return await (await Model.rest("resend_code","POST",{})).json();
    }

    async confirmCode(code: string) {

        return await (await Model.rest("confirm_code","POST",{"code": code})).json();
    }

}