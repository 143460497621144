export class Emoji {

    private static _map = null;

    public static getMap() {
        if (null === Emoji._map) {
            Emoji._map = [];
            const emoji = window["emoji"];
            for(const categoryIndex in emoji) {
                const category = emoji[categoryIndex];
                const name = category.n;
                for(const row of category.emojis) {
                    row.c = categoryIndex;
                    row.id = Emoji._map.length;
                    Emoji._map.push(row);
                }
            }
        }
        return Emoji._map;
    }

    public static getCategories() {
        Emoji.getMap();
        return window["emoji"];
    }

    private static _emojiToPngCache = {};

    private static _missingEmojis = {
        19: true,
        49: true,
        77: true,
        141: true,
        146: true,
        150: true,
        175: true,
        176: true,
        549: true,
        550: true,
        613: true,
        615: true,
        636: true,
        664: true,
        715: true,
        716: true,
        1137: true,
        1152: true,
        1191: true,
        1192: true,
        1437: true,
        1473: true,


        10: true,
        31: true,
        32: true,
        35: true,
        41: true,
        74: true,
        83: true,
        171: true,
        172: true,
        173: true,
        174: true,
        182: true,
        192: true,
        201: true,
        226: true,
        346: true,
        361: true,
        362: true,
        398: true,
        635: true,
        656: true,
        678: true,
        679: true,
        801: true,
        812: true,
        875: true,
        924: true,
        931: true,
        1100: true,
        1200: true,
        1341: true,
        1343: true,
        1364: true,
        1374: true,
        1377: true,
        1383: true,
    };

    public static isMissing(id: number) {
        return true === Emoji._missingEmojis[id];
    }

    public static emojiToPng(icon: number,size=64) {
        const key = ""+icon+"-"+size;
        if (undefined === Emoji._emojiToPngCache[key]) {
            const canvas = document.createElement("canvas");

            canvas.width = size;
            canvas.height = size;
            // document.getElementById("dbg").append(canvas);
            const context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);

            context.beginPath();
            context.font=`900 ${size*0.8}px Arial`;
            context.textAlign = "center";
            context.fillStyle = "#000000";
            context.fillText(Emoji.getMap()[icon].e,size / 2,size*0.85);
            Emoji._emojiToPngCache[key] = canvas.toDataURL("image/png");
        }
        return Emoji._emojiToPngCache[key];
    }


}
