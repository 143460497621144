export class Vector2 {
    constructor(public x: number, public y: number) {

    }

    public toString(): string {
        return "V{"+this.x+","+this.y+"}";
    }

    public equals(other: Vector2): boolean {
        return this.x == other.x && this.y == other.y;
    }

    public multiply(q): Vector2 {
        return new Vector2(this.x * q, this.y * q);
    }

    public multiplyVector(vector: Vector2): Vector2 {
        return new Vector2(this.x * vector.x, this.y * vector.y);
    }

    public divide(q): Vector2 {
        return new Vector2(this.x / q, this.y / q);
    }

    public sum(other: Vector2): Vector2 {
        return new Vector2(this.x + other.x,this.y + other.y);
    }

    public minus(other: Vector2): Vector2 {
        return new Vector2(this.x - other.x,this.y - other.y);
    }

    public dist() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    public distance(other: Vector2): number {
        return new Vector2(this.x-other.x,this.y-other.y).dist();

    }

    // public normalize(): Vector2 {
    //     return new Vector2(this.x < 0?-this.x:this.x,this.y < 0?-this.y:this.y);
    // }

    public lesserValues(other: Vector2): Vector2 {
        return new Vector2(Math.min(this.x,other.x),Math.min(this.y,other.y));
    }

    public greaterValues(other: Vector2): Vector2 {
        return new Vector2(Math.max(this.x,other.x),Math.max(this.y,other.y));

    }

    public isGreaterThan(other: Vector2): boolean {
        return this.x > other.x && this.y > other.y;
    }

    public isLesserThan(other: Vector2): boolean {
        return this.x < other.x && this.y < other.y;
    }

    public isGreaterEqualThan(other: Vector2): boolean {
        return this.x >= other.x && this.y >= other.y;
    }

    public isLesserEqualThan(other: Vector2): boolean {
        return this.x <= other.x && this.y <= other.y;
    }

    public round(): Vector2 {
        return new Vector2(Math.round(this.x),Math.round(this.y));
    }

    public array(): number[] {
        return [this.x,this.y];
    }

    public normalize(): Vector2 {
        return new Vector2(
            (this.x < 0) ? -this.x : this.x,
            (this.y < 0) ? -this.y : this.y,
        )
    }

    public addDistance(distance): Vector2 {
        if (this.x > 0) {
            return new Vector2(this.x + distance,this.y);
        } else if (this.x < 0) {
            return new Vector2(this.x - distance,this.y);
        }
        if (this.y > 0) {
            return new Vector2(this.x,this.y + distance);
        } else if (this.y < 0) {
            return new Vector2(this.x,this.y - distance);
        }
        return this;
    }

    public maximum(dist: number) {
        const result = new Vector2(this.x,this.y);
        if (result.x > dist) {
            result.x = dist;
        }
        if (result.x < -dist) {
            result.x = -dist;
        }
        if (result.y > dist) {
            result.y = dist;
        }
        if (result.y < -dist) {
            result.y = -dist;
        }
        return result;
    }

}
