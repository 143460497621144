import React, {useState} from 'react';
import "./modal.css";
import {AppController} from "../../../controllers/AppController";


export interface ModalComponentProps {

    title: string;
    onClose: any


}

export interface ModalComponentState {

    // selectedIcon: number
}

export class ModalComponent extends React.Component<ModalComponentProps, ModalComponentState> {

    public static opened: ModalComponent[] = [];

    constructor(props, state) {
        super(props, state);
        this.state = {

            // selectedIcon: props.selectedIcon
        }


    }

    componentDidMount() {
        ModalComponent.opened.push(this);
        if (!document.body.classList.contains("modal-opened")) {
            document.body.classList.add("modal-opened")
        }
    }

    componentWillUnmount() {
        ModalComponent.opened.splice(ModalComponent.opened.indexOf(this),1);
        if (0 == ModalComponent.opened.length && document.body.classList.contains("modal-opened")) {
            document.body.classList.remove("modal-opened")
        }
    }

    onClose() {
        this.props.onClose();
    }



    render() {

        return <div
            className="modal modal-component modal-background app-ui"
            onClick={e => e.target["classList"].contains("modal-background")?this.props.onClose():null}

        >
            <div className="modal-dialog modal-xl modal-background">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.title}</h5>
                        <button
                            onClick={e => this.props.onClose()}
                            type="button"
                            className="btn-close"
                            data-mdb-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">{this.props.children}</div>

                </div>
            </div>
        </div>;
        // return <div className="modal-component app-ui">
        //     <div className="modal-container">
        //         <button type="button" className="btn-close float-end" aria-label="Close"
        //             onClick={e => this.props.onClose()}
        //         />
        //         {this.props.children}
        //     </div>
        // </div>
    }
}
