import React from 'react';
import {AppController} from "../../../controllers/AppController";
import {LoadingComponent} from "../../widgets/loading/LoadingComponent";
import "./comments.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";
import {MapInvitation} from "../../../node/MapInvitation";
import {MapNodeType, MapNodeTypeEnum} from "../../../node/MapNodeType";
import {DaemonUtils} from "../../../utils/DaemonUtils";
import {MapNode} from "../../../node/MapNode";
import {MapNodeData} from "../../../node/MapNodeData";
import {DateComponent} from "../../widgets/DateComponent";
import {UserIconComponent} from "../user-icon/UserIconComponent";
import {ModelUtil} from "../../../model/ModelUtil";
import {CommentsModalComponent} from "./CommentsModalComponent";
import {UserNotification, UserNotificationType} from "../../../node/UserNotification";

export interface CommentsComponentProps {
    app: AppController,
    mapNode: MapNode,
    mapNodeData?: MapNodeData
}

export interface CommentsComponentState {

    opened: boolean,
    comments: any[],
    unread: boolean

}


export class CommentsComponent extends React.Component<CommentsComponentProps, CommentsComponentState> {

    // private _messageRef = React.createRef<HTMLInputElement>();
    // private _listRef = React.createRef<HTMLUListElement>();

    private _onNotificationsChange = null;
    public static registry = {};

    static getContextString(mapNodeId: number,mapNodeDataId: number = undefined) {
        let result = "" + mapNodeId;
        if (undefined != mapNodeDataId) {
            result += "-" + mapNodeDataId;
        }
        return result;
    }

    static getByContext(mapNodeId:number,mapNodeDataId:number) {
        const contextString = CommentsComponent.getContextString(mapNodeId,mapNodeDataId);
        return CommentsComponent.registry[contextString];
    }



    constructor(props) {
        super(props);

        this.state = {opened: false, comments: [],unread: false}
    }

    getCommentsCount() {
        if (undefined != this.state.comments && this.state.comments.length > this.props.mapNode.commentsCount) {
            return this.state.comments.length;
        } else {
            return this.props.mapNode.commentsCount;
        }
    }

    getContextString() {
        const mapNodeDataId = (undefined !== this.props.mapNodeData) ? this.props.mapNodeData.id : undefined;
        return CommentsComponent
            .getContextString(this.props.mapNode.id,
                mapNodeDataId);
    }

    componentDidMount() {
        CommentsComponent.registry[this.getContextString()] = this;
        window["CommentsComponentRegistry"] = CommentsComponent.registry;
        this._onNotificationsChange = () => {
            this.setState({
                unread: null != this.getUnreadNotification()
            })
        };
        this._onNotificationsChange();
        this.props.app.userService.onNotificationsChange.push(this._onNotificationsChange);
    }

    componentWillUnmount() {
        CommentsComponent.registry[this.getContextString()] = undefined;
        this.props.app.userService.onNotificationsChange.splice(
            this.props.app.userService.onNotificationsChange.indexOf(this._onNotificationsChange),1
        )
    }

    getSessionKey() {
        return "comment.addNewTmp." + this.getContextString();
    }

    open() {
        CommentsModalComponent.instance.open(this.props.mapNode,this.props.mapNodeData);
        const notification = this.getUnreadNotification();
        if (null != notification) {
            this.setState({unread: false});
            this.props.app.userService.markRead(notification).then(() => {

            })
        }

    }

    isOpen() {
        return this.state.opened;
    }

    getUnreadNotification() {
        return this.props.app.userService
            .getNotificationsLoaded()
            .find(notification => UserNotificationType.TYPE_COMMENT == notification.type
                && notification.unread
                && ModelUtil.entityIdToNumeric(notification.contextMapNode) == this.props.mapNode.id
            )
    }

    //isUnread() {
      //  return this.state.unread;
        //return null != this.getUnreadNotification();
   // }


    render() {



          return  <span className={"map-node-tool-button comments-component-button app-ui app-node-ignore-focus"}
                  onClick={e => this.open()}
            >
                <i title={"Comments"}
                   className={" fas fa-comment comments-component-button-icon "+(this.state.unread?"text-danger":"")}/>
              {0 < this.getCommentsCount()?<span className={"comments-component-indicator"}>{this.getCommentsCount()}</span>:null}


            </span>

        {/*</span>*/}
    }
}
