import {TodoComponent} from "./TodoComponent";
import {TodoElement} from "./TodoElement";

export class TodoSortOperation {

    private placeholderAt;
    private placeholder;
    private movingDom;
    private position;
    private placeholderHeight;


    constructor(public todoComponent: TodoComponent, public todo: TodoElement) {
    }


    // private getTodoPosition(top: number): any {
    //     let fromTop = 0;
    //     let result = 0;
    //     let last = null;
    //     for(const el of this.todoComponent._listRef.current.children) {
    //         if (el === this.movingDom) {
    //             continue;
    //         }
    //         last = el;
    //         const height = el.getBoundingClientRect().height;
    //         console.log("top",top,fromTop);
    //         if (top >= fromTop && top <= fromTop + height) {
    //             return {element: el,position: result};
    //         }
    //         fromTop += height;
    //         if (el !== this.placeholder) {
    //             result++;
    //         }
    //     }
    //     return {element: null,position: result};
    // }

    public renderPlaceholder() {
        const position = this.position;
        if (this.placeholderAt !== position.position) {
            this.placeholderAt = position.position;
            if (null != this.placeholder) {
                this.placeholder.remove();
            }
            this.placeholder = document.createElement("div");
            this.placeholder.innerHTML = this.movingDom.innerHTML;
            this.placeholder.setAttribute("class", "todo-sort-placeholder");
            this.placeholder.setAttribute("style","height: "+this.placeholderHeight+"px");
            if (this.position.last) {
                this.placeholder.classList.add("todo-sort-placeholder-last");
                this.todoComponent._listRef.current.appendChild(this.placeholder);
            } else {

                this.todoComponent._listRef.current.insertBefore(this.placeholder, position.element);
            }

            this.scroll(this.placeholder);
            // console.log("placeholder position", this.position.position);
        }
    }

    private getDistance(event, element) {
        let clientY = event.clientY;
        if (undefined != event.touches && undefined != event.touches[0]) {
            clientY = event.touches[0].clientY;
        }
        const zoomFix = this.getZoomFix();
        let dist = clientY / zoomFix - element.getBoundingClientRect().top;
        if (dist < 0) {
            dist = -dist;
        }
        return dist;
    }

    public getTodoPosition(event) {
        let winner = null;
        let winnerDist = null;
        let position = 0;
        let positionResult = 0;
        for (const el of this.todoComponent._listRef.current.children) {
            if (el === this.movingDom || el === this.placeholder) {
                continue;
            }
            if (null == winner || this.getDistance(event, el) < winnerDist) {
                winnerDist = this.getDistance(event, el);
                winner = el;
                positionResult = position;

            }
            position++;
        }
        let last = false;
        const zoomFix = window.innerWidth / window.outerWidth;
        let clientY = event.clientY;
        if (undefined != event.touches && undefined != event.touches[0]) {
            clientY = event.touches[0].clientY;
        }
        //console.log("winner",winner,clientY / zoomFix,winner.getBoundingClientRect().bottom);
        if (null != winner && clientY / zoomFix > winner.getBoundingClientRect().bottom) {
            console.log("position++");
            positionResult++;
            last = true;
        }
        return {
            "element": winner,
            "position": positionResult,
            "last": last
        }
    }

    private scroll(element) {
        if (undefined != element["scrollIntoViewIfNeeded"]) {
            element["scrollIntoViewIfNeeded"]();
        } else {
            element.scrollIntoView();
        }
    }

    public getZoomFix() {
        const bodyZoom = window.innerWidth / window.outerWidth;
        return bodyZoom;
        // const nodeZoom = parseFloat(this.movingDom.parentElement.parentElement.parentElement.style.transform.replace(/[^0-9.]/g,''));
        // const result = bodyZoom * nodeZoom;
        // console.log("zoom fix",result);
        // return result;
    }

    public getNodeZoom() {
        return parseFloat(this.movingDom.parentElement.parentElement.parentElement.style.transform.replace(/[^0-9.]/g,''));
    }

    public run(event) {
        const container = this.todoComponent._listRef.current;

        this.movingDom = document.getElementById(this.todo.getLocalId()).parentElement;
        this.placeholderHeight = Math.round(this.movingDom.getBoundingClientRect().height / this.getNodeZoom());

        this.movingDom.classList.add("todo-sort-moving");
        this.position = this.getTodoPosition(event);
        this.renderPlaceholder();
        this.todoComponent.props.app.disableScrollZoom = true;
        const nodeZoom = this.getNodeZoom();
        const zoomFix = this.getZoomFix();

        const onMouseMove = e => {


            // const pointer = e.clientY / zoomFix - container.getBoundingClientRect().y;
            // this.movingDom.style.top = pointer / nodeZoom + "px";
            // this.movingDom.style.left = (e.clientX / zoomFix - container.getBoundingClientRect().x) / nodeZoom + "px";

            this.position = this.getTodoPosition(e);
            this.renderPlaceholder();
            // this.renderPlaceholder(position)
            // console.log("pointer",pointer,position);

            // console.log(pointer,container.parentNode.parentNode.scrollTop);

        };
        const onMouseUp = () => {
            console.log("MOUSE UP");
            let position = 0;
            const todos = this.todoComponent.getVisibleTodos();
            this.movingDom.classList.remove("todo-sort-moving");
            if (null != this.placeholder) {
                this.placeholder.remove();
            }
            if (this.position.position !== this.todo.position) {
                this.todo.position = this.position.position;
                console.log("set position", this.todo.position);
                this.todoComponent.saveTodo(this.todo);
            }
            for (const todoElement of todos) {
                if (todoElement !== this.todo) {
                    if (position == this.position.position) {
                        position++;
                    }
                    if (position !== todoElement.position) {
                        todoElement.position = position;
                        this.todoComponent.saveTodo(todoElement);
                    }
                    position++;
                }
            }
            this.todoComponent.props.app.io.onMouseMove.splice(this.todoComponent.props.app.io.onMouseMove.indexOf(onMouseMove), 1);
            this.todoComponent.props.app.io.onMouseUp.splice(this.todoComponent.props.app.io.onMouseUp.indexOf(onMouseUp), 1);

            this.todoComponent.props.app.io.onDrag.splice(this.todoComponent.props.app.io.onDrag.indexOf(onMouseMove), 1);
            this.todoComponent.props.app.io.onDragEnd.splice(this.todoComponent.props.app.io.onDragEnd.indexOf(onMouseUp), 1);

            this.todoComponent.forceUpdate(() => {
                this.scroll(document.getElementById(this.todo.getLocalId()).parentElement);
            });
            this.todoComponent.props.app.disableScrollZoom = false;
        };
        this.todoComponent.props.app.io.onMouseMove.push(onMouseMove);
        this.todoComponent.props.app.io.onDrag.push(onMouseMove);
        this.todoComponent.props.app.io.onMouseUp.push(onMouseUp);
        this.todoComponent.props.app.io.onDragEnd.push(onMouseUp);

    }

}
