export class User {
    public id: number;
    public email: string;
    public mapAccesses;
    public roles;
    public username;
    public rating;
    public status;

    public isRatingSent() {
        return null != this.rating;
    }
}