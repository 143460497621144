import React, {useState} from 'react';
import {MapNode} from "../../node/MapNode";
import {MapNodeType, MapNodeTypeEnum} from "../../node/MapNodeType";
import {Emoji} from "../../lib/Emoji";




export interface MapNodeIconComponentProps {
    mapNode: MapNode;
}
export interface MapNodeIconComponentState {

}

export class MapNodeIconComponent extends React.Component<MapNodeIconComponentProps,MapNodeIconComponentState> {

    constructor(props: MapNodeIconComponentProps,state: MapNodeIconComponentState) {
        super(props,state);

    }

    getIcon() {
        return this.props.mapNode.getTypeObject().getInfo(this.props.mapNode).icon;
    }



    render() {
        const icon = this.getIcon();
        if (/^\//.test(icon)) {
            return <img className={"map-node-icon map-node-icon-img"} src={icon} />;

        } else {
            const spec = this.props.mapNode.getTypeObject().getMiniMapSpec(this.props.mapNode);
            const color = spec.color;
            if (MapNodeTypeEnum.FLAG == this.props.mapNode.type && 1 === spec.additional) {
                return <span className="map-node-icon map-node-icon-emoji">{Emoji.getMap()[spec.icon].e}</span>
            }
            if (null != color) {
                return <i style={{color: color}} className={"map-node-icon "+this.getIcon()}/>;
            } else {
                return <i className={"map-node-icon "+ this.getIcon()}/>;
            }

        }
    }
}
