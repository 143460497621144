import React from 'react';
import {Vector2} from "../map/Vector2";
import {MapObject} from "../map/MapObject";
import {AppController} from "../controllers/AppController";
import {MapNodeType} from "../node/MapNodeType";




export interface DebugComponentProps {
    app: AppController
}


export interface DebugComponentState {
    options: string[],
    enabled: boolean
}

export class DebugComponent extends React.Component<DebugComponentProps,DebugComponentState> {


    constructor(props: DebugComponentProps) {
        super(props);
        this.state = {options: [],enabled: false}
    }



    render() {

        if (true !== window["debug"]) {
            return null;
        }

        if (!this.state.enabled) {
            return <i onClick={e => this.setState({enabled:true})} className="fas fa-bug debug-toggle" />
        }

        let theLog = this.props.app.log;
        if (null == theLog) {
            theLog = {};
        }

        let log = [];
        for(let key in theLog) {
            log.push(<li key={key} >{key}: {theLog[key]}</li>);
        }
        const allOptions = ["no-shadows","no-icons"];

        MapNodeType.getAllTypes().forEach(type => allOptions.push("no-type-"+type.getInfo().getName()));

        const options = allOptions.map(option => <div key={option}>
            <input type="checkbox"
                   name={"option-"+option}
                   checked={-1 !== this.state.options.indexOf(option)}
                   onChange={e => {
                       if (e.target.checked) {
                           this.state.options.push(option);
                           this.setState({options: this.state.options});
                       } else {
                           this.state.options.splice(this.state.options.indexOf(option), 1);
                           this.setState({options: this.state.options});
                       }
                       this.props.app.setGlobalClass(this.state.options.join(" "))
                   }}
                   /> <label htmlFor={"option-"+option}>{option}</label>
        </div>)

        return <div className="debug">
            {log}

            {options}
        </div>
    }
}
