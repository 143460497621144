export class MapNodeOptions {
    constructor(public options: any[]) {

    }

    public static fromRaw(optionsRaw: string) {
        if (null === optionsRaw) {
            return new MapNodeOptions([]);
        } else {
            return new MapNodeOptions(optionsRaw.split(","));
        }
    }

    public toRaw(): string {
        return this.options.join(",");
    }

    get(index: number,number: boolean = true): any {
        if (undefined !== this.options[index]) {
            return number?parseInt(this.options[index]):this.options[index];
        } else {
            return null;
        }
    }
    set(index: number, value: any) {
        this.options[index] = value;
    }
}