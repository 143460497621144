import {MapObject} from "../map/MapObject";
import {Vector2} from "../map/Vector2";
import {DateComponent} from "../components/widgets/DateComponent";


export class MapNodeData {
    public createdAt;
    public updatedAt;
    public mapNodeId;

    constructor(public id=null,
                public data: string=null) {

    }


    public getUpdateInfo() {
        let result = "";
        if (null != this.createdAt) {
            result += "Created: "+DateComponent.formatDate(DateComponent.parseDateString(this.createdAt),false);
        }
        if (null != this.updatedAt && this.createdAt != this.updatedAt) {
            result += "\nUpdated: "+DateComponent.formatDate(DateComponent.parseDateString(this.updatedAt),false);
        }
        return result;
    }

}