import React, {useState} from 'react';

import "./loading.css";



export interface LoadingComponentProps {
    fullPage?:boolean
}
export interface LoadingComponentState {

}

export class LoadingComponent extends React.Component<LoadingComponentProps,LoadingComponentState> {


//https://mdbootstrap.com/docs/standard/components/spinners/
    render() {
        return <span className={"loading-component   "+(this.props.fullPage ? "loading-component-full-page":"")} role="status">
            <img className="loading-component-icon" src="/favicon-old.ico" />
            <div className="spinner-border" />


        </span>;

    }
}
