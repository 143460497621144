import {MapObject} from "../map/MapObject";
import {MapNodeData} from "./MapNodeData";
import {MapNodeType, MapNodeTypeEnum} from "./MapNodeType";
import {MapNodeOptions} from "./MapNodeOptions";


export class MapNode {

    public _passwordUnlockHash: string = null;
    public _passwordUnlocked: boolean = false;

    public _onUpdate: (() => void)[] = [];

    public _customFields = {};

    public commentsCount: number = 0;

    public localUpdateDate: Date = null;
    public _otherUpdateDate: Date = null;
    public _otherUpdateUserId: number;

    public events: any = {};

    public updateTime: number = null;
    public touchedTime: number = null;

    public recentlyAdded: number = null;
    // public localUpdated: boolean = false;

    constructor(public id: number,
                public title: string,
                public options: string,
                public createdAt: any,
                public updatedAt: any,
                public type: number,
                public data: string,
                public mapObject: MapObject,
                public mapNodeDatas: MapNodeData[] = null,
                public password = null,
                public publicKey: string = null,
                public mapId: number = null,
                public ownerId: number = null,
                public hidden: boolean = false,
                public touchedAt: any = null
                ) {

        this.events.onFullScreenChange = [];
        if (null != this.updatedAt) {
            this.updateTime = Date.parse(this.updatedAt);
        }
        if (null != this.touchedAt) {
            this.touchedTime = Date.parse(this.touchedAt);
        }

    }

    isRecenltyAdded() {
        return null != this.recentlyAdded && new Date().getTime()-this.recentlyAdded < 2000;
    }

    onLocalUpdate() {
        // this.localUpdated = true;
        this.localUpdateDate = new Date();
        this.updateTime = new Date().getTime();
    }

    isPasswordLockAvailable() {
        return null == this.password && -1 !== [
            MapNodeTypeEnum.TODO,
            MapNodeTypeEnum.NOTE_TXT,
            MapNodeTypeEnum.LOG,
            MapNodeTypeEnum.FILES,
            // MapNodeTypeEnum.NOTE
        ].indexOf(this.type);
    }

    setPasswordUnlockHash(hash) {
        this._passwordUnlocked = hash;
    }

    isPasswordLocked() {
        return true == this.password && !this._passwordUnlocked;
    }

    isHidden() {
        return this.hidden;
    }

    getTypeObject(): MapNodeType {
        return MapNodeType.getType(this.type);
    }

    public getOptionsObject(): MapNodeOptions {
        return MapNodeOptions.fromRaw(this.options);
    }

    public setOptionsObject(options: MapNodeOptions) {
        this.options = options.toRaw();
    }


}
