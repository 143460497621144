export enum UserNotificationType {
    TYPE_COMMENT = 1, TYPE_MAP_NODE = 2,TYPE_MAP_NODE_DELETED = 3
}

export class UserNotification {
    public id;
    public contextMap;
    public contextMapComment;
    public contextMapNode;
    public contextMapNodeData;
    public contextUser;
    public createdAt;
    public type;
    public unread;
    public contextOtherId;

}