import {Vector2} from "../map/Vector2";
import {MapNode} from "./MapNode";
import {MapNodeMinimapSpec} from "./MapNodeMinimapSpec";
import {getIcon} from "../lib/Icons";
import {inspect} from "util";

/*
add
- enum
- getAllTypes
- new class type
- MapNodeComponent
 */

export enum MapNodeTypeEnum {
    NOTE=1,
    FLAG,
    TODO,
    WORMHOLE,
    LOG,
    FILES,
    NOTE_TXT
}

export class MapNodeTypeInfo {
    constructor(public title: string,public icon: string) {
    }

    getName() {
        return this.title.replace(/\s/g,'-').toLowerCase();
    }
}

export class MapNodeType {

    static allTypes = null;
    getType(): number {
        return null;
    }
    getDefaultSize(): Vector2 {
        return null;
    }
    getMinSize() {
        return new Vector2(50,50);
    }
    getCssClass() {
        return "";
    }


    static getAllTypes(): MapNodeType[] {
        if (null === MapNodeType.allTypes) {
            MapNodeType.allTypes = [new MapNodeFlag(),new MapNodeNoteTxt(),new MapNodeNote(),new MapNodeTodo(),new MapNodeWormhole(),new MapNodeLog(),new MapNodeFiles()]
        }
        return MapNodeType.allTypes;
    }

    static getType(type: number): MapNodeType {
        return MapNodeType.getAllTypes().find(t => t.getType() === type);
    }

    public isResizable(): Vector2 {
        return new Vector2(1,1);
    }

    public getMapNodeColor(context: MapNode): string {
        return null;
    }

    public getMiniMapSpec(context: MapNode): MapNodeMinimapSpec {
        return new MapNodeMinimapSpec();
    }

    public getDisplayContentsLevelTreshold(): Vector2 {
        return new Vector2(-1,2);
    }

    public getInfo(context: MapNode = null): MapNodeTypeInfo {
        return new MapNodeTypeInfo("--","--");
    }

    getSearchImportance(context: MapNode) {
        return 0;
    }

    public isFullScreenEditable() {
        return false;
    }

    public isFittingScreenOnFocus() {
        return true;
    }

    public hasLastEditIcon() {
        return true;
    }



}

/**
 * @deprecated
 */
export class MapNodeNote extends MapNodeType {
    getDefaultSize(): Vector2 {
        return new Vector2(400, 247);
    }

    getMinSize(): Vector2 {
        return new Vector2(100,80);
    }

    getType(): number {
        return MapNodeTypeEnum.NOTE;
    }

    getCssClass(): string {
        return "note note-light app-shadow";
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        return new MapNodeTypeInfo("Note","far fa-sticky-note");
    }



}


export class MapNodeFlag extends MapNodeType {

    static TYPE_ICON = 0;
    static TYPE_EMOJI = 1;

    static DEFAULT_ICON = 411;
    static DEFAULT_COLOR = "#000000";

    getDefaultSize(): Vector2 {
        // return new Vector2(213, 56);
        return new Vector2(287, 187);

    }

    getMinSize(): Vector2 {
        return new Vector2(150,100);
    }

    getType(): number {
        return MapNodeTypeEnum.FLAG;
    }

    getCssClass(): string {
        return "map-node-flag";
    }

    public isResizable(): Vector2 {
        return new Vector2(1,0);
    }

    public getMiniMapSpec(context: MapNode): MapNodeMinimapSpec {
        let color = context.getOptionsObject().get(1,false);
        let icon = context.getOptionsObject().get(0);
        let type = context.getOptionsObject().get(2,true);
        if (null == icon || isNaN(icon)) {
            icon = MapNodeFlag.DEFAULT_ICON;
        }
        if (null == color) {
            color = MapNodeFlag.DEFAULT_COLOR;
        }
        return new MapNodeMinimapSpec(color?color:null,icon,type?type:0);
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        if (null != context) {
            let icon = context.getOptionsObject().get(0);
            if (null == icon || isNaN(icon)) {
                icon = MapNodeFlag.DEFAULT_ICON;
            }
            return new MapNodeTypeInfo("Flag",getIcon(icon));
        }
        return new MapNodeTypeInfo("Flag","far fa-flag");
    }

    public getDisplayContentsLevelTreshold(): Vector2 {
        return new Vector2(null,null);
    }

    getSearchImportance(context: MapNode): number {
        return 3;
    }

    public isFullScreenEditable() {
        return true;
    }

    public hasLastEditIcon() {
        return false;
    }
}


export class MapNodeTodo extends MapNodeType {
    getDefaultSize(): Vector2 {
        return new Vector2(400, 247);
    }

    getMinSize(): Vector2 {
        return new Vector2(100,80);
    }

    getType(): number {
        return MapNodeTypeEnum.TODO;
    }

    getCssClass(): string {
        return "note note-light app-shadow";
    }

    public getMiniMapSpec(context: MapNode): MapNodeMinimapSpec {
        let color = null;
        switch(context.getOptionsObject().get(0)) {
            case 1:
                color = "#FFA900";
                break
            case 2:
                color = "#F93154";
                break;
        }
        return new MapNodeMinimapSpec(color);
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        return new MapNodeTypeInfo("Todo List","fas fa-clipboard-check");
    }

    isFullScreenEditable() {
        return true;
    }

}


export class MapNodeWormhole extends MapNodeType {

    static IMAGE = "/images/wormhole.png";
    static ICON = 1207; // compact-disc

    getDefaultSize(): Vector2 {
        // return new Vector2(213, 56);
        return new Vector2(287, 187);

    }

    getMinSize(): Vector2 {
        return new Vector2(150,100);
    }

    getType(): number {
        return MapNodeTypeEnum.WORMHOLE;
    }

    getCssClass(): string {
        return "map-node-wormhole";
    }

    public isResizable(): Vector2 {
        return new Vector2(1,0);
    }

    public getMiniMapSpec(context: MapNode): MapNodeMinimapSpec {

        return new MapNodeMinimapSpec("#000000",MapNodeWormhole.ICON);
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        if (null != context) {
            return new MapNodeTypeInfo("Wormhole",MapNodeWormhole.IMAGE);
        }
        return new MapNodeTypeInfo("Wormhole","far fa-circle");
    }
    public getDisplayContentsLevelTreshold(): Vector2 {
        return new Vector2(null,null);
    }
    public getSearchImportance(context: MapNode): number {
        return 1;
    }

    public hasLastEditIcon() {
        return false;
    }
}



export class MapNodeLog extends MapNodeType {
    getDefaultSize(): Vector2 {
        return new Vector2(800, 500);
    }

    getMinSize(): Vector2 {
        return new Vector2(200,100);
    }

    getType(): number {
        return MapNodeTypeEnum.LOG;
    }

    getCssClass(): string {
        return "note note-light app-shadow node-log-component";
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        return new MapNodeTypeInfo("Log","fas fa-book");
    }
    isFullScreenEditable() {
        return true;
    }

}


export class MapNodeFiles extends MapNodeType {
    getDefaultSize(): Vector2 {
        return new Vector2(400, 247);
    }

    getType(): number {
        return MapNodeTypeEnum.FILES;
    }

    getCssClass(): string {
        return "note note-light app-shadow";
    }


    public getDisplayContentsLevelTreshold(): Vector2 {
        return new Vector2(0,1);
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        return new MapNodeTypeInfo("Upload files","fas fa-file-upload");
    }

    isFullScreenEditable() {
        return true;
    }

    public isFittingScreenOnFocus() {
        return false;
    }

}



export class MapNodeNoteTxt extends MapNodeType {

    static COLORS = [
        "#fbfbfb",
        "#fff9b1",
        "#d5f693",
        "#a7ccf5",
        "#f5d127",
        "#94d275",
        "#6dd6fa",
        "#f16c7f",
        "#8EC2BF",
        "#8291F1",

    ];

    getDefaultSize(): Vector2 {
        return new Vector2(400, 247);
    }

    getMinSize(): Vector2 {
        return new Vector2(100,80);
    }

    getType(): number {
        return MapNodeTypeEnum.NOTE_TXT;
    }

    getCssClass(): string {
        return "note note-light app-shadow note-txt-component";
    }

    getInfo(context: MapNode = null): MapNodeTypeInfo {
        return new MapNodeTypeInfo("Text Note","far fa-sticky-note");
    }

    isFullScreenEditable() {
        return true;
    }

    public getMapNodeColor(context: MapNode): string {
        let color = context.getOptionsObject().get(0,true);
        if (isNaN(color)) {
            color = 0;
        }
        return MapNodeNoteTxt.COLORS[color];
    }

    public getMiniMapSpec(context: MapNode): MapNodeMinimapSpec {
        let color = this.getMapNodeColor(context);
        if (color === "#fbfbfb") {
            color = null;
        }
        return new MapNodeMinimapSpec(color,
            null,0);
    }

}
