import React from 'react';
import logo from './logo.svg';
import './App.css';
import {AppComponent} from "./components/AppComponent";
import {AppLoader} from "./components/AppLoader";

function App() {
  return (
    <AppLoader />
  );
}

export default App;
