import {ApolloClient, createHttpLink, gql, InMemoryCache} from "@apollo/client";

/*
https://localhost/graphql/graphql_playground
 */

export class Model {

    public static logged: string = null;
    protected static client = null;

    public static getApiUrl() {
        if ("dragonote.com" === document.location.host) {
            return "https://api.dragonote.com/";
        } else {
            return "http://localhost/";
        }
    }

    private static setClient() {
        // if (null != Model.client) {
        //     return;
        // }
        const link = createHttpLink({
            uri: Model.getApiUrl()+ 'graphql',
            fetchOptions: {
                mode: 'cors',
                credentials: "include"
            },
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("token")}`
            }
        });
        Model.client = new ApolloClient({
            cache: new InMemoryCache(),
            link
        });

    }

    public static rest(uri,method="GET",data=null) {
        const body = null != data ? JSON.stringify(data) : null;
        return fetch(Model.getApiUrl()+uri, {
            "headers": {
                "accept": "application/json",
                "authorization": `Bearer ${sessionStorage.getItem("token")}`,
            },
            "body": body,
            "method": method,
            "mode": "cors",
            "credentials": "include"
        });
    }



    public static authorize(email: string,password: string) {
        return new Promise((resolve, reject) => {
            fetch(Model.getApiUrl() + "authentication_token", {
                "headers": {
                    "accept": "application/json",
                    "content-type": "application/json",
                },

                "body": JSON.stringify({email: email, password: password}),
                "method": "POST",
                "mode": "cors",
                // "credentials": "include"
            }).then(response => {

                response.json().then(data => {
                    if (response.ok) {
                        sessionStorage.setItem("token", data.token);
                        sessionStorage.setItem("refreshToken", data.refresh_token);

                        Model.setClient();
                        resolve(true);
                        // resolve(data.token);

                    } else {
                        if (null != data && undefined != data.message) {
                            reject(data.message);
                        } else {
                            reject("Error "+response.status);
                        }
                    }
                });
            });
        });
    }

    public static refreshToken() {
        return new Promise((resolve, reject) => {
            if (null == sessionStorage.getItem("refreshToken")) {
                reject("No refresh token");
            } else {
                fetch(Model.getApiUrl() + "token/refresh", {
                    "headers": {
                        "accept": "application/json",
                        "content-type": "application/json",
                    },

                    "body": JSON.stringify({refresh_token: sessionStorage.getItem("refreshToken")}),
                    "method": "POST",
                    "mode": "cors",
                    // "credentials": "include"
                }).then(response => {

                    response.json().then(data => {
                        if (response.ok) {
                            sessionStorage.setItem("token", data.token);
                            sessionStorage.setItem("refreshToken", data.refresh_token);

                            Model.setClient();

                            resolve(true);

                        } else {
                            Model.logOut();
                            if (null != data && undefined != data.message) {
                                reject(data.message);
                                // this.setState({error: data.message});
                            } else {
                                // this.setState({error: "Error " + response.status});
                                reject("Error " + response.status);
                            }
                        }
                    });
                },error => {
                    reject(error);
                });
            }

        });

    }

    public static logOut() {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refreshToken");
        Model.client = null;
    }

    public static getClient() {
        return Model.client;
    }

    // public static query(query: string) {
    //     return Model.getClient()
    //         .query({
    //             query: gql`${query}`
    //         });
    // }
    //
    // public static mutate(query: string,varialbes: any) {
    //     return Model.getClient()
    //         .mutate({
    //             mutation: gql`${query}`,
    //             variables: varialbes
    //         });
    // }

}
