import React, {useState} from 'react';


export interface PasswordUnlockComponentProps {

    onSubmit: (password: string) => void

}

export interface PasswordUnlockComponentState {

    // selectedIcon: number
}

export class PasswordUnlockComponent extends React.Component<PasswordUnlockComponentProps, PasswordUnlockComponentState> {


    constructor(props, state) {
        super(props, state);
        this.state = {

            // selectedIcon: props.selectedIcon
        }


    }

    onSubmit(e) {
        e.preventDefault();
        const password = e.target.querySelector(".password-font").value;
        e.target.querySelector(".password-font").value = "";
        this.props.onSubmit(password);
    }



    render() {
        return <div className="password-unlock-component">
            <form onSubmit={e => this.onSubmit(e)}>
                <div className="input-group mb-3">
                    <input
                        type="test"


                        className="form-control password-font" placeholder="Password protected"
                           required={true}
                           aria-describedby="button-addon2"/>
                    <button className="btn btn-outline-dark" type="submit" id="button-addon2"

                            data-mdb-ripple-color="dark">
                        Unlock
                    </button>

                </div>
            </form>
        </div>;
    }
}
