import React from 'react';
import {AppController} from "../../../controllers/AppController";

import "./notifications.css";
import {ModalComponent} from "../../widgets/modal/ModalComponent";

export interface NotificationsComponentProps {
    app: AppController
}

export interface NotificationsComponentState {
    notifications: {text: string,type: string,_id: number}[],
    confirm: string,
    confirmType: string
}

export class NotificationsComponent extends React.Component<NotificationsComponentProps, NotificationsComponentState> {

    private lastId = 0;

    private _confirmPromise: Promise<boolean>;

    private _confirmResolve;

    constructor(props) {
        super(props);
        this.state = {notifications: [],confirm: null,confirmType: null};


    }

    private _onKeyUp;

    componentDidMount() {
        this.props.app.notify = (text,type) => {
            const notifications = this.state.notifications;
            const notification = {text: text,type: type, _id: this.lastId++};
            notifications.push(notification);
            this.setState({notifications: notifications});
            setTimeout(()=> this.removeNotification(notification),3000);
        };
        this.props.app.confirm = (title,type) => {
            this.setState({"confirm": title,"confirmType": type});
            this._confirmPromise = new Promise((resolve) => {
                this._confirmResolve = resolve;
            });
            return this._confirmPromise;
        };
        this._onKeyUp = e => {
            if (null != this.state.confirm && "Enter" == e.key) {
                this.onConfirmConfirm();
            }
        };
        this.props.app.io.onKeyUp.push(this._onKeyUp);
    }

    componentWillUnmount() {
        this.props.app.io.onKeyUp.splice(this.props.app.io.onKeyUp.indexOf(this._onKeyUp),1);
    }

    removeNotification(notification) {
        const notifications = this.state.notifications;
        notifications.splice(notifications.indexOf(notification), 1);
        this.setState({notifications: notifications});
    }

    closeConfirm() {
        this.setState({"confirm": null});
    }

    onConfirmClose() {
        this._confirmResolve(false);
        this.setState({"confirm": null});
    }

    onConfirmConfirm() {
        this._confirmResolve(true);
        this.setState({"confirm": null});
    }

    render() {

        return <div>
            {null != this.state.confirm?<ModalComponent title="Confirm" onClose={() => this.onConfirmClose()}>
                <div className={"alert alert-"+this.state.confirmType}>
                    {this.state.confirm}

                </div>
                <div className="modal-confirm-buttons">
                    <button className="btn btn-outline-danger ripple-surface ripple-surface-dark" onClick={e => this.onConfirmConfirm()}>Confirm</button>
                    <button className="btn btn-outline-flat ripple-surface ripple-surface-dark" onClick={e => this.onConfirmClose()}>Cancel</button>
                </div>

                </ModalComponent>
            :null}
            <div className={"notifications-component"}>
                {this.state.notifications.map(notification => <div key={notification._id}
                                                                   className={"alert alert-"+notification.type}>{notification.text}</div>)}
            </div>
        </div>
    }
}
