import React, {useState} from 'react';

import {AppController} from "../../../controllers/AppController";

import createImagePlugin from '@draft-js-plugins/image';

import createLinkifyPlugin from 'draft-js-linkify-plugin';
import {MapNode} from "../../../node/MapNode";

import {LogElement} from "./LogElement";
import {MapNodeData} from "../../../node/MapNodeData";
import {DateComponent} from "../../widgets/DateComponent";


export interface LogRowComponentProps {
    // viewerState: MapObject,
    app: AppController,
    mapNode: MapNode,
    log: LogElement,
    



}


export interface LogRowComponentState {
    // log: LogElement
}



export class LogRowComponent extends React.Component<LogRowComponentProps,LogRowComponentState> {

    constructor(props,state) {
        super(props,state);
        this.state = {
            // log: this.props.log,
        }
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }





    render() {


        return <li className={"log-row list-group-item"}>
            <div className={"small log-row-date"}><DateComponent date={this.props.log.date} /></div>
            <div className={"log-row-content"}>{this.props.log.content}</div>

        </li>
    }
}
